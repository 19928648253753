import axios from "./axios";
import * as Requests from "./requests";

export const getMenuScrapingStateApi = (token: string) => {
  return axios.get(Requests.menuScrapingStateUrl, {
    headers: {
      token,
    },
    baseURL: process.env.DASHBOARD_ENDPOINT,
  });
};
