import {
  getCompetitions,
  getCompetitionsSuccess,
  getCompetitionsFailure,
} from "../actions/get-competitions-actions";
import { Map } from "immutable";
import * as constants from "../../constants";
import { mutateState } from "../../helpers/mutate-state";
import { LeaderBoardItem } from "../../models/LeaderBoardItem";
import { union } from "ts-action";
import {
  IDeleteCompetitionSuccessAction,
  INextLeaderboardSuccessAction,
} from "../../types";

const COMPETITIONS = "COMPETITIONS";
const LOADING = "LOADING";
const LOADED = "LOADED";
const FAILED = "FAILED";
const HAS_NEXT_LEADERBOARD = "HAS_NEXT_LEADERBOARD";
const HAS_NEXT_LEADERBOARD_LOADED = "HAS_NEXT_LEADERBOARD_LOADED";

export interface ICompetitionsReducerState {
  [COMPETITIONS]: LeaderBoardItem[];
  [LOADING]: boolean;
  [LOADED]: boolean;
  [FAILED]: boolean;
  [HAS_NEXT_LEADERBOARD]: boolean;
  [HAS_NEXT_LEADERBOARD_LOADED]: boolean;
}

const initialState: ICompetitionsReducerState = Map({
  [COMPETITIONS]: [],
  [LOADED]: false,
  [LOADING]: false,
  [FAILED]: false,
  [HAS_NEXT_LEADERBOARD]: false,
  [HAS_NEXT_LEADERBOARD_LOADED]: false,
}).toJS();

const allActions = union({
  getCompetitions,
  getCompetitionsSuccess,
  getCompetitionsFailure,
});

type getCompetitionsActions = typeof allActions;

export default (
  state = initialState,
  action:
    | getCompetitionsActions
    | IDeleteCompetitionSuccessAction
    | INextLeaderboardSuccessAction
): ICompetitionsReducerState => {
  switch (action.type) {
    case constants.getCompetitionsAction.fulfilled: {
      return mutateState(state, (map) => {
        map.set(COMPETITIONS, action.payload);
        map.set(LOADING, false);
        map.set(LOADED, true);
        map.set(FAILED, false);
      });
    }
    case constants.getCompetitionsAction.requested: {
      return mutateState(state, (map) => {
        map.set(LOADING, true);
        map.set(LOADED, false);
        map.set(FAILED, false);
      });
    }
    case constants.getCompetitionsAction.rejected: {
      return mutateState(state, (map) => {
        map.set(LOADING, false);
        map.set(LOADED, false);
        map.set(FAILED, true);
      });
    }
    case constants.deleteCompetitionAction.fulfilled: {
      return mutateState(state, (map) => {
        const competitions = map.get(COMPETITIONS) as LeaderBoardItem[];
        const competitionsAfterDeletion: LeaderBoardItem[] = competitions.map(
          (leaderBoard) => {
            if (leaderBoard.id === action.payload) {
              return new LeaderBoardItem({
                start_time: leaderBoard.date.format("MM YYYY"),
              });
            }
            return leaderBoard;
          }
        );
        map.set(COMPETITIONS, competitionsAfterDeletion);
        map.set(HAS_NEXT_LEADERBOARD, false);
      });
    }
    case constants.nextLeaderboardAction.fulfilled: {
      return mutateState(state, (map) => {
        map.set(HAS_NEXT_LEADERBOARD, action.payload);
        map.set(HAS_NEXT_LEADERBOARD_LOADED, true);
      });
    }
    case constants.createCompetitionAction.fulfilled: {
      return mutateState(state, (map) => {
        map.set(HAS_NEXT_LEADERBOARD, true);
      });
    }
    default:
      return state;
  }
};
