import React from "react";
import * as styles from "./styles.module.sass";
import { uDIC } from "../useDefaultAndInsertedClassName";

interface IProps {
  label: string;
  className?: string;
  onClick?: () => void;
  type?: React.ButtonHTMLAttributes<HTMLButtonElement>["type"];
}
const GenericButtonWithoutIcon: React.FC<IProps &
  React.ButtonHTMLAttributes<HTMLButtonElement>> = (props) => {
  return (
    <button
      type="button"
      {...props}
      className={uDIC(styles.genericButtonDefaults, props.className)}
    >
      {props.label || props.children}
    </button>
  );
};

export { GenericButtonWithoutIcon };
