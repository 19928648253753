export default {
  title: "Add new Item",
  imagePlaceholder: "Upload item image",
  namePlaceholder: "Item Name",
  categoryPlaceholder: "Item Category",
  pricePlaceholder: "Item Price",
  integrationIdPlaceholder: "Integration Id",
  addItem: "Add Item",
  uploadImageButtonText: "Upload Image",
  uploadImageDescription: "Image should be at least 4KB and at most 1MB",
  unsupportedImageType: "Unsupported file type...",
};
