export default {
  openingHour: "Open Hours",
  preparationTime: "Preparation time",
  brandCat: "Brand category",
  delivery: "Delivery time & cost",
  brandTags: "Brand Tags",
  coverPhoto: "Cover Photo",
  friday: "Friday",
  saturday: "Saturday",
  sunday: "Sunday",
  monday: "Monday",
  posTitle: "Online orders are sent directly to POS",
  tuesday: "Tuesday",
  wednesday: "Wednesday",
  thursday: "Thursday",
  AM: "AM",
  PM: "PM",
  selectTimeZoneLabel: "Select time zone",
  setUpPrepTimeTitle: "Setup Preparation Time",
  setUpPrepTimeSubTitle: "What's the average preparation time?",
  from: "From",
  to: "To",
  minutes: "Minutes",
  minimumOrderValue: "Minimum order value",
  setUpDeliveryTitle: "Setup Delivery Time & Cost",
  setUpDeliverySubTitle: "What’s Average preparation time?",
  time: "Time",
  cost: "Cost",
  description: "File Requirements: JPG, PNG up to 5MB.",
  uploadPhoto: "Upload Photo",
  done: "Done",
  unsupported: "unsupported file type",
  routingMethod: "Routing method",
  deliveryMethod: "Delivery Method",
  paymentMethodTitle: "Payment method",
  branchesWorkingHours: "Branches Working Hours",
};
