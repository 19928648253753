import axios from "./axios";
import * as Requests from "./requests";

export interface IMenuScraping {
  menu_scraping_type: string;
  en_url: string;
  ar_url: string;
}

export const importMenuApi = (token: string, data: IMenuScraping) => {
  return axios.post(Requests.importMenuUrl, data, {
    headers: { token },

    baseURL: process.env.DASHBOARD_ENDPOINT,
  });
};
