import { reducer, on } from "ts-action";
import { withLoadingReducer } from "./withLoadingState";
import { getMenuScrapingStateAction } from "../../constants/actions";
import {
  getMenuScrapingStateSuccess,
  MenuScrapingState,
  IMenuScrapingState,
  importMenuSuccess,
} from "../actions/menu-scraping-actions";

const initialState: IMenuScrapingState = {
  menu_import_status: MenuScrapingState.null,
};

const genMenuImportStatus = (menu_import_status: MenuScrapingState) => {
  return (state: any) => ({
    ...state,
    menu_import_status,
  });
};

export const getMenuScrapingStateReducer = withLoadingReducer(
  reducer<IMenuScrapingState>(
    [
      on(getMenuScrapingStateSuccess, (state, { payload }) => ({
        ...state,
        menu_import_status: payload.menu_import_status,
      })),
      on(importMenuSuccess, genMenuImportStatus(MenuScrapingState.pending)),
    ],
    initialState
  ),
  getMenuScrapingStateAction
);
