import { action, payload } from "ts-action";
import {
  getOrderingPortalLinkAction,
  getWebPortalHistoryAction,
  getWebPortalStatisticsAction,
} from "../../constants/actions";
import { IWebPortalFilters } from "../../../../axios/getWebPortalHistory";
import { OrderType } from "../../../OrderingPortal/OrderingPortalHistory/OrderingPortalHistoryHeader/OrderingPortalFilters";
import { Gift } from "../../SetupPickup/OrderHistory/PastOrder/PastOrderTable/OrderDetails/OrderDetails/SingleItem";
import { DeliveryMethods } from "../../lib/types";
export interface IWebPortalStatistics {
  portal_clicks_count: number;
  created_basket_orders_count: number;
  created_orders_count: number;
  completed_orders_count: number;
}
export interface IWebPortalHistoryPayload {
  page: number;
  per_page: number;
  filters?: IWebPortalFilters | undefined;
}
export interface IWebPortalOrders {
  orders: IWebPortalOrder[];
  hasMore: boolean;
  filters: IWebPortalFilters;
  page: number;
  total_orders: number;
}

export enum OrderStatus {
  scheduled = "scheduled",
  sent = "sent",
  received = "received",
  seen = "seen",
  accepted = "accepted",
  ready_for_pickup = "ready_for_pickup",
  rejected = "rejected",
  out_for_delivery = "out_for_delivery",
  canceled_by_customer = "canceled_by_customer",
  canceled_by_agent = "canceled_by_agent",
  canceled_by_cashier = "canceled_by_cashier",
  customer_no_show = "customer_no_show",
  got_picked_up = "got_picked_up",
  delivered = "delivered",
  canceled_by_courier = "canceled_by_courier",
}

export interface IOrderTracking {
  statuses: IStatus[];
}

export interface IStatus {
  id: string;
  status: OrderStatus;
  store_time: number;
  user_id: string;
  user_name: string;
  user_type: string;
  order_status_reason_id: number;
  order_status_reason: IReason;
}

export interface IReason {
  id: number;
  ar_text: string;
  en_text: string;
}

export interface ICustomerInfo {
  customer: ICustomer;
  total_orders: number;
}

export interface ICustomer {
  app_version: string;
  birth_date: number;
  shopxId: string;
  image: string;
  created_at: number;
  country_code: string;
  last_active_time: number;
  store_image: string;
  password: string;
  update_data_screen_status: number;
  updated_at: number;
  name: string;
  phone_number: string;
  id: string;
  email: string;
  area?: IArea;
  government?: IGovernment;
  country?: ICountryForCustomer;
}

export interface IGovernment {
  code: string;
  EnName: string;
  address_by_google: string;
  latitude: number;
  ArName: string;
  rocket_code: string;
  longitude: number;
}

export interface ICountryForCustomer {
  country_code: string;
  ArName: string;
  ISO_code: string;
  EnName: string;
  last_city_code: number;
  pickup_status: string;
}
export interface IArea {
  EnName: string;
  ArName: string;
  code: string;
}
export interface IPromotion {
  customers_file_path: string;
  desc: string;
  id: string;
  user_id: string;
  updated_at: number;
  start_time: number;
  min_threshold: number;
  end_time: number;
  max_usage_times: number;
  max_discount_value: number;
  promo_code: string;
  type: string;
  created_at: number;
  discount_value: number;
}

export interface IModifier {
  name: string;
  price: number;
  id: string;
}
export interface IModifiersGroup {
  en_name: string;
  ar_name: string;
  id: string;
  modifiers: IModifier[];
}

export interface IItem {
  id: string;
  category_ar_name: string;
  category_en_name: string;
  ar_name: string;
  en_name: string;
  comment: string;
  image: string;
  is_gift: boolean | Gift;
  price: number;
  quantity: number;
  modifiers_group: IModifiersGroup[];
}
export interface IOrderDetails {
  items: IItem[];
  promotion: IPromotion;
  address: IDeliveryAddress;
  total_price: number;
  payment_method: string;
  sub_district_ar_name: string;
  sub_district_en_name: string;
  district_ar_name: string;
  district_en_name: string;
  total_invoices_without_promo_code: number;
  order_type: OrderType;
  comment: string;
}

export interface ISpecificOrder {
  store_id: string;
  order_code: string;
  estimated_customer_arrival_interval: number;
  updated_at: number;
  branch_id: string;
  created_at: number;
  name: string;
  total_invoices: number;
  id: string;
  delivery_fees: number;
  country_code: number;
  delivery_time: number;
  status: string;
  delivery_zones: IDeliveryZone[];
}

export interface IStore {
  pickup_prep_min: number;
  pickup_prep_max: number;
  store_id: string;
  detailed_address?: string;
  ar_detailed_address?: string;
  en_detailed_address?: string;
  address: string;
  lng: number;
  updated_at: number;
  phone: string;
  numbers: string[];
  name: string;
  ar_name?: string;
  en_name?: string;
  created_at: number;
  id: string;
  type: number;
  lat: number;
  app_version?: string;
  delivery_zones: IDeliveryZone[];
}
export interface IDeliveryZone {
  city_ar_name: string;
  city_en_name: string;
  district_ar_name: string;
  district_en_name: string;
  sub_district_ar_name: string;
  sub_district_en_name: string;
  sub_district_id: string;
}

export interface IStoreAppInfo {
  allow_reach: number;
  allow_leaderboard: number;
  delivery_time: number;
  activation_date: number;
  timezone: string;
  payment_status: number;
  created_at: number;
  converting_ratio: number;
  country_code: string;
  active_leaderboard: number;
  country_iso_code: string;
  background_color: string;
  updated_at: number;
  payment_notification: number;
  manager_id: string;
  api_key: string;
  end_trial: number;
  name: string;
  cover_image: string;
  id: string;
  pos?: string;
  payment_date: number;
  store_image: string;
  trial_duration: number;
  status: number;
  delivery_fees: number;
}
export interface IAddress {
  delivery_address: IDeliveryAddress;
  delivery_zone: IDeliveryDistrict;
}

export interface IDeliveryDistrict {
  district_ar_name: string;
  district_en_name: string;
  city_ar_name: string;
  city_en_name: string;
  sub_district_ar_name: string;
  sub_district_en_name: string;
  sub_district_id: string;
}

export interface IDeliveryAddress {
  id: string;
  street: string;
  building: string;
  floor: string;
  apartment: string;
  more_info: string;
  type: string;
}

export interface IWebPortalOrder {
  order_code: string;
  store_name: string;
  branch_name: string;
  total_price: string;
  id: string;
  status: OrderStatus;
  store_id?: string;
  delivery_fees?: number;
  payment_method: string;
  travel_mode?: string;
  someone_else: number;
  comment?: string;
  order_type: OrderType;
  customer_name: string;
  customer_id: string;
  estimated_delivery_min_time?: number;
  country_iso_code: string;
  customer_total_orders: string;
  promo_code: null;
  promotion_description: null;
  promotion_title: null;
  total_invoices_without_promo_code: null;
  updated_at: number;
  branch_id?: string;
  created_at: number;
  total_invoices: number;
  estimated_customer_arrival_interval?: number;
  estimated_delivery_interval?: number;
  estimated_delivery_max_time?: number;
  delivery_method: DeliveryMethods;
}
export interface IOrderingPortalLink {
  web_portal_link: string;
}

export interface IFiltersForStatistics {
  filters?: IWebPortalFilters;
}
export const getOrderingPortalLink = action(
  getOrderingPortalLinkAction.requested
);
export const getOrderingPortalLinkSuccess = action(
  getOrderingPortalLinkAction.fulfilled,
  payload<IOrderingPortalLink>()
);
export const getOrderingPortalLinkFailure = action(
  getOrderingPortalLinkAction.rejected,
  payload<Error>()
);

export const getWebPortalHistory = action(
  getWebPortalHistoryAction.requested,
  payload<IWebPortalHistoryPayload>()
);
export const getWebPortalHistorySuccess = action(
  getWebPortalHistoryAction.fulfilled,
  payload<IWebPortalOrders>()
);
export const getWebPortalHistoryFailure = action(
  getWebPortalHistoryAction.rejected,
  payload<Error>()
);
export const getWebPortalStatistics = action(
  getWebPortalStatisticsAction.requested,
  payload<IFiltersForStatistics>()
);
export const getWebPortalStatisticsSuccess = action(
  getWebPortalStatisticsAction.fulfilled,
  payload<IWebPortalStatistics>()
);
export const getWebPortalStatisticsFailure = action(
  getWebPortalStatisticsAction.rejected,
  payload<Error>()
);
