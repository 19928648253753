import { action, payload } from "ts-action";
import { getCustomerInfoAction } from "../../constants/actions";
import { ICustomerInfo } from "./lib";

export const getCustomerInfo = action(
  getCustomerInfoAction.requested,
  payload<string>()
);

export const getCustomerInfoSuccess = action(
  getCustomerInfoAction.fulfilled,
  payload<ICustomerInfo>()
);
export const getCustomerInfoFailure = action(
  getCustomerInfoAction.rejected,
  payload<Error>()
);
