import React, { useEffect, useMemo } from "react";
import * as styles from "./styles.module.sass";
import { Modal } from "@material-ui/core";
import { hot } from "react-hot-loader";
import { useDispatch } from "react-redux";
import { orderTracking } from "../../../../../redux-store/actions/order-tracking-action";
import { getCustomerInfo } from "../../../../../redux-store/actions/customer-actions";
import OrderTracking from "./OrderTracking";
import { useSelect } from "../../../../../../../hooks/useSelect";
import {
  getOrderDetails,
  getOrder,
  getBranchInfo,
  getStoreInfo,
  getExtraInfo,
} from "../../../../../redux-store/actions/past-order-actions";
import OrderInDetails from "../OrderDetails/OrderDetails/index";
import { ISpecificOrder } from "../../../../../redux-store/actions/lib";
import {
  getLocalizedCurrency,
  getLocalizedCountry,
} from "../../../../../../../../Utils/getLocalizedCurrency";
import i18n from "../../../../../../../i18n/i18n";
import Strings from "../../../../../i18n/strings/orderHistory";
import { useTranslation } from "../../../../../../WizardComponents/Branches/AddBranchForm/AddZones/RenderZonesFieldArray/useTranslation";
import OrderInfo from "./OrderInfo";
import { useRtlClass } from "../../../../../../../lib";
interface IProps {
  open: boolean;
  onClose: () => void;
  id: string;
}
export const addDeliveryFees = (left: number, order: ISpecificOrder) => {
  return Number(left + (order.delivery_fees || 0) || 0).toFixed(2);
};
export const useGetCountry = (iso_code: string): string => {
  return useMemo(() => {
    if (iso_code) {
      getLocalizedCountry(i18n.language, iso_code);
    }
    return getLocalizedCountry(i18n.language, iso_code);
  }, [i18n.language, iso_code]);
};
export const useGetCurrency = (countryIsoCode: string): string => {
  return useMemo(() => {
    if (countryIsoCode) {
      getLocalizedCurrency(countryIsoCode);
    }
    return getLocalizedCurrency(countryIsoCode);
  }, [i18n.language, countryIsoCode]);
};

const OrderDetails: React.FC<IProps> = ({ open, onClose, id }) => {
  const { t } = useTranslation("orderHistory");
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(orderTracking(id));
    dispatch(getCustomerInfo(id));
    dispatch(getOrderDetails(id));
    dispatch(getOrder(id));
    dispatch(getBranchInfo(id));
    dispatch(getStoreInfo(id));
    dispatch(getExtraInfo(id));
  }, [id, open]);

  const { status } = useSelect((state) => state.orderTrackingReducer);
  const { orderDetails } = useSelect((state) => state.orderDetailsReducer);
  const { order } = useSelect((state) => state.orderReducer);
  const { branchInfo } = useSelect((state) => state.getBranchInfoReducer);
  const { customerInfo } = useSelect((state) => state.getCustomerInfoReducer);
  const { storeInfo } = useSelect((state) => state.getStoreInfoReducer);
  const country = useGetCountry(storeInfo.country_iso_code);
  const currency = useGetCurrency(storeInfo.country_iso_code);

  const { extraInfo } = useSelect((state) => state.extraInfoReducer);
  const rtl = useRtlClass(styles);
  return (
    <Modal className={styles.main} open={open} onClose={onClose}>
      <div className={`${styles.wrapper} ${rtl}`}>
        <div className={styles.headerWrapper}>
          <span className={styles.headerTitle}>{t(Strings.orderDetails)}</span>
          <img
            src={require("../../../../../../../assets/icon-close.svg")}
            className={styles.closeIcon}
            onClick={onClose}
          />
        </div>
        <div className={styles.contentWrapper}>
          <OrderTracking status={status} />
          <div className={styles.separator} />
          <OrderInfo
            address={extraInfo}
            customer_country={country}
            delivery_time={storeInfo.delivery_time}
            delivery_fees={storeInfo.delivery_fees}
            orderType={orderDetails.order_type as any}
            BranchInfo={branchInfo}
            CustomerInfo={customerInfo}
            area={customerInfo.customer.area as any}
            country={customerInfo.customer.country as any}
            district_ar_name={orderDetails.district_ar_name}
            district_en_name={orderDetails.district_en_name}
            sub_district_ar_name={orderDetails.sub_district_ar_name}
            city_en_name={extraInfo.delivery_zone.city_en_name}
            sub_district_en_name={orderDetails.sub_district_en_name}
            government={customerInfo.customer.government as any}
            store={storeInfo}
            currency={currency}
            comment={orderDetails.comment}
          />
          <div className={styles.separator} />
          <OrderInDetails
            orderDetails={orderDetails}
            store_id={storeInfo.id}
            order_id={id}
            total_invoice={addDeliveryFees(order.total_invoices, order)}
            delivery_fees={order.delivery_fees}
            currency={currency}
            promotion={orderDetails.promotion}
            total_invoices_without_promo_code={addDeliveryFees(
              orderDetails.total_invoices_without_promo_code,
              order
            )}
          />
        </div>
      </div>
    </Modal>
  );
};

export default hot(module)(OrderDetails);
