import Modal from "react-responsive-modal";
import * as React from "react";
import * as styles from "./styles.module.sass";
import { hot } from "react-hot-loader";
import PointsOperationCard from "../PointOperationCard";
import RedemptionOperationCard from "../RedemptionOperationCard";
import RatingsCard from "../RatingsCard";
import { prefixImage } from "../UsersRanking/User";
import {
  useGetCustomerRedemptions,
  useGetCustomerReviews,
  useGetCustomerOperations,
  useGetCustomerProfile,
} from "./hooks";
import { ProfileSkeleton } from "./ProfileSkeleton";
import { translate, InjectedTranslateProps } from "react-i18next";
import Strings from "../../../i18n/strings/leaderboard";
import { useRtlClass } from "../../../lib";
import { useMemo } from "react";
import { useState } from "react";
import * as moment from "moment";
import { useSelect } from "../../../hooks/useSelect";

const useCalculateAgeFromTimeStamp = (ageTimeStamp: number) => {
  return useMemo(() => {
    return moment().diff(ageTimeStamp, "years", false);
  }, [ageTimeStamp]);
};

interface IProps {
  modal: boolean;
  customer: { id: string };
  close: () => void;
}

const reachedEnd = (e) =>
  e.target.offsetHeight + e.target.scrollTop >= e.target.scrollHeight;

const ListProblemInfo: React.FC<{ text: string }> = ({ text }) => {
  return (
    <div className={styles.noData}>
      <img className={styles.img} src={require("../../../assets/info.png")} />
      <span className={styles.text}>{text}</span>
    </div>
  );
};

const ProfileModal: React.FC<IProps & InjectedTranslateProps> = (props) => {
  const { customer, t } = props;
  const { profile, failure, success } = useGetCustomerProfile(customer.id);
  const { cashiers, loaded } = useSelect((state) => state.cashiersReducer);

  const {
    operations,
    nextOperationsPage,
    success: operationsSuccess,
    failure: operationsFailure,
  } = useGetCustomerOperations(
    customer.id,
    profile.points_operations,
    cashiers
  );
  const {
    reviews,
    nextReviewsPage,
    success: reviewsSuccess,
    failure: reviewsFailure,
  } = useGetCustomerReviews(customer.id, profile.reviews);
  const {
    redemptions,
    nextRedemptionsPage,
    success: redemptionsSuccess,
    failure: redemptionsFailure,
  } = useGetCustomerRedemptions(customer.id, profile.redemptions);
  const [enlargeImageModalState, setEnlargeImageModalState] = useState<{
    isOpen: boolean;
  }>({ isOpen: false });
  const handleReviewsScroll = (e) => reachedEnd(e) && nextReviewsPage();
  const handlePointOperationsScroll = (e) =>
    reachedEnd(e) && nextOperationsPage();
  const handleRedemptionScroll = (e) => reachedEnd(e) && nextRedemptionsPage();
  const openEnlargeImageModal = () => {
    setEnlargeImageModalState({ isOpen: true });
  };
  const closeEnlargeImageModal = () =>
    setEnlargeImageModalState({ isOpen: false });
  const customerEnlargeImage =
    profile &&
    (prefixImage(profile.image) || require("../../../assets/user.svg"));

  const gender = useMemo(() => {
    return [t(Strings.male), t(Strings.female)][profile.gender] || "";
  }, [profile.id]);
  const averageRating = useMemo(() => {
    if (profile.average_rating) {
      const roundedToNextTenth = Math.round(profile.average_rating * 10) / 10;
      return roundedToNextTenth.toString().replace(/\.0+$/, "");
    }
    return "NA";
  }, [profile.id]);

  let render = <ProfileSkeleton t={t} />;
  const age = useCalculateAgeFromTimeStamp(profile.birth_date);
  if (success && loaded) {
    render = (
      <div className={styles.wrapper}>
        <div className={styles.profileHeader}>
          <div className={styles.user}>
            <img
              src={
                prefixImage(profile.image) ||
                require("../../../assets/user.svg")
              }
              onClick={openEnlargeImageModal}
              className={styles.userImg}
            />
            <div className={styles.userData}>
              <p className={styles.name}>{profile.name}</p>
              <p className={styles.phone}>{profile.phone_number}</p>
              <p className={styles.mail}>{profile.email}</p>
              <p className={styles.old}>
                {gender}{" "}
                {profile.birth_date ? t(Strings.yearsOld, { count: age }) : ""}
              </p>
            </div>
          </div>
          <div className={styles.stats}>
            <div className={styles.statWrapper}>
              <p className={styles.title}>
                {t(Strings.totalInvoicesPart1)}
                <br /> {t(Strings.totalInvoicesPart2)}
              </p>
              <p className={styles.number}>{profile.total_invoices}</p>
            </div>
            <div className={styles.split} />
            <div className={styles.statWrapper}>
              <p className={styles.title}>
                {t(Strings.lifeTimePointsPart1)} <br />{" "}
                {t(Strings.lifeTimePointsPart2)}
              </p>
              <p className={styles.number}>{profile.total_points}</p>
            </div>
            <div className={styles.split} />
            <div className={styles.statWrapper}>
              <p className={styles.title}>
                {t(Strings.storeVisitsPart1)}
                <br /> {t(Strings.storeVisitsPart2)}
              </p>
              <p className={styles.number}>{profile.visits}</p>
            </div>
            <div className={styles.split} />
            <div className={styles.statWrapper}>
              <p className={styles.title}>
                {t(Strings.receivedPointsPart1)}
                <br /> {t(Strings.receivedPointsPart2)}
              </p>
              <p className={styles.number}>{profile.points}</p>
            </div>
            <div className={styles.split} />
            <div className={styles.statWrapper}>
              <p className={styles.title}>
                {t(Strings.overallRatingPart1)}
                <br /> {t(Strings.overallRatingPart2)}
              </p>
              <p className={styles.number}>{averageRating}/5</p>
            </div>
          </div>
        </div>
        <div className={styles.columnsWrapper}>
          <div className={styles.pointOperationsWrapper}>
            <div className={styles.title}>
              {t(Strings.pointsOperationsTitle)}
            </div>
            <div
              onScroll={handlePointOperationsScroll}
              className={styles.cardsWrapper}
            >
              {operationsSuccess &&
                (operations.length ? (
                  operations.map((pointOperation) => (
                    <PointsOperationCard
                      key={pointOperation.id}
                      pointsOperation={pointOperation}
                    />
                  ))
                ) : (
                  <ListProblemInfo text={t(Strings.noPointsOperations)} />
                ))}
              {operationsFailure && (
                <ListProblemInfo
                  text={t(Strings.errorLoadingPointsOperations)}
                />
              )}
            </div>
          </div>

          <div className={styles.redemptionOperationsWrapper}>
            <div className={styles.title}>{t(Strings.redemptionsTitle)}</div>
            <div
              onScroll={handleRedemptionScroll}
              className={styles.cardsWrapper}
            >
              {redemptionsSuccess &&
                (redemptions.length ? (
                  redemptions.map((redemptionOperation) => (
                    <RedemptionOperationCard
                      key={redemptionOperation.id}
                      redemptionOperation={redemptionOperation}
                    />
                  ))
                ) : (
                  <ListProblemInfo text={t(Strings.noRedemptions)} />
                ))}
              {redemptionsFailure && (
                <ListProblemInfo text={t(Strings.errorLoadingRedemptions)} />
              )}
            </div>
          </div>

          <div className={styles.ratingsWrapper}>
            <div className={styles.title}>{t(Strings.ratingsTitle)}</div>
            <div onScroll={handleReviewsScroll} className={styles.cardsWrapper}>
              {reviewsSuccess &&
                (reviews.length ? (
                  reviews.map((review) => (
                    <RatingsCard key={review.id} review={review} />
                  ))
                ) : (
                  <ListProblemInfo text={t(Strings.noRatings)} />
                ))}
              {reviewsFailure && (
                <ListProblemInfo text={t(Strings.errorLoadingRatings)} />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
  if (failure) {
    render = (
      <div className={styles.wrapper}>
        <div className={styles.rejectedText}>
          <h1>{t(Strings.somethingWentWrong)}</h1>
        </div>
      </div>
    );
  }
  const rtlClass = useRtlClass(styles);
  return (
    <Modal
      open={props.modal}
      onClose={props.close}
      showCloseIcon={false}
      classNames={{
        modal: `${styles.modal} ${rtlClass}`,
        overlay: styles.overlay,
      }}
      animationDuration={1000}
    >
      {render}
      {profile && enlargeImageModalState.isOpen && (
        <Modal
          open={enlargeImageModalState.isOpen}
          onClose={closeEnlargeImageModal}
          showCloseIcon={false}
          classNames={{
            modal: `${styles.modal} ${rtlClass} ${styles.customerImagePopup}`,
            overlay: styles.overlay,
          }}
          animationDuration={1000}
        >
          <div className={styles.layout} />
          <p className={styles.photoOf}>
            {t(Strings.photoOf)}
            <span className={styles.customerName}>{profile.name}</span>
          </p>
          <img className={styles.customerImg} src={customerEnlargeImage} />
          <div
            onClick={closeEnlargeImageModal}
            title={"Close"}
            className={styles.closeWrapper}
          >
            <img
              className={styles.close}
              src={require("../../../assets/add.svg")}
            />
          </div>
        </Modal>
      )}
    </Modal>
  );
};

export default hot(module)(
  translate("leaderboard")(ProfileModal) as any
) as React.FC<IProps>;
