import React, { useState } from "react";
import * as styles from "./styles.module.sass";
import { hot } from "react-hot-loader";
import { Icon } from "./icon";

import {
  IOrderTracking,
  OrderStatus,
} from "../../../../../../redux-store/actions/lib";
import Strings from "../../../../../../i18n/strings/orderHistory";
import { useTranslation } from "../../../../../../../WizardComponents/Branches/AddBranchForm/AddZones/RenderZonesFieldArray/useTranslation";
import { useRtlClass } from "shopx-shared-components/src/hooks/useRtlClassName";
import { format } from "date-fns";
import { useSelect } from "../../../../../../../../hooks/useSelect";
interface IProps {
  status: IOrderTracking;
}

const OrderTracking: React.FC<IProps> = (props) => {
  const { status } = props;
  const { t } = useTranslation("orderHistory");
  const textStatus = {
    [OrderStatus.accepted]: t(Strings.accepted),
    [OrderStatus.got_picked_up]: t(Strings.got_picked_up),
    [OrderStatus.ready_for_pickup]: t(Strings.ready_for_pickup),
    [OrderStatus.received]: t(Strings.order_received),
    [OrderStatus.rejected]: t(Strings.rejected),
    [OrderStatus.seen]: t(Strings.seen),
    [OrderStatus.sent]: t(Strings.sent),
    [OrderStatus.canceled_by_agent]: t(Strings.canceled_by_agent),
    [OrderStatus.canceled_by_customer]: t(Strings.canceled_by_customer),
    [OrderStatus.canceled_by_cashier]: t(Strings.canceled_by_cashier),
    [OrderStatus.customer_no_show]: t(Strings.customer_no_show),
    [OrderStatus.scheduled]: t(Strings.scheduled),
    [OrderStatus.delivered]: t(Strings.delivered),
    [OrderStatus.out_for_delivery]: t(Strings.out_for_delivery),
  };
  const [collapse, setCollapse] = useState(false);
  const toggle = () => setCollapse(!collapse);
  const orderFailed = {
    [OrderStatus.rejected]: t(Strings.rejected),
    [OrderStatus.canceled_by_agent]: t(Strings.canceled_by_agent),
    [OrderStatus.canceled_by_customer]: t(Strings.canceled_by_customer),
    [OrderStatus.canceled_by_cashier]: t(Strings.canceled_by_cashier),
    [OrderStatus.customer_no_show]: t(Strings.customer_no_show),
  };
  const rtl = useRtlClass(styles);
  const { country_iso_code } = useSelect((state) => state.storeReducer.store);

  return (
    <div className={`${styles.wrapper} ${rtl}`}>
      <div className={styles.orderTrackingHeader}>
        <span className={styles.title}>{t(Strings.orderTracking)}</span>
      </div>
      {(status.statuses || []).map((orderStatusLogItem, n) => (
        <>
          <li key={orderStatusLogItem.id} className={styles.progressItem}>
            <>
              <a className={styles.link} id={`id`}>
                <Icon
                  orderFailed={orderFailed[orderStatusLogItem.status]}
                  states={n === status.statuses.length - 1}
                />

                <div className={styles.orderStateWrapper}>
                  <span className={styles.orderState}>
                    {textStatus[orderStatusLogItem.status]}
                  </span>
                  <div className={styles.timeWrapper} onClick={toggle}>
                    <span className={styles.time}>
                      {format(
                        new Date(orderStatusLogItem.store_time),
                        "MMM DD, YYYY h:mm A",
                        {
                          locale: country_iso_code,
                        }
                      )}
                    </span>
                  </div>
                </div>
              </a>
            </>
            <div
              className={`${styles.lineForState}`}
              style={{
                backgroundColor:
                  n === status.statuses.length - 1 ? "#d9d9d9" : "#41126c",
              }}
            />
            {(orderStatusLogItem.status === OrderStatus.rejected ||
              orderStatusLogItem.status === OrderStatus.canceled_by_cashier) &&
              orderStatusLogItem.order_status_reason !== null && (
                <div className={styles.rejectionReason}>
                  <img src={require("../../../../../../assets/error.svg")} />
                  <div className={styles.reasonWrapper}>
                    <span className={styles.reasonTitle}>
                      {t(Strings.rejectionReason)}
                    </span>
                    <span className={styles.reason}>
                      {orderStatusLogItem.order_status_reason.en_text} -{" "}
                      {orderStatusLogItem.order_status_reason.ar_text}
                    </span>
                  </div>
                </div>
              )}
          </li>
        </>
      ))}
    </div>
  );
};
export default hot(module)(OrderTracking);
