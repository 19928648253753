import { call, put, select, takeLatest } from "redux-saga/effects";
import { selectToken } from "../../../../redux-store/selectors";
import { getZonesAction } from "../../constants/actions";
import {
  getZonesSuccess,
  getZones,
  getZonesFailure,
} from "../actions/get-zones-actions";
import { getZonesAPI } from "../../axios/getZones";
import { union } from "ts-action";
const actionType = union({ getZones });

function* getZonesSaga(action: typeof actionType) {
  try {
    const token = yield select(selectToken);
    const res = yield call(getZonesAPI, token, action.payload.area_id);

    yield put(getZonesSuccess(res.data));
  } catch (error) {
    yield put(getZonesFailure(error));
  }
}

export function* watchGetZonesSaga() {
  yield takeLatest(getZonesAction.requested, getZonesSaga);
}
