import { reducer, on } from "ts-action";
import { withLoadingReducer, ILoadingState } from "./withLoadingState";
import { getOrderingCategoriesAction } from "../../constants/actions";
import {
  getOrderingCategoriesSuccess,
  reOrderCategory,
  editOrderingCategory,
  deleteOrderingCategory,
  createOrderingCategory,
  createOrderingCategorySuccess,
} from "../actions/categoryActions";
import { IOrderingMenuCategory } from "../../lib/types";
import { updateItemOrdering } from "../../../../redux-store/reducers/menuReducer";
import { optimistic, OptimisticState } from "redux-optimistic-ui";
import { Reducer } from "redux";
import { IOptReaction } from "../actions/lib";
import { createOrderingItemSuccess } from "../actions";

export interface IState {
  categories: string[];
  categoriesById: { [x: string]: IOrderingMenuCategory };
}

const initialState: IState = {
  categories: [],
  categoriesById: {},
};

export const handleOptSuccess = <
  S extends { [x: string]: any } = {},
  A extends IOptReaction = any
>(
  idsKey: keyof S,
  storeKey: keyof S
) => (state: S, { meta, payload }: A) => {
  const updatedIds = state[idsKey].map((id: string) =>
    id === String(meta.optimistic.id) ? payload.id : id
  );
  const updatedStore = {
    ...state[storeKey],
    [payload.id]: payload,
  };
  return {
    ...state,
    [idsKey]: updatedIds,
    [storeKey]: updatedStore,
  };
};

export const orderingMenuCategoriesReducer = optimistic(
  withLoadingReducer(
    reducer<IState>(
      [
        on(createOrderingCategory, (state, { payload, meta }) => {
          const { id } = meta.optimistic;
          return {
            ...state,
            categories: [...state.categories, String(id)],
            categoriesById: {
              ...state.categoriesById,
              [id]: { ...payload, id: String(id) },
            },
          };
        }),
        on(
          createOrderingCategorySuccess,
          handleOptSuccess("categories", "categoriesById")
        ),
        on(getOrderingCategoriesSuccess, (state, { payload }) => {
          const sortedOrders = payload
            .sort((a, b) => a.order - b.order)
            .map((cat, i) => ({ ...cat, order: i + 1 }));
          return {
            ...state,
            categories: sortedOrders.map((a) => a.id),
            categoriesById: sortedOrders.reduce(
              (acc, cat) => ({
                ...acc,
                [cat.id]: cat,
              }),
              {}
            ),
          };
        }),
        on(reOrderCategory, (state, { payload }) => {
          const oldItemData = state.categoriesById[payload.id];
          const sortedOrders = updateItemOrdering(
            state.categories.map((id) => state.categoriesById[id]),
            { ...oldItemData, ...payload },
            oldItemData
          );
          return {
            ...state,
            categories: sortedOrders.map(({ id }) => id),
            categoriesById: sortedOrders.reduce(
              (acc, cat) => ({
                ...acc,
                [cat.id]: cat,
              }),
              {}
            ),
          };
        }),

        on(editOrderingCategory, (state, { payload }) => {
          const oldCategory = state.categoriesById[payload.id];
          return {
            ...state,
            categoriesById: {
              ...state.categoriesById,
              [payload.id]: {
                ...oldCategory,
                ...payload,
              },
            },
          };
        }),

        on(deleteOrderingCategory, (state, { payload }) => {
          const oldCategory = state.categoriesById[payload];
          return {
            ...state,
            categories: state.categories.filter(
              (cat) => cat !== oldCategory.id
            ),
          };
        }),
        on(createOrderingItemSuccess, (state, { payload }) => {
          const oldCat = state.categoriesById[payload.category_id];
          return {
            ...state,
            categoriesById: {
              ...state.categoriesById,
              [oldCat.id]: {
                ...oldCat,
                total_items: oldCat.total_items + 1,
              },
            },
          };
        }),
      ],
      initialState
    ),
    getOrderingCategoriesAction
  )
) as Reducer<OptimisticState<IState & ILoadingState>>;
