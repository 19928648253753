export default {
  title: "Add Item",
  english_itemName: "Name in English",
  arabic_itemName: "Name in Arabic",
  englishItemNamePlaceholder: "Soup",
  arabicItemNamePlaceholder: "شوربة",
  en_description: "Description in English",
  ar_description: "Description in Arabic",
  description_placeholder: "Describe ingredients, preparation methods, etc.",
  branchesSearchPlaceholder: "Search for branches...",
  add_description: "Add description in another language",
  unsupportedText: "Unsupported file type...",
  image_label: "Photo ",
  upload_photo: "Upload photo",
  image_description: "File Requirements: JPG, PNG up to 5MB.",
  price: "Price",
  chooseABranch: "Choose a branch…",
  all: "All",
  selectAll: "Select All",
  unSelectAll: "Unselect All",
  branchesSelected: "Branches selected",
  noBranchesFound: "No Branches found...",
  vat: "Tax",
  addModifier: "Add Modifiers",
  itemsAvailableIn: "This item available in:",
  selectBranch: "Select branch",
  modifier_description: "",
  noSavedModifier: "No saved modifiers",
  selectModifier: "Saved Modifier Groups",
  add: "add",
  addNewModifierGroup: "Add Modifier Group",
  addNewItem: "Add Item",
  priceAfterVAT: "Price after tax:",
  editItem: "Save Edits",
  optional: "(optional)",
  item: "Item",
  additionalPrice: "Additional price",
  addModifierGroup: "Add Modifier Group",
  editModifierGroup: "Edit the modifier group",
  modifierName: "Name in English",
  modifier_placeholder: "Choice of salads",
  modifierNameInArabic: "Name in Arabic",
  foodicsIntegrationID: "Integration Id",
  foodicsIntegrationIDPlaceholder: "Integration Id",
  minimumItems: " Minimum items the customer can choose",
  maximumItems: "Maximum items the customer can choose",
  isThisRequired: "Is this modifier required?",
  required: "Required",
  modifier_optional: "Optional",
  saveModifier: "Save modifier in the modifiers list",
  saveModifierDescription: "You can add this modifier to another item quickly",
  cancel: "Cancel",
  submit: "Save",
  items: "Items",
  name: "Name",
  arName: "Name in Arabic",
  addAnotherItem: "Add Another Item",
  vatText: "Modifier item price after VAT:",
};
