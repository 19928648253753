import { call, select, takeLatest } from "redux-saga/effects";
import * as actions from "../actions/modifierGroupsAction";

import { selectToken } from "../../../../redux-store/selectors";
import { CreateOrderingSuggestedModifierGroup } from "../../axios/createOrderingSavedModifierGroup";
import { createOrderingSuggestedModifiersGroupsAction } from "../../constants/actions";
import { handleOptimism } from "./handleOptimism";
import { customNotify } from "../../SharedComponent/Notification";
import { translateMenuError } from "./changeItemCategorySaga";
import menuErrorMessages from "../../i18n/strings/menuErrorMessages";

function* createSuggestedModifierGroupSaga({ payload }) {
  try {
    const token = yield select(selectToken);
    const suggestedModifier = yield call(
      CreateOrderingSuggestedModifierGroup,
      token,
      payload
    );

    return { ...payload, ...suggestedModifier.data };
  } catch (e) {
    customNotify.error(
      translateMenuError(menuErrorMessages.createModifierGroup)
    );
    throw e;
  }
}

export function* watchCreateSuggestedModifierGroupSaga() {
  yield takeLatest(
    createOrderingSuggestedModifiersGroupsAction.requested,
    handleOptimism(createSuggestedModifierGroupSaga, {
      success: actions.createOrderingSuggestedModifiersGroupSuccess,
      failure: actions.createOrderingSuggestedModifiersGroupFailure,
    })
  );
}
