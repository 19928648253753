import { PersistConfig, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";
import { reducer as form, FormStateMap, FormState } from "redux-form";
import { loadingBarReducer } from "react-redux-loading-bar";
import authReducer from "./reducers/authReducer";
import countriesReducer from "./reducers/countriesReducer";
import createCompetitionReducer from "./reducers/createCompetitionReducer";
import competitionsReducer from "./reducers/competitionsReducer";
import competitionReducer from "./reducers/competitionReducer";
import userRankingReducer from "./reducers/userRankingReducer";
import addMoreReducer from "./reducers/addMoreReducer";
import editCompetitionReducer from "./reducers/editCompetitionReducer";
import collapseReducer from "./reducers/collapseReducer";
import tutorialReducer from "./reducers/tutorialReducer";
import storeReducer from "./reducers/storeReducer";
import menuReducer from "./reducers/menuReducer";
import giftListReducer from "./reducers/giftListReducer";
import branchesReducer from "./reducers/branchesReducer";
import cashiersReducer from "./reducers/cashiersReducer";
import pickupReducers from "../Components/Pickup/redux-store/reducers";
import { getSingleBranchReducer } from "../Components/WizardComponents/Branches/AddBranchForm/redux/getSingleBranchReducer";
export interface IFormState {
  gift_1: FormState;
  gift_2: FormState;
  gift_3: FormState;
  gift_4: FormState;
  gift_5: FormState;
  confirmationForm: FormState;
  storeForm: FormState;
  addCashierForm: FormState;
  addUserForm: FormState;
  myAccountForm: FormState;
  changePasswordForm: FormState;
  addBlockedUserForm: FormState;
  PickupBranchesForm: FormState;
}

type returnTypeInferrer<T> = T extends (state, action) => infer U ? U : never;

export interface IRootReducerState extends rootReducerType {
  form: FormStateMap;
}

const rootReducer = combineReducers({
  loadingBar: loadingBarReducer,
  form,
  authReducer: persistReducer(
    {
      storage,
      key: "auth",
    },
    authReducer
  ),
  countriesReducer,
  menuReducer,
  createCompetitionReducer,
  userRankingReducer,
  competitionsReducer,
  competitionReducer,
  addMoreReducer,
  editCompetitionReducer,
  collapseReducer,
  tutorialReducer,
  cashiersReducer,
  storeReducer,
  giftListReducer,
  branchesReducer,
  getSingleBranchReducer,
  ...pickupReducers,
});

type rootReducerType = returnTypeInferrer<typeof rootReducer>;

const persistorOptions: PersistConfig = {
  key: "root",
  storage,
  whitelist: ["appReducer"],
};

const persistedReducer = persistReducer(persistorOptions, rootReducer);

export default persistedReducer;
