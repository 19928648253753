import * as constants from "../../constants/actions";
import { ICreateMenu } from "../../../../types";
import { action, payload } from "ts-action";
import { IMenu } from "../../lib/types";

export const createMenu = action(
  constants.createOrderingMenuAction.requested,
  payload<ICreateMenu>()
);
export const createMenuSuccess = action(
  constants.createOrderingMenuAction.fulfilled,
  payload<IMenu>()
);
export const createMenuFailure = action(
  constants.createOrderingMenuAction.rejected,
  payload<Error>()
);

export const getMenus = action(constants.getOrderingMenusAction.requested);
export const getMenusSuccess = action(
  constants.getOrderingMenusAction.fulfilled,
  payload<IMenu[]>()
);
export const getMenusFailure = action(
  constants.getOrderingMenusAction.rejected,
  payload<Error>()
);
