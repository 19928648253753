import reviews from "./reviews";
export default {
  competitionMissedTitle: "Looks like you’ve missed {{month}}",
  competitionNotStartedTitle: "{{currentMonth}} didn't start",
  competitionNotStartedSubTitle: "Please wait for the beginning of the month",
  editGifts: "Edit gifts",
  deleteBooking: "Delete booking",
  password: "Password",
  deleteCompetitionModalTitle: "Delete Competition",
  enterPasswordToDeletePart1: "Enter your password to delete",
  enterPasswordToDeletePart2: "{{nextMonth}} competition",
  delete: "Delete",
  receiptCode: "Receipt ID",
  createCompetitionTitle: "Create {{nextMonth}}’s New Competition",
  createCompetitionSubTitle: "Competition Gifts",

  extendGifts: "Add Rank #4, #5",
  removeGiftExtension: "Remove Rank #4, #5",

  giftFormTitle: "Rank #{{rank}}",
  giftTitlePlaceholder: "Gift title",
  giftDescriptionPlaceholder: "Describe the gift",

  somethingWentWrong: "Something went wrong!",

  editCompetitionTitle: "Edit {{nextMonth}}’s New Competition",
  editCompetitionSubTitle: "Gifts",

  tutorialFirstStepTitle: "Year Timeline",
  tutorialFirstStepSubTitle:
    "You can view last months results, manage the current running competition, and setup the next month competition",
  tutorialSecondStepTitle: "Months",
  tutorialSecondStepSubTitle: "You can click on any month to view its setup",
  tutorialThirdStepTitle: "Competition gifts",
  tutorialThirdStepSubTitle: "Here you can view the current competition gifts",
  tutorialFourthStepTitle: "Book for next month",
  tutorialFourthStepSubTitle: "Create a competition for the next month",
  tutorialFifthStepTitle: "Participated Customers",
  tutorialFifthStepSubTitle:
    "You can view the participated customers in your competition",
  tutorialSixthStepTitle: "Filter Customers",
  tutorialSixthStepSubTitle:
    "You can filter the customers who compete in your leaderboard by the customers with the app and customers without the app",
  tutorialTitle: "Your Leaderboard",
  tutorialSubTitle:
    "Engage your customers in a competition and reward your most loyal customers with the ultimate prize",
  tutorialStart: "Tell me how?",
  tutorialTempGiftName: "Gift Name",
  tutorialTempGiftsListTitle: "Gifts",

  "1st": "1st",
  "2nd": "2nd",
  "3rd": "3rd",
  "4th": "4th",
  "5th": "5th",
  giftRanking: "place",
  nextMonthBooked: "{{nextMonthName}} Booked",
  bookNextMonth: "Book {{nextMonthName}}",

  restartTutorialButtonText: "How it works",

  noCompetitionTitle: "No competition created yet",
  noCompetitionSubTitle: "Book one now!",

  customerReceiptPoints: "Points",
  customerReceiptRemainingPoints: "Remaining",
  compensation: "Compensation",
  invoice: "Invoice",
  branch: "Branch",
  cashierName: "Cashier name",
  male: "Male",
  female: "Female",
  yearsOld: ", {{count}} years",
  totalInvoicesPart1: "Total",
  totalInvoicesPart2: "invoices",
  lifeTimePointsPart1: "Lifetime",
  lifeTimePointsPart2: "points",
  storeVisitsPart1: "Store",
  storeVisitsPart2: "visits",
  receivedPointsPart1: "Current",
  receivedPointsPart2: "points",
  overallRatingPart1: "Overall",
  overallRatingPart2: "rating",
  pointsOperationsTitle: "Points Operations",
  redemptionsTitle: "Redemption Operations",
  ratingsTitle: "Ratings",
  noPointsOperations: "User has not made any points operations yet",
  noRedemptions: "User has not made any redemptions yet",
  noRatings: "User has not made any reviews yet",
  errorLoadingPointsOperations: "Error loading points operations",
  errorLoadingRedemptions: "Error loading redemptions",
  errorLoadingRatings: "Error loading ratings",
  photoOf: "Photo of",

  emoji1: reviews.emoji1,
  emoji2: reviews.emoji2,
  emoji3: reviews.emoji3,
  emoji4: reviews.emoji4,
  emoji5: reviews.emoji5,
  readMore: "Read more...",
  next: "Next",
  last: "Last",
  gifts: "Gifts",
  allUsers: "All Users",
  all: "All",
  withTheApp: "With the app",
  withoutTheApp: "Without the app",
  filters: "Filters",
  pointsWithCount: "{{count}} Points",
  noUsers: "There are no users",
  createCompetition: "Create",
  editCompetition: "Edit",
  errorInternetConnection: "Check your internet connection",
  errorOccurredRetrying: "Internal error occurred, retrying...",
  errorEnterSpecifiedNumberOfGifts: "Please enter 3 or 5 gifts",
  errorLoadingCompetition: "Failed to load competition data, please try again",
  errorWrongPassword: "Password is invalid",

  successCompetitionCreated: "Competition created successfully",
  successCompetitionDeleted: "Competition deleted successfully",
  successCompetitionEdited: "Competition edited successfully",
  successStoreEditsSaved: "Your changes have been saved",

  competitorName: "Competitor Name",

  totalInvoicesTableHead: "Total Invoices",

  tableHeadUserPhoneNumber: "Phone Number",
  tableHeadUserName: "Name",
  tableHeadUserPoints: "Points",
  tableHeadUserMoneySpent: "Money Spent",
  tableHeadUserEmail: "E-mail",

  statsTotalInvoices: "Total Month Revenue",
  statsTotalVisits: "Total Visits",

  firstCountUsers: "First customer",
  firstCountUsers_plural: "First {{count}} customers",
  statsAllUsers: "All customers",
};
