import axios from "./axios";
import { AxiosReturn } from "./types";
import * as Requests from "./requests";

export const hasNextLeaderboard = (token): AxiosReturn<{}> => {
  return axios.get(Requests.hasLeaderboard, {
    baseURL: process.env.LUMEN_ENDPOINT,
    headers: {
      token,
    },
  });
};
