import random from "uuid/v4";
import * as React from "react";
import Skeleton from "react-skeleton-loader";
import * as styles from "./styles.module.sass";
import { InjectedTranslateProps } from "react-i18next";
import Strings from "../../../i18n/strings/leaderboard";

const getArray = (length: number) => new Array(length).fill("0").map(random);

const StatusSkeleton: React.FC<{ fields: string[] }> = ({ fields }) => (
  <div className={styles.cardWrapper}>
    <div className={styles.date}>
      <img
        className={styles.logo}
        src={require("../../../assets/icon-time.svg")}
      />
      <Skeleton widthRandomness={0} width={"10vw"} height={"2rem"} />
    </div>
    <div className={styles.statsWrapper}>
      {fields.map((a) => (
        <div className={styles.status} key={a + "ddd"}>
          <h5 className={styles.statusLabel}>{a}</h5>
          <Skeleton widthRandomness={0} width={"100%"} height={"2rem"} />
        </div>
      ))}
    </div>
  </div>
);
export const ProfileSkeleton: React.FC<InjectedTranslateProps> = ({ t }) => (
  <div className={styles.wrapper}>
    <div className={styles.profileHeader}>
      <div className={styles.user}>
        <Skeleton
          widthRandomness={0}
          width={"10rem"}
          height={"10rem"}
          borderRadius={"50%"}
        />
        <div className={styles.userData}>
          {["name", "phone", "old", "mail"].map((a) => (
            <p className={styles[a]} key={a}>
              <Skeleton widthRandomness={0} width={"15rem"} height={"2rem"} />
            </p>
          ))}
        </div>
      </div>
      <div className={styles.stats}>
        {getArray(5).map((a) => (
          <Skeleton
            key={a}
            widthRandomness={0}
            width={"7vw"}
            height={"7vw"}
            borderRadius={"50%"}
          />
        ))}
      </div>
    </div>
    <div className={styles.columnsWrapper}>
      <div className={styles.pointOperationsWrapper}>
        <div className={styles.title}>{t(Strings.pointsOperationsTitle)}</div>
        <div className={styles.cardsWrapperSkeleton}>
          {getArray(3).map((a) => (
            <StatusSkeleton
              key={a}
              fields={[
                t(Strings.customerReceiptPoints),
                t(Strings.customerReceiptRemainingPoints),
                t(Strings.invoice),
                t(Strings.branch),
              ]}
            />
          ))}
        </div>
      </div>

      <div className={styles.redemptionOperationsWrapper}>
        <div className={styles.title}>{t(Strings.redemptionsTitle)}</div>
        <div className={styles.cardsWrapperSkeleton}>
          <div className={styles.redemptionCardWrapper}>
            <div className={styles.imgWrapper}>
              <Skeleton
                widthRandomness={0}
                width={"6rem"}
                height={"6rem"}
                borderRadius={"50%"}
              />
              <Skeleton widthRandomness={0} width={"7vw"} height={"2rem"} />
            </div>
            <div className={styles.statsWrapper}>
              <div className={styles.date}>
                <h5 className={styles.dateLabel} />
                <Skeleton widthRandomness={0} width={"7vw"} height={"2rem"} />
              </div>
              <div className={styles.status}>
                <h5 className={styles.statusLabel}>
                  {t(Strings.customerReceiptPoints)}
                </h5>
                <Skeleton widthRandomness={0} width={"100%"} height={"2rem"} />
              </div>
              <div className={styles.status}>
                <h5 className={styles.statusLabel}>{t(Strings.branch)}</h5>
                <Skeleton widthRandomness={0} width={"100%"} height={"2rem"} />
              </div>
            </div>
          </div>
          <div className={styles.redemptionCardWrapper}>
            <div className={styles.imgWrapper}>
              <Skeleton
                widthRandomness={0}
                width={"6rem"}
                height={"6rem"}
                borderRadius={"50%"}
              />
              <Skeleton widthRandomness={0} width={"7vw"} height={"2rem"} />
            </div>
            <div className={styles.statsWrapper}>
              <div className={styles.date}>
                <h5 className={styles.dateLabel} />
                <Skeleton widthRandomness={0} width={"7vw"} height={"2rem"} />
              </div>
              <div className={styles.status}>
                <h5 className={styles.statusLabel}>
                  {t(Strings.customerReceiptPoints)}
                </h5>
                <Skeleton widthRandomness={0} width={"100%"} height={"2rem"} />
              </div>
              <div className={styles.status}>
                <h5 className={styles.statusLabel}>{t(Strings.branch)}</h5>
                <Skeleton widthRandomness={0} width={"100%"} height={"2rem"} />
              </div>
            </div>
          </div>
          <div className={styles.redemptionCardWrapper}>
            <div className={styles.imgWrapper}>
              <Skeleton
                widthRandomness={0}
                width={"6rem"}
                height={"6rem"}
                borderRadius={"50%"}
              />
              <Skeleton widthRandomness={0} width={"7vw"} height={"2rem"} />
            </div>
            <div className={styles.statsWrapper}>
              <div className={styles.date}>
                <h5 className={styles.dateLabel} />
                <Skeleton widthRandomness={0} width={"7vw"} height={"2rem"} />
              </div>
              <div className={styles.status}>
                <h5 className={styles.statusLabel}>
                  {t(Strings.customerReceiptPoints)}
                </h5>
                <Skeleton widthRandomness={0} width={"100%"} height={"2rem"} />
              </div>
              <div className={styles.status}>
                <h5 className={styles.statusLabel}>{t(Strings.branch)}</h5>
                <Skeleton widthRandomness={0} width={"100%"} height={"2rem"} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.ratingsWrapper}>
        <div className={styles.title}>{t(Strings.ratingsTitle)}</div>
        <div className={styles.cardsWrapperSkeleton}>
          <div className={styles.ratingCardWrapper}>
            <div className={styles.date}>
              <Skeleton widthRandomness={0} width={"100%"} height={"2rem"} />
            </div>
            <div className={styles.reviewStatus}>
              <Skeleton
                widthRandomness={0}
                width={"6rem"}
                height={"6rem"}
                borderRadius={"50%"}
              />
              <Skeleton widthRandomness={0} width={"12vw"} height={"2rem"} />
            </div>
            <div className={styles.reviewText}>
              <Skeleton widthRandomness={0} width={"100%"} height={"5rem"} />
            </div>
          </div>
          <div className={styles.ratingCardWrapper}>
            <div className={styles.date}>
              <Skeleton widthRandomness={0} width={"100%"} height={"2rem"} />
            </div>
            <div className={styles.reviewStatus}>
              <Skeleton
                widthRandomness={0}
                width={"6rem"}
                height={"6rem"}
                borderRadius={"50%"}
              />
              <Skeleton widthRandomness={0} width={"12vw"} height={"2rem"} />
            </div>
            <div className={styles.reviewText}>
              <Skeleton widthRandomness={0} width={"100%"} height={"5rem"} />
            </div>
          </div>
          <div className={styles.ratingCardWrapper}>
            <div className={styles.date}>
              <Skeleton widthRandomness={0} width={"100%"} height={"2rem"} />
            </div>
            <div className={styles.reviewStatus}>
              <Skeleton
                widthRandomness={0}
                width={"6rem"}
                height={"6rem"}
                borderRadius={"50%"}
              />
              <Skeleton widthRandomness={0} width={"12vw"} height={"2rem"} />
            </div>
            <div className={styles.reviewText}>
              <Skeleton widthRandomness={0} width={"100%"} height={"5rem"} />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);
