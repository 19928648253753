import i18n from "../src/i18n/i18n";
import memoize from "lodash/memoize";
import { CURRENCIES } from "./currencies";
import { useContext, useMemo } from "react";
import StoreData from "../src/contexts/StoreData";
import { COUNTRIES } from "./countries";
export const getLocalizedCurrency = memoize((iso_code: string) => {
  const lang = i18n.language;
  const langCurrencies = CURRENCIES[lang.slice(0, 2)];
  return (
    (langCurrencies && langCurrencies[iso_code]) || CURRENCIES.en[iso_code]
  );
});

export const useGetCurrency = () => {
  const { storeData } = useContext(StoreData);
  const countryIsoCode = (storeData && storeData.country_iso_code) || "eg";
  return useMemo(
    () => {
      return getLocalizedCurrency(countryIsoCode);
    },
    [i18n.language, storeData]
  );
};

export const getLocalizedCountry = (lang: string, iso_code: string) => {
  // @ts-ignore
  const langCountries = COUNTRIES[lang.slice(0, 2)];
  // @ts-ignore
  return langCountries[iso_code] || COUNTRIES.en[iso_code];
};
