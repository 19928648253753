import * as React from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import * as styles from "./styles.module.sass";
import { useToggleState } from "../../../Utils/custom-hooks/useToggleState";
import { useEffect, useState } from "react";
import { ISelectItemData } from "../FormElements/RenderDropDownSelect";

interface ISelectItemProps {
  item: ISelectItemData;
}

const SelectItem: React.FunctionComponent<ISelectItemProps> = ({ item }) => {
  return <div className={styles.item}>{item.label}</div>;
};

interface IRenderDropDownSelectProps {
  options: ISelectItemData[];
  onChange: (value: ISelectItemData) => void;
  initialValue?: ISelectItemData;
  className?: string;
  disabled?: boolean;
  renderSelectItem?: (item: ISelectItemData) => React.ReactChild;
  openModifierGroup?: () => void;
  renderSelectToggle?: (item: ISelectItemData) => React.ReactChild;
  isOpen?: boolean;
}

const defaultRenderSelectToggle = (item: ISelectItemData) => item.label;
const SelectItemWrapper: React.FunctionComponent<{
  onSelect: (value: any) => void;
  value: any;
}> = (props) => {
  const onSelect = () => props.onSelect(props.value);
  return <DropdownItem onClick={onSelect}>{props.children}</DropdownItem>;
};

export const CustomDropDownSelect: React.FunctionComponent<IRenderDropDownSelectProps> = (
  props
) => {
  const {
    options,
    className,
    renderSelectItem,
    disabled,
    renderSelectToggle,
    onChange,
    initialValue,
  } = props;
  const [value, selectValue] = useState(initialValue || options[0]);
  const [isOpen, toggleMenu] = useToggleState(false);
  useEffect(() => {
    if (initialValue) {
      selectValue(initialValue);
    }
  }, [initialValue]);
  const handleChange = (v: ISelectItemData) => {
    selectValue(v);
    onChange(v);
  };
  const defaultRenderSelectItem = (option: ISelectItemData) => (
    <SelectItem item={option} />
  );

  return (
    <div className={`${className || ""} ${styles.dropDownPart}`}>
      <Dropdown isOpen={isOpen || props.isOpen} toggle={toggleMenu}>
        <DropdownToggle disabled={disabled} className={styles.switch}>
          {(renderSelectToggle || defaultRenderSelectToggle)(value)}
        </DropdownToggle>

        <DropdownMenu className={styles.menuWrapper}>
          {options.map((option, index) => (
            <SelectItemWrapper
              key={index}
              value={option}
              onSelect={handleChange}
            >
              {(renderSelectItem || defaultRenderSelectItem)(option)}
            </SelectItemWrapper>
          ))}
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};
