import advancedSettings from "./advancedSettings";

export default {
  leaderBoard: "leaderBoard",
  reviews: "reviews",
  reviewsResolution: "reviewsResolution",
  reviewsIssues: "reviewsIssues",
  branchesAndCashiers: "branchesAndCashiers",
  brandSettings: "brandSettings",
  dashboardUsers: "dashboardUsers",
  brandListing: "brandListing",
  brandInfo: "brandInfo",
  brandVisuals: "brandVisuals",
  menuItems: "menuItems",
  myProfile: "myProfile",
  settings: "settings",
  giftList: "giftList",
  socialLinks: "socialLinks",
  convertingRatio: "convertingRatio",
  orderHistory: "orderHistory",
  orderingPortal: "orderingPortal",
  support: "support",
  help: "help",
  logout: "logout",
  main: "main",
  myAccount: "myAccount",
  branchesAndMain: "branchesAndMain",
  advancedSettings: "advancedSettings",
  pickup: "pickup",
  pickupBranches: "pickupBranches",
  pickupMenu: "pickupMenu",
  menu: "menu",

  blockedUsers: advancedSettings.blockedUsers,
};
