import React, { useMemo } from "react";
import * as styles from "../CollapseBrandListing/styles.module.sass";
import { Collapse, Button, CardBody, Card } from "reactstrap";
import { NavLink, RouteComponentProps } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { InjectedTranslateProps, translate } from "react-i18next";
import { useToggleState } from "../../../../Utils/custom-hooks/useToggleState";
import { useRtlClass } from "../../../lib";
import NavigationStrings from "../../../i18n/strings/navigation";

interface IProps extends RouteComponentProps, InjectedTranslateProps {
  canAccessBranches: boolean;
  hasFullAccess: boolean;
  is_is_show: boolean;
}

const CollapseBrandSettings: React.FC<IProps> = ({
  t,
  location,
  is_is_show,
  hasFullAccess,
  canAccessBranches,
}) => {
  const isInSettings = useMemo(() => {
    return location.pathname.startsWith("/settings");
  }, [location.pathname]);
  const duration = 10;

  const defaultStyle = {
    transition: `all ${duration}ms ease`,
    opacity: 0,
  };

  const transitionStyles = {
    false: { opacity: 0 },
    true: { opacity: 1 },
  };
  const [collapse, toggle] = useToggleState();
  const isOpen = collapse && is_is_show;

  return (
    <div className={`${styles.wrapper} ${useRtlClass(styles)}`}>
      <NavLink
        to={"/settings/branches_cashiers"}
        className={isInSettings ? styles.btn : styles.btn}
      >
        <Button
          onClick={toggle}
          className={isInSettings ? styles.btnActive : styles.btn}
        >
          <div className={styles.bar}>
            <img
              className={styles.icon}
              src={require("../../../assets/settings.svg")}
            />
            <span
              className={is_is_show ? styles.showNavItem : styles.hideNavItem}
            >
              {t(NavigationStrings.brandSettings)}
            </span>
          </div>
          <span
            className={`${
              is_is_show ? styles.showNavItem : styles.hideNavItem
            } ${isOpen ? styles.arrow : styles.arrowOpen}`}
          />
        </Button>
      </NavLink>
      {/* <NavLink
        to={"/settings/branches_cashiers"}
        className={isInSettings ? styles.btn : styles.btn}
      >
        <Button onClick={toggle} className={isInSettings ? styles.btnActive : styles.btn}>
          <div className={styles.bar}>
            <img className={styles.icon} src={require('../../../assets/settings.svg')}/>
            <span className={is_is_show?styles.showNavItem:styles.hideNavItem}>{t(NavigationStrings.brandSettings)}</span>
          </div>
          <span className={`${is_is_show?styles.showNavItem:styles.hideNavItem} ${isOpen ? styles.arrow : styles.arrowOpen}`}/>
        </Button>
      </NavLink> */}
      <Collapse
        style={{
          ...defaultStyle,
          ...transitionStyles[isOpen ? "true" : "false"],
        }}
        in={isOpen || isInSettings}
        timeout={duration}
        className={styles.body}
        isOpen={isOpen}
      >
        <Card className={styles.card}>
          <CardBody style={{ padding: "1.2rem 0" }}>
            {canAccessBranches && (
              <li className={styles.NavigationItem}>
                <NavLink
                  to="/settings/branches_cashiers"
                  exact={true}
                  activeClassName={styles.active}
                >
                  <span>{t(NavigationStrings.branchesAndCashiers)}</span>
                </NavLink>
              </li>
            )}
            {hasFullAccess && (
              <li className={styles.NavigationItem}>
                <NavLink
                  to="/settings/users"
                  exact={true}
                  activeClassName={styles.active}
                >
                  <span>{t(NavigationStrings.dashboardUsers)}</span>
                </NavLink>
              </li>
            )}
            {hasFullAccess && (
              <li className={styles.NavigationItem}>
                <NavLink
                  to="/settings/advanced"
                  exact={true}
                  activeClassName={styles.active}
                >
                  <span>{t(NavigationStrings.advancedSettings)}</span>
                </NavLink>
              </li>
            )}
          </CardBody>
        </Card>
      </Collapse>
    </div>
  );
};

export default translate("navigation")(withRouter(CollapseBrandSettings));
