import {
  getUserRanking,
  getUserRankingSuccess,
  getUserRankingFailure,
  IGetUserRankingSuccessData,
} from "../actions/user-ranking-actions";
import { Map } from "immutable";
import * as constants from "../../constants";
import { mutateState } from "../../helpers/mutate-state";
import { union } from "ts-action";

const USERS = "USERS";
const LOADING = "LOADING";
const LOADED = "LOADED";
const FAILED = "FAILED";
const PAGE = "PAGE";
const PAGES_COUNT = "PAGES_COUNT";
const USERS_COUNT = "USERS_COUNT";

export interface IUserRankingReducerState {
  [USERS]: any[];
  [PAGE]: number;
  [PAGES_COUNT]: number;
  [USERS_COUNT]: number;
  [LOADING]: boolean;
  [LOADED]: boolean;
  [FAILED]: boolean;
}

const initialState: IUserRankingReducerState = Map({
  [USERS]: [],
  [PAGE]: 0,
  [PAGES_COUNT]: 0,
  [USERS_COUNT]: 0,
  [LOADED]: false,
  [LOADING]: false,
  [FAILED]: false,
}).toJS();

const allActions = union({
  getUserRanking,
  getUserRankingSuccess,
  getUserRankingFailure,
});

type getUserRankingActions = typeof allActions;

export default (
  state = initialState,
  action: getUserRankingActions
): IUserRankingReducerState => {
  switch (action.type) {
    case constants.getUserRankingAction.fulfilled: {
      return mutateState(state, (map: Map<string, any>) => {
        const data = action.payload as IGetUserRankingSuccessData;
        map.set(USERS, data.data.customers);
        map.set(PAGE, data.page);
        map.set(PAGES_COUNT, data.pagesCount);
        map.set(USERS_COUNT, data.data.totalCustomers);
        map.set(LOADING, false);
        map.set(LOADED, true);
        map.set(FAILED, false);
      });
    }
    case constants.getUserRankingAction.requested: {
      return mutateState(state, (map) => {
        map.set(LOADING, true);
        map.set(LOADED, false);
        map.set(FAILED, false);
      });
    }
    case constants.getUserRankingAction.rejected: {
      return mutateState(state, (map) => {
        map.set(LOADING, false);
        map.set(LOADED, false);
        map.set(FAILED, true);
      });
    }
    default:
      return state;
  }
};
