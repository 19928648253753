import { call, put, select, takeLatest } from "redux-saga/effects";
import { selectToken } from "../../../../redux-store/selectors";
import { union } from "ts-action";
import { getStatisticsAction } from "../../constants/actions";
import {
  getStatistics,
  getStatisticsFailure,
  getStatisticsSuccess,
} from "../actions/statistics-actions";
import { getStatisticsAPI } from "../../axios/statisticsAPI";

const actionType = union({ getStatistics });
function* getStatisticsSaga(action: typeof actionType) {
  const { branch_id, end_time, order_type, start_time } = action.payload;
  const token = yield select(selectToken);
  try {
    const res = yield call(
      getStatisticsAPI,
      token,
      start_time,
      end_time,
      branch_id,
      order_type
    );
    yield put(
      getStatisticsSuccess(
        res?.data || { chart_data: [], net_profit: 0, total_orders: 0 }
      )
    );
  } catch (e) {
    yield put(getStatisticsFailure(e));
  }
}

export function* watchGetStatisticsSaga() {
  yield takeLatest(getStatisticsAction.requested, getStatisticsSaga);
}
