import convertingRatio from "./convertingRatio";
import onBoardingSteps from "./onBoardingSteps";
export default {
  title: "الصور والألوان",
  logoLabel: "إضافة شعار المتجر",
  logoDescription:
    "يجب ألا يقل حجم الصورة عن 4 كيلوبايت وألا يزيد عن 1 ميجابايت",
  logoUploadButtonText: "رفع الصورة",
  coverLabel: "إضافة صورة الغلاف",
  coverDescription:
    "يجب ألا يقل حجم صورة الغلاف عن 4 كيلوبايت وألا يزيد عن 1 ميجابايت",
  coverUploadButtonText: "رفع صورة الغلاف",
  unsupportedFileType: "نوع الملف غير مدعوم",
  brandColorLabel: "اختيار لون متجرك",
  brandColorNote:
    "*برجاء العلم أن اللون الذي ستختاره سيظهر لمستخدمي تطبيق Koinz",
  mockUpTitle: "شكل متجرك في التطبيق",
  progressAutoSaved: convertingRatio.progressAutoSaved,
  next: onBoardingSteps.next,
};
