import * as constants from "../../constants";
import { Map } from "immutable";
import { mutateState } from "../../helpers/mutate-state";
import { IGetStoreSuccessAction, IStore } from "../../types";
import {
  constructLoadingReducer,
  ILoadingReducerState,
} from "./constructLoadingReducer";

const STORE = "store";
const EDIT_FAILED = "editFailed";
const SAVED = "saved";
const SAVING = "saving";

export interface IStoreReducerState extends ILoadingReducerState {
  [STORE]: IStore;
  [EDIT_FAILED]: boolean;
  [SAVED]: boolean;
  [SAVING]: boolean;
}

const initialState: IStoreReducerState = Map({
  [STORE]: {},
  [EDIT_FAILED]: false,
}).toJS();

const storeReducer = (
  state: IStoreReducerState,
  action: IGetStoreSuccessAction
): IStoreReducerState => {
  switch (action.type) {
    case constants.getStoreAction.fulfilled: {
      return mutateState(state, (map) => {
        map.set(STORE, (action as IGetStoreSuccessAction).payload);
      });
    }
    case constants.ACTION_RESET_EDIT_FAILED: {
      return mutateState(state, (map) => {
        map.set(EDIT_FAILED, false);
      });
    }
    case constants.editStoreAction.requested: {
      return mutateState(state, (map) => {
        map.set(SAVING, true);
        map.set(SAVED, false);
      });
    }
    case constants.editStoreAction.fulfilled: {
      return mutateState(state, (map) => {
        map.set(SAVING, false);
        map.set(SAVED, true);
      });
    }
    case constants.editStoreAction.rejected: {
      return mutateState(state, (map) => {
        map.set(EDIT_FAILED, true);
        map.set(SAVING, false);
        map.set(SAVED, false);
      });
    }
    default:
      return state;
  }
};

export default constructLoadingReducer(
  constants.getStoreAction,
  storeReducer,
  initialState
);
