import { reducer, on } from "ts-action";
import { withLoadingReducer } from "./withLoadingState";
import {
  getMinValueSuccess,
  setMinValueSuccess,
} from "../actions/min-order-value";
import { getMinOrderValueAction } from "../../constants/actions";
import { IMinOrderValue } from "../../lib/types";

interface IState {
  minOrderValue: IMinOrderValue;
}

export const minOrderValueReducer = withLoadingReducer<IState>(
  reducer<IState>(
    [
      on(getMinValueSuccess, (state, { payload }) => ({
        ...state,
        minOrderValue: payload,
      })),
      on(setMinValueSuccess, (state, { payload }) => ({
        ...state,
        minOrderValue: payload,
      })),
    ],
    {
      minOrderValue: {
        minimum_order_value: undefined,
        minimum_order_value_enabled: 0,
      },
    }
  ),
  getMinOrderValueAction
);
