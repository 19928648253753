import { call, put, select, takeLatest } from "redux-saga/effects";
import { selectToken } from "../../../../redux-store/selectors";
import { editStoreSettingsAction } from "../../constants/actions";
import { editStoreSettings } from "../../axios/editStoreSettings";
import * as actions from "../actions/StoreSettings";
import { union } from "ts-action";
import { editStoreSettingsFailure } from "../actions/StoreSettings";
import moment from "moment";
import memoize from "lodash/memoize";

interface IAmPmTime {
  time: string;
  AmPm: string;
}

export const isValidAmPmTime = memoize((time: string) => {
  time = time.toUpperCase();
  const splitTime = time.split(" ");
  const splitHM = splitTime[0].split(":");
  const hours = splitHM[0];
  const minutes = splitHM[1];
  const AmPm = splitTime[1];
  const hoursValid = Number(hours) <= 12;
  const minutesValid = Number(minutes) <= 59;
  const AmPmValid = AmPm === "AM" || AmPm === "PM";

  return hoursValid && minutesValid && AmPmValid;
});
export const normalizeAmPmTime = (timeData: IAmPmTime) => {
  const { time, AmPm } = timeData;
  const concatTime = `${time}${AmPm ? " " + AmPm : ""}`;
  if (isValidAmPmTime(concatTime)) {
    return moment(`${time} ${AmPm}`, ["h:mm A"]).format("HH:mm");
  }
  return concatTime;
};

export const formatAmPmTime = (time: string = " "): IAmPmTime => {
  const rawTime = moment(time, ["HH:mm"])
    .format("hh:mm A")
    .split(" ");
  return {
    time: rawTime[0],
    AmPm: rawTime[1],
  };
};

const actionType = union({ a: actions.editStoreSettings });

function* editStorSettingsSaga(action: typeof actionType) {
  try {
    const token = yield select(selectToken);
    const res = yield call(editStoreSettings, token, action.payload);
    yield put(actions.editStoreSettingsSuccess(res.data));
  } catch (error) {
    yield put(editStoreSettingsFailure(error));
  }
}

export function* watchedStoreSettingsSaga() {
  yield takeLatest(editStoreSettingsAction.requested, editStorSettingsSaga);
}
