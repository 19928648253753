import React, { useContext, useEffect, useMemo, useState } from "react";
import * as styles from "./styles.module.sass";
import { Collapse, CardBody, Card } from "reactstrap";
import { CustomDropDownSelect } from "../../CustomDropDownSelect";
import { ISelectItemData } from "../../FormElements/RenderDropDownSelect";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import ReviewsFilters from "../../../contexts/ReviewsFilters";
import translate from "react-i18next/src/translate";
import { InjectedTranslateProps } from "react-i18next";
import Strings from "../../../i18n/strings/reviews";
import i18n from "../../../i18n/i18n";
import { IReviewCategoryWithTags } from "../../../axios/getReviewsCategories";
import { ITag } from "../../../axios/getReview";
import { hot } from "react-hot-loader";
import { numberFormatter } from "../../../../Utils/numberFormatter";
import { prefixImage } from "../../Leaderboard/UsersRanking/User";

interface IProps {
  collapse: boolean;
  categories: IReviewCategoryWithTags[];
}

const renderSelectToggle = (item: ISelectItemData) => (
  <div className={styles.dropDownToggle}>
    <span>{item.label}</span>
    <ArrowDropDown />
  </div>
);

// items crop

const CategorySelectItem: React.FC<{
  item: ISelectItemData<IReviewCategoryWithTags>;
}> = ({ item }) => {
  return (
    <div className={styles.dropDownItem}>
      {item.value.image ? <img src={prefixImage(item.value.image)} /> : null}
      <span>{item.label}</span>
      {item.value.reviews_count !== undefined && (
        <p>({numberFormatter(item.value.reviews_count, 0)})</p>
      )}
    </div>
  );
};
const renderSelectItem = (item: ISelectItemData<IReviewCategoryWithTags>) => (
  <CategorySelectItem item={item} />
);

const collectUniqueTags = (tagsCollection: ITag[]) => {
  return Object.values(
    tagsCollection.reduce((acc, next) => {
      const existingTag: ITag | undefined = acc[next.code];
      if (existingTag) {
        return {
          ...acc,
          [next.code]: {
            ...next,
            reviews_count:
              (next.reviews_count || 0) + (existingTag.reviews_count || 0),
          },
        };
      }
      return {
        ...acc,
        [next.code]: next,
      };
    }, {} as { [x: string]: ITag })
  );
};

const useFlattenTagsFromCats = (cats: IReviewCategoryWithTags[]) => {
  const {
    filters: { rate },
  } = useContext(ReviewsFilters);
  return useMemo(() => {
    const flattenedTags = cats
      .reduce((acc, cat) => {
        return [...acc, ...cat.review_tags];
      }, [])
      .filter((tag) => (rate ? tag.rate === rate : tag));
    const goodAndBadTagsRaw = flattenedTags.reduce(
      (acc, next) => {
        const isGood = next.rate > 2;
        if (isGood) {
          return { ...acc, good: [...acc.good, next] };
        } else {
          return { ...acc, bad: [...acc.bad, next] };
        }
      },
      { good: [], bad: [] }
    );
    const good = collectUniqueTags(goodAndBadTagsRaw.good).sort(
      (a, b) => (b.reviews_count || 0) - (a.reviews_count || 0)
    );
    const bad = collectUniqueTags(goodAndBadTagsRaw.bad).sort(
      (a, b) => (b.reviews_count || 0) - (a.reviews_count || 0)
    );
    return { good, bad };
  }, [cats, rate]);
};

export const getLocalizedName = (obj: {
  [x: string]: any;
  EnName: string;
  ArName: string;
}) => {
  const isArabic = i18n.language === "ar";
  return isArabic ? obj.ArName : obj.EnName;
};

interface ITagsProps {
  tags: ITag[];
  title: string;
}

const Tag: React.FC<{ tag: ITag; onClickTag: (tag: ITag) => void }> = ({
  tag,
  onClickTag,
}) => {
  const {
    filters: { tag_code },
  } = useContext(ReviewsFilters);
  const tagStateClass = tag.rate > 2 ? styles.positiveTag : styles.negativeTag;
  const tagActiveClass = tag_code === tag.code ? styles.active : "";
  const handleClick = () => onClickTag(tag);
  return (
    <div
      onClick={handleClick}
      key={tag.code + tag.rate}
      className={`${styles.tag} ${tagActiveClass} ${tagStateClass}`}
    >
      <span className={styles.dot}>•</span>
      <span className={styles.text}>
        {getLocalizedName(tag)} ({numberFormatter(tag.reviews_count || 0, 0)})
      </span>
    </div>
  );
};

const Tags: React.FC<ITagsProps> = ({ tags, title }) => {
  const {
    filters: { tag_code },
    setFilters,
  } = useContext(ReviewsFilters);
  const filterByTag = (tag: ITag) => {
    if (tag_code === tag.code) {
      setFilters({
        page: 0,
        tag_code: undefined,
      });
    } else {
      setFilters({
        page: 0,
        tag_code: tag.code,
      });
    }
  };
  const halves = useMemo(() => splitInHalf(tags), [tags]);
  return (
    <div className={styles.tagsWrapper}>
      <h4 className={styles.tagsTitle}>{title}</h4>
      <div style={{ display: "flex" }}>
        {halves.map((half, index) => {
          return (
            <div key={index} className={styles.tagsCollection}>
              {half.map((tag) => (
                <Tag tag={tag} onClickTag={filterByTag} key={tag.code} />
              ))}
            </div>
          );
        })}
      </div>
    </div>
  );
};

function splitInHalf<ContentType>(arr: ContentType[]) {
  const splitHere = Math.ceil(arr.length / 2);
  const firstHalf = arr.slice(0, splitHere);
  const secondHalf = arr.slice(splitHere);

  return [firstHalf, secondHalf];
}

const CategoriesFilters: React.FunctionComponent<IProps &
  InjectedTranslateProps> = (props) => {
  const { collapse, t, categories } = props;
  const mappedCats = useMemo(
    () => [
      {
        value: {},
        label: t(Strings.allCategoriesFilter),
      },
      ...categories
        .map((cat) => ({
          value: cat,
          label: getLocalizedName(cat),
        }))
        .sort((a, b) => a.value.order - b.value.order),
    ],
    [categories, i18n.language]
  );
  const { setFilters, filters } = useContext(ReviewsFilters);
  const duration = 1000;
  const [selectedCategory, setSelectedCategory] = useState<ISelectItemData>(
    mappedCats[0]
  );
  useEffect(() => {
    setFilters({
      page: 0,
      category_id: selectedCategory.value.id,
      tag_code: undefined,
    });
  }, [selectedCategory]);
  const tagsForSelectedCategory = selectedCategory.value.id
    ? categories.filter((cat) => cat.id === selectedCategory.value.id)
    : categories;
  const tags = useFlattenTagsFromCats(tagsForSelectedCategory);

  const defaultStyle = {
    transition: `all ${duration}ms ease`,
    opacity: 0,
  };
  const transitionStyles = {
    false: { opacity: 0 },
    true: { opacity: 1 },
  };
  return (
    <Collapse
      style={{
        ...defaultStyle,
        ...transitionStyles[collapse ? "true" : "false"],
      }}
      in={collapse}
      timeout={duration}
      className={styles.body}
      isOpen={collapse}
    >
      <Card style={{ background: "#fafafa", border: 0 }}>
        <CardBody style={{ padding: 0 }}>
          <div className={styles.wrapper}>
            <div className={styles.ddPart}>
              <p className={styles.label}>{t(Strings.categoryFilterTitle)}</p>
              <div className={styles.categoriesAndSeparator}>
                <CustomDropDownSelect
                  renderSelectItem={renderSelectItem}
                  renderSelectToggle={renderSelectToggle}
                  options={mappedCats}
                  className={styles.cats}
                  onChange={setSelectedCategory}
                />
                <div className={styles.catsAndTagsSeparator} />
              </div>
            </div>
            <div className={styles.allTagsWrapper}>
              {!!tags.good.length && !filters.has_issue && (
                <Tags tags={tags.good} title={t(Strings.goodTagsTitle)} />
              )}
              {!!tags.bad.length && (
                <Tags tags={tags.bad} title={t(Strings.badTagsTitle)} />
              )}
            </div>
          </div>
        </CardBody>
      </Card>
    </Collapse>
  );
};

export default hot(module)(translate("reviews")(CategoriesFilters));
