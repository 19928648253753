import * as constants from "../../constants";
import { Map } from "immutable";
import { mutateState } from "../../helpers/mutate-state";
import {
  IAddSpecialItemAction,
  IAddSpecialItemFailureAction,
  IAddSpecialItemSuccessAction,
  ICataloguedItems,
  ICUDItemsAction,
  IDeleteSpecialItemAction,
  IDeleteSpecialItemFailureAction,
  IDeleteSpecialItemSuccessAction,
  IEditLoyaltyItemAction,
  IEditLoyaltyItemFailureAction,
  IEditLoyaltyItemSuccessAction,
  IEditSpecialItemAction,
  IEditSpecialItemFailureAction,
  IEditSpecialItemSuccessAction,
  IFilterMenu,
  IGetCategoriesAction,
  IGetCategoriesFailureAction,
  IGetCategoriesSuccessAction,
  IGetGiftListSuccessAction,
  IGetStoreItemsAction,
  IGetStoreItemsFailureAction,
  IGetStoreItemsSuccessAction,
  IMenuFilters,
  IStoreItem,
  OptimisticFailure,
  OptimisticLoading,
  OptimisticSuccess,
} from "../../types/wizard-types";
import {
  constructLoadingReducer,
  ILoadingReducerState,
} from "./constructLoadingReducer";
import { itemsReducer } from "./itemsReducer";

const CATEGORIES = "categories";
const GIFT_LIST = "giftList";
const SPECIAL_ITEMS = "specialItems";
const FILTERS = "filters";
const FILTERED_CATEGORIES = "filteredCategories";
const AVAILABLE_MENU_GIFTS = "AVAILABLE_MENU_GIFTS";

export interface IGiftListReducerState extends ILoadingReducerState {
  [CATEGORIES]: ICataloguedItems[];
  [GIFT_LIST]: IStoreItem[];
  [SPECIAL_ITEMS]: IStoreItem[];
  [FILTERS]: IMenuFilters;
  [FILTERED_CATEGORIES]: ICataloguedItems[];
  [AVAILABLE_MENU_GIFTS]: ICataloguedItems[];
}

const initialState: IGiftListReducerState = Map({
  [CATEGORIES]: [],
  [GIFT_LIST]: [],
  [SPECIAL_ITEMS]: [],
  [FILTERS]: undefined,
  [FILTERED_CATEGORIES]: [],
  [AVAILABLE_MENU_GIFTS]: [],
}).toJS();

type menuActions =
  | IGetCategoriesAction
  | IGetCategoriesSuccessAction
  | IGetCategoriesFailureAction
  | IGetStoreItemsAction
  | IGetStoreItemsSuccessAction
  | IGetStoreItemsFailureAction
  | IEditLoyaltyItemAction
  | IEditLoyaltyItemFailureAction
  | IAddSpecialItemAction
  | IAddSpecialItemFailureAction
  | IAddSpecialItemSuccessAction
  | IDeleteSpecialItemAction
  | IDeleteSpecialItemFailureAction
  | IDeleteSpecialItemSuccessAction
  | IGetGiftListSuccessAction;
export const sortByPrice = (a: IStoreItem, b: IStoreItem) => a.price - b.price;
// const mapItemsToCategories = (payload: ICataloguedItems[], oldCats: ICataloguedItems[]) => {
//     const catsById = payload.reduce((acc, next) => {
//         return {
//             ...acc,
//             [next.id]: next,
//         };
//     }, {});
//     return oldCats.map(c => catsById[c.id] ? catsById[c.id] : c)
//         .sort((a, b) => a.name.localeCompare(b.name));
// };

const sortWithOutFilteringOutBits = (cats: ICataloguedItems[]) => {
  return cats.map((cat) => {
    const sortedItems = cat.items
      ? cat.items.sort((a, b) => a.name.localeCompare(b.name))
      : [];
    return { ...cat, items: sortedItems };
  });
};

const sortCategoriesItems = (cats: ICataloguedItems[]) => {
  return cats
    .map((cat) => {
      const sortedItems = cat.items
        ? cat.items
            .filter((item) => +item.is_in_loyalty_program !== 1)
            .sort((a, b) => a.name.localeCompare(b.name))
        : [];
      return sortedItems.length
        ? { ...cat, items: sortedItems }
        : { ...cat, deleted: true };
    })
    .filter((cat) => !cat.deleted);
};
const getLoyaltyItems = (cats: ICataloguedItems[]) => {
  let giftList: IStoreItem[] = [];
  cats
    .filter((c) => c.items && c.items.length)
    .forEach((c) =>
      c.items.forEach((item) => {
        if (+item.is_in_loyalty_program === 1) {
          giftList = [...giftList, item];
        }
      })
    );
  return giftList.sort(sortByPrice);
};

const filterCatsForGifts = (
  payload: IMenuFilters,
  oldCats: ICataloguedItems[]
) => {
  return oldCats.map((c) => {
    return {
      ...c,
      items: c.items.filter((item) =>
        item.name.toLowerCase().includes(payload.name || "")
      ),
    };
  });
};
const filterCats = (payload: IMenuFilters, oldCats: ICataloguedItems[]) => {
  let newCats: any;
  if (payload.category) {
    newCats = oldCats
      .filter((c) => c.id === (payload.category as any).value)
      .map((c) => {
        const items = c.items.filter(
          (item) => +item.is_in_loyalty_program !== 1
        );
        return items.length ? { ...c, items } : undefined;
      })
      .filter((cat) => cat !== undefined);
  } else {
    newCats = oldCats
      .map((c) => {
        const items = c.items.filter(
          (item) =>
            +item.is_in_loyalty_program !== 1 &&
            item.name.toLowerCase().includes(payload.name || "")
        );
        return items.length ? { ...c, items } : undefined;
      })
      .filter((cat) => cat !== undefined);
  }
  return newCats;
};
const giftListReducer = (
  state: IGiftListReducerState,
  action: menuActions
): IGiftListReducerState => {
  switch (action.type) {
    case constants.getGiftListAction.requested: {
      return mutateState(state, (map) => {
        map.set(CATEGORIES, []);
        map.set(FILTERED_CATEGORIES, []);
        map.set(GIFT_LIST, []);
        map.set(SPECIAL_ITEMS, []);
      });
    }
    case constants.ACTION_FILTER_ADD_FROM_MENU: {
      const { payload } = action as IFilterMenu;
      return mutateState(state, (map) => {
        const oldCats = map.get(CATEGORIES) as ICataloguedItems[];
        map.set(FILTERED_CATEGORIES, filterCats(payload, oldCats));
        map.set(FILTERS, payload);
        map.set(
          AVAILABLE_MENU_GIFTS,
          filterCatsForGifts(payload, sortWithOutFilteringOutBits(oldCats))
        );
      });
    }
    case constants.editLoyaltyItemAction.requested: {
      return mutateState(state, (map) => {
        const { payload } = action as IEditLoyaltyItemAction;
        const oldCat = map.get(CATEGORIES) as ICataloguedItems[];
        const newCats = oldCat.map((c) => ({
          ...c,
          items: c.items.map((item) => {
            if (item.id === payload.id) {
              return { ...item, ...payload, state: OptimisticLoading };
            }
            return item;
          }),
        }));
        const sortedItemsCats = sortCategoriesItems(newCats);
        map.set(CATEGORIES, newCats);
        map.set(GIFT_LIST, getLoyaltyItems(newCats));
        const filters = map.get(FILTERS) as IMenuFilters;
        if (filters) {
          map.set(FILTERED_CATEGORIES, filterCats(filters, sortedItemsCats));
        } else {
          map.set(FILTERED_CATEGORIES, sortedItemsCats);
        }
      });
    }
    case constants.editLoyaltyItemAction.rejected: {
      return mutateState(state, (map) => {
        const { payload } = action as IEditLoyaltyItemFailureAction;
        const oldCat = map.get(CATEGORIES) as ICataloguedItems[];
        const newCats = oldCat.map((c) => ({
          ...c,
          items: c.items.map((item) => {
            if (item.id === payload.id) {
              return { ...payload.fallBack, state: OptimisticFailure };
            }
            return item;
          }),
        }));
        const sortedItemsCats = sortCategoriesItems(newCats);
        map.set(CATEGORIES, newCats);
        map.set(GIFT_LIST, getLoyaltyItems(newCats));
        const filters = map.get(FILTERS) as IMenuFilters;
        if (filters) {
          map.set(FILTERED_CATEGORIES, filterCats(filters, sortedItemsCats));
        } else {
          map.set(FILTERED_CATEGORIES, sortedItemsCats);
        }
      });
    }
    case constants.editLoyaltyItemAction.fulfilled: {
      return mutateState(state, (map) => {
        const { payload } = action as IEditLoyaltyItemSuccessAction;
        const oldCat = map.get(CATEGORIES) as ICataloguedItems[];
        const newCats = oldCat.map((c) => ({
          ...c,
          items: c.items.map((item) => {
            if (item.id === payload.id) {
              return { ...payload, state: OptimisticSuccess };
            }
            return item;
          }),
        }));
        const sortedItemsCats = sortCategoriesItems(newCats);
        map.set(CATEGORIES, newCats);
        map.set(GIFT_LIST, getLoyaltyItems(newCats));
        const filters = map.get(FILTERS) as IMenuFilters;
        if (filters) {
          map.set(FILTERED_CATEGORIES, filterCats(filters, sortedItemsCats));
        } else {
          map.set(FILTERED_CATEGORIES, sortedItemsCats);
        }
      });
    }
    case constants.addSpecialItemAction.requested: {
      return mutateState(state, (map) => {
        const oldItems = map.get(SPECIAL_ITEMS) as IStoreItem[];
        map.set(SPECIAL_ITEMS, [
          ...oldItems,
          (action as IAddSpecialItemAction).payload,
        ]);
      });
    }
    case constants.addSpecialItemAction.fulfilled: {
      return mutateState(state, (map) => {
        const oldItems = map.get(SPECIAL_ITEMS) as IStoreItem[];
        const { payload } = action as IAddSpecialItemSuccessAction;
        const newItems = oldItems.map((a) =>
          a.optimisticId === payload.optimisticId ? { ...a, ...payload } : a
        );
        map.set(SPECIAL_ITEMS, newItems);
      });
    }
    case constants.addSpecialItemAction.rejected: {
      return mutateState(state, (map) => {
        const { payload } = action as IAddSpecialItemFailureAction;
        const oldItems = map.get(SPECIAL_ITEMS) as IStoreItem[];
        const newItems = oldItems.filter(
          (item) => item.optimisticId !== payload.optimisticId
        );
        map.set(SPECIAL_ITEMS, newItems);
      });
    }
    case constants.editSpecialItemAction.requested: {
      return mutateState(state, (map) => {
        const oldItems = map.get(SPECIAL_ITEMS) as IStoreItem[];
        const { payload } = action as IEditSpecialItemAction;
        const newSpecialItems = oldItems.map((item) => {
          if (item.id === payload.id) {
            return {
              ...item,
              ...payload,
              fallBack: item,
              state: OptimisticLoading,
            };
          }
          return item;
        });
        map.set(SPECIAL_ITEMS, newSpecialItems);
      });
    }
    case constants.editSpecialItemAction.fulfilled: {
      return mutateState(state, (map) => {
        const oldItems = map.get(SPECIAL_ITEMS) as IStoreItem[];
        const { payload } = action as IEditSpecialItemSuccessAction;
        const newItems = oldItems.map((item) =>
          item.id === payload.id ? payload : item
        );
        map.set(SPECIAL_ITEMS, newItems);
      });
    }
    case constants.editSpecialItemAction.rejected: {
      return mutateState(state, (map) => {
        const { payload } = action as IEditSpecialItemFailureAction;
        const oldItems = map.get(SPECIAL_ITEMS) as IStoreItem[];
        const newItems = oldItems.map((item) =>
          item.id === payload.id ? payload.fallBack : item
        );
        map.set(SPECIAL_ITEMS, newItems);
      });
    }
    case constants.deleteSpecialItemAction.requested: {
      return mutateState(state, (map) => {
        const { payload } = action as IDeleteSpecialItemAction;
        const oldItems = map.get(SPECIAL_ITEMS) as IStoreItem[];
        const newItems = oldItems.filter((item) => item.id !== payload.id);
        map.set(SPECIAL_ITEMS, newItems);
      });
    }
    case constants.deleteSpecialItemAction.rejected: {
      return mutateState(state, (map) => {
        const { payload } = action as IDeleteSpecialItemFailureAction;
        const oldItems = map.get(SPECIAL_ITEMS) as IStoreItem[];
        map.set(SPECIAL_ITEMS, [...oldItems, payload]);
      });
    }
    case constants.getGiftListAction.fulfilled: {
      return mutateState(state, (map) => {
        const { payload } = action as IGetGiftListSuccessAction;
        const newCats = payload.items;
        const sortedItemsCats = sortCategoriesItems(newCats);
        map.set(CATEGORIES, newCats);
        map.set(GIFT_LIST, getLoyaltyItems(newCats));
        map.set(FILTERED_CATEGORIES, sortedItemsCats);
        map.set(SPECIAL_ITEMS, payload.specialItems);
        map.set(AVAILABLE_MENU_GIFTS, sortWithOutFilteringOutBits(newCats));
      });
    }
    case constants.addStoreItemAction.rejected:
    case constants.addStoreItemAction.requested:
    case constants.addStoreItemAction.fulfilled:
    case constants.editStoreItemAction.rejected:
    case constants.editStoreItemAction.requested:
    case constants.editStoreItemAction.fulfilled:
    case constants.deleteStoreItemAction.rejected:
    case constants.deleteStoreItemAction.requested:
    case constants.deleteStoreItemAction.fulfilled: {
      return mutateState(state, (map) => {
        const oldCats = map.get(CATEGORIES) as ICataloguedItems[];
        const {
          payload: { category_id },
        } = action as ICUDItemsAction;
        const newCats = oldCats.map((c) => {
          if (c.id === category_id) {
            return {
              ...c,
              items: itemsReducer(c.items || [], action as ICUDItemsAction),
            };
          }
          return c;
        });
        const sortedItemsCats = sortCategoriesItems(newCats);
        map.set(CATEGORIES, newCats);
        map.set(GIFT_LIST, getLoyaltyItems(newCats));
        const filters = map.get(FILTERS) as IMenuFilters;
        if (filters) {
          map.set(FILTERED_CATEGORIES, filterCats(filters, sortedItemsCats));
        } else {
          map.set(FILTERED_CATEGORIES, sortedItemsCats);
        }
      });
    }
    default:
      return state;
  }
};

export default constructLoadingReducer(
  constants.getGiftListAction,
  giftListReducer,
  initialState
);
