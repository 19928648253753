import { takeLatest, put, select, call } from "redux-saga/effects";
import { getWebPortalStatisticsAction } from "../../constants/actions";
import { selectToken } from "../../../../redux-store/selectors";
import {
  getWebPortalStatisticsSuccess,
  getWebPortalStatisticsFailure,
  getWebPortalStatistics,
} from "../actions/ordering-portal-link-action";
import { getWebPortalStatisticsApi } from "../../../../axios/getWebPortalStatistics";
import { union } from "ts-action";
const actionTypes = union({ getWebPortalStatistics });
function* getWebPortalStatisticsSaga({
  payload: { filters },
}: typeof actionTypes) {
  try {
    const token = yield select(selectToken);
    const res = yield call(getWebPortalStatisticsApi, token, filters);
    yield put(getWebPortalStatisticsSuccess(res.data));
  } catch (e) {
    yield put(getWebPortalStatisticsFailure(e));
  }
}

export function* watchGetWebPortalStatisticsSaga() {
  yield takeLatest(
    getWebPortalStatisticsAction.requested,
    getWebPortalStatisticsSaga
  );
}
