import { payload, action } from "ts-action";
import * as actions from "../../constants/actions";
import { IBranch } from "../../../../types/shared";
import { IZoneItem } from "../../../WizardComponents/Branches/AddBranchForm/lib";

export const editBranchZones = action(
  actions.editBranchZonesAction.submitting,
  payload<IBranch & { zones: IZoneItem[] }>()
);

export const editBranchZonesClear = action(
  actions.editBranchZonesAction.initial
);
export const editBranchZonesSubmitted = action(
  actions.editBranchZonesAction.submitted
);
export const editBranchZonesFailure = action(
  actions.editBranchZonesAction.failed
);
