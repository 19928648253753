import * as actions from "../../constants";
import {
  IAddCategoryAction,
  IAddCategoryFailureAction,
  IAddCategorySuccessAction,
  ICataloguedItems,
  ICategory,
  IDeleteCategoryAction,
  IDeleteCategoryFailureAction,
  IDeleteCategorySuccessAction,
  IEditCategoryAction,
  IEditCategoryFailureAction,
  IEditCategorySuccessAction,
  IGetCategoriesAction,
  IGetCategoriesFailureAction,
  IGetCategoriesSuccessAction,
} from "../../types/wizard-types";
import { IEditCategory } from "../../axios/editCategory";
import { IOptimisticObject } from "../../types/shared";

export const getCategories = (): IGetCategoriesAction => ({
  type: actions.getCategoriesAction.requested,
});

export const getCategoriesSuccess = (
  categories: ICategory[]
): IGetCategoriesSuccessAction => ({
  type: actions.getCategoriesAction.fulfilled,
  payload: categories,
});

export const getCategoriesFailure = (
  error: Error
): IGetCategoriesFailureAction => ({
  type: actions.getCategoriesAction.rejected,
  payload: error,
});

export const editCategory = (
  categoryToEdit: IEditCategory
): IEditCategoryAction => ({
  type: actions.editCategoryAction.requested,
  payload: categoryToEdit,
});

export const editCategorySuccess = (
  editedCategory: ICategory
): IEditCategorySuccessAction => ({
  type: actions.editCategoryAction.fulfilled,
  payload: editedCategory,
});

export const editCategoryFailure = (
  editedCategory: IEditCategory
): IEditCategoryFailureAction => ({
  type: actions.editCategoryAction.rejected,
  payload: editedCategory,
});

export const addCategory = (
  payload: IOptimisticObject & { name: string; id: string }
): IAddCategoryAction => ({
  type: actions.addCategoryAction.requested,
  payload,
});

export const addCategorySuccess = (
  addedCategory: IOptimisticObject & ICataloguedItems
): IAddCategorySuccessAction => ({
  type: actions.addCategoryAction.fulfilled,
  payload: addedCategory,
});

export const addCategoryFailure = (
  item: IOptimisticObject
): IAddCategoryFailureAction => ({
  type: actions.addCategoryAction.rejected,
  payload: item,
});

export const deleteCategory = (
  payload: ICataloguedItems
): IDeleteCategoryAction => ({
  type: actions.deleteCategoryAction.requested,
  payload,
});

export const deleteCategorySuccess = (): IDeleteCategorySuccessAction => ({
  type: actions.deleteCategoryAction.fulfilled,
});

export const deleteCategoryFailure = (
  payload: ICataloguedItems
): IDeleteCategoryFailureAction => ({
  type: actions.deleteCategoryAction.rejected,
  payload,
});
