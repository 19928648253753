import * as actions from "../../constants";
import {
  IEditStoreAction,
  IEditStoreFailureAction,
  IEditStoreSuccessAction,
  IFormChangedData,
  IGetStoreAction,
  IGetStoreFailureAction,
  IGetStoreSuccessAction,
  IResetEditFailed,
  IStore,
} from "../../types";

export const getStore = (): IGetStoreAction => ({
  type: actions.getStoreAction.requested,
});

export const getStoreSuccess = (store: IStore): IGetStoreSuccessAction => ({
  type: actions.getStoreAction.fulfilled,
  payload: store,
});

export const getStoreFailure = (error: Error): IGetStoreFailureAction => ({
  type: actions.getStoreAction.rejected,
  payload: error,
});

export const editStore = (
  changedData: IFormChangedData[]
): IEditStoreAction => ({
  type: actions.editStoreAction.requested,
  payload: { changedData },
});

export const editStoreSuccess = (): IEditStoreSuccessAction => ({
  type: actions.editStoreAction.fulfilled,
});

export const editStoreFailure = (error: Error): IEditStoreFailureAction => ({
  type: actions.editStoreAction.rejected,
  payload: error,
});

export const resetEditFailed = (): IResetEditFailed => ({
  type: actions.ACTION_RESET_EDIT_FAILED,
});
