import unregister from "./registerServiceWorker";
import * as React from "react";
import * as ReactDOM from "react-dom";
import App from "./App";
import persistedStore from "./redux-store/configureStore";
import { Provider } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.module.sass";
import "./fonts.css";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n/i18n";
import { DragDropContextProvider } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import { imagesURL } from "./Components/Leaderboard/Card";
import * as Mixpanel from "mixpanel-browser";
import ReactGA from "react-ga";
import { RecoilRoot } from "recoil";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
//
import { amplitudeInit } from "./tracking/amplitude";
// Tracking init
// Mixpanel and React Google analytics tracking
Mixpanel.init("ccc03d365c677e8a2ff430d0cf674621");
ReactGA.initialize("UA-149364338-1");
ReactGA.pageview(window.location.pathname + window.location.search);
//
import * as Sentry from "@sentry/browser";
const isProduction = process.env.NODE_ENV === "production";
if (isProduction) {
  Sentry.init({
    dsn: "https://adcf9c3c2b9e4ec3b77edae84062d21f@sentry.io/1769495",
  });
}

export const imgBURL = imagesURL;

unregister();

async function init() {
  const queryClient = new QueryClient();

  const { store } = await persistedStore();
  // Amplitude tracking
  amplitudeInit();
  ReactDOM.render(
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <DragDropContextProvider backend={HTML5Backend}>
        <Provider store={store}>
          <RecoilRoot>
            <I18nextProvider i18n={i18n as any}>
              <App />
            </I18nextProvider>
          </RecoilRoot>
        </Provider>
      </DragDropContextProvider>
    </QueryClientProvider>,
    document.getElementById("root") as HTMLDivElement
  );
}

init();
