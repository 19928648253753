import { reducer, on } from "ts-action";
import { withLoadingReducer } from "./withLoadingState";
import { getStoreInfoAction } from "../../constants/actions";
import { IStoreAppInfo } from "../actions/lib";
import { getStoreInfoSuccess } from "../actions/past-order-actions";

interface IState {
  storeInfo: IStoreAppInfo;
}

const initialState: IState = {
  storeInfo: {
    activation_date: 0,
    active_leaderboard: 0,
    allow_leaderboard: 0,
    allow_reach: 0,
    api_key: "",
    background_color: "",
    converting_ratio: 0,
    country_code: "",
    country_iso_code: "",
    cover_image: "",
    created_at: 0,
    delivery_fees: 0,
    delivery_time: 0,
    end_trial: 0,
    id: "",
    manager_id: "",
    name: "",
    payment_date: 0,
    payment_notification: 0,
    payment_status: 0,
    status: 0,
    store_image: "",
    timezone: "",
    trial_duration: 0,
    updated_at: 0,
    pos: "",
  },
};

export const getStoreInfoReducer = withLoadingReducer(
  reducer<IState>(
    [
      on(getStoreInfoSuccess, (state, { payload }) => ({
        ...state,
        storeInfo: {
          ...state.storeInfo,
          ...payload,
        },
      })),
    ],
    initialState
  ),
  getStoreInfoAction
);
