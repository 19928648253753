import { call, put, select, takeLatest } from "redux-saga/effects";
import { getBranchesAction } from "../../constants";
import { getBranchesFailure, getBranchesSuccess } from "../actions";
import { selectToken } from "../selectors";
import { getBranches } from "../../axios/getBranches";

function* getBranchesSaga(action) {
  try {
    const token = yield select(selectToken);
    const params = action.payload;
    const response = yield call(getBranches, token, params);
    yield put(
      getBranchesSuccess((response.data || []).filter((b) => !b.deleted))
    );
  } catch (e) {
    yield put(getBranchesFailure(e));
  }
}

export function* watchGetBranchesSaga() {
  yield takeLatest(getBranchesAction.requested, getBranchesSaga);
}
