import * as requests from "./requests";
import axios from "./axios";
import { IFilter } from "../redux-store/actions/lib";

export const getPastOrderAPI = (
  token: string,
  page: number,
  per_page: number,
  filters?: IFilter
) => {
  return axios.get(requests.pastOrderAPI, {
    params: { page, per_page, ...filters },
    headers: {
      token,
    },
    baseURL: process.env.PICKUP_ENDPOINT,
  });
};
