import * as React from "react";
import * as styles from "./styles.module.sass";
import { hot } from "react-hot-loader";
import SingleItem from "./SingleItem";
import {
  IPromotion,
  IOrderDetails,
} from "../../../../../../redux-store/actions/lib";
import Strings from "../../../../../../i18n/strings/orderHistory";
import ReactTooltip from "react-tooltip";
import { useTranslation } from "../../../../../../../WizardComponents/Branches/AddBranchForm/AddZones/RenderZonesFieldArray/useTranslation";
interface IOrderDetailsProps {
  orderDetails: IOrderDetails;
  total_invoice?: any;
  currency: string;
  promotion?: IPromotion;
  order_id?: any;
  total_invoices_without_promo_code?: any;
  store_id: string;
  delivery_fees?: number;
}

export enum PaymentMethod {
  online = "online",
  cash = "cash",
}

const OrderInDetails: React.FC<IOrderDetailsProps> = (props) => {
  const {
    orderDetails,
    total_invoice,
    currency,
    promotion,
    total_invoices_without_promo_code,
    delivery_fees,
  } = props;
  const { t } = useTranslation("orderHistory");
  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <span className={styles.title}>{t(Strings.orderDetails)}</span>
      </div>
      <div className={styles.itemsWrapper}>
        {orderDetails.items.map((item) => {
          return (
            <SingleItem
              comment={item.comment}
              key={item.id}
              is_gift={item.is_gift}
              currency={currency}
              itemName={`${item.en_name} - ${item.ar_name}`}
              itemNum={item.quantity}
              itemPrice={item.price}
              modifiers={item.modifiers_group}
              category_ar_name={item.category_ar_name}
              category_en_name={item.category_en_name}
            />
          );
        })}
      </div>
      {orderDetails.comment && (
        <div className={styles.comment}>
          <span className={styles.commentHeader}>{t(Strings.comment)}</span>
          <span className={styles.commentSubHeader}>
            {orderDetails.comment}
          </span>
        </div>
      )}
      <div className={styles.wrap}>
        <div className={styles.payment}>
          <div className={styles.method}>
            <span>{t(Strings.paymentMethod)}</span>
            {orderDetails.payment_method === PaymentMethod.cash ? (
              <p>{t(Strings.cash)}</p>
            ) : (
              <span className={styles.paymentTitle}>{t(Strings.visa)}</span>
            )}
          </div>
          <div className={styles.promotionWrapper}>
            <span className={styles.promotionTitle}>
              {t(Strings.promoCode)}
            </span>
            {promotion === null ? (
              <span className={styles.promo}>{t(Strings.noCodeApplied)}</span>
            ) : (
              <div className={styles.promoCodeWrapper}>
                <span className={styles.promoCode}>
                  {promotion?.promo_code}
                </span>
                <div style={{ margin: "0 .3rem" }} />
                <div
                  className={styles.tooltip}
                  data-tip={true}
                  data-for={`tt${promotion?.id}`}
                >
                  <img
                    className={styles.toolTipIcon}
                    src={require("../../../../../../assets/help.svg")}
                  />
                </div>
                <ReactTooltip
                  id={`tt${promotion?.id}`}
                  place="top"
                  effect="solid"
                >
                  {promotion?.desc}
                </ReactTooltip>
              </div>
            )}
          </div>

          <div className={styles.invoice}>
            <span>{t(Strings.totalInvoice)}</span>
            {promotion === null ? (
              <span className={styles.totalInvoiceTitle}>
                {total_invoice}
                {currency}
              </span>
            ) : (
              <>
                <span className={styles.beforePromo}>
                  {total_invoices_without_promo_code} {currency}
                </span>
                <span className={styles.totalInvoiceTitle}>
                  {total_invoice} {currency}
                </span>
              </>
            )}
          </div>
        </div>
        {delivery_fees ? (
          <div className={styles.deliverFees} style={{ display: "block" }}>
            <span>{t(Strings.deliverFees)}</span>
            <span className={styles.totalInvoiceTitle}>
              {delivery_fees} {currency}
            </span>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default hot(module)(OrderInDetails);
