import Notification from "rc-notification";
import * as React from "react";
import * as styles from "./styles.module.sass";
import i18n from "../../i18n/i18n";

let notification;
Notification.newInstance(
  {
    closeIcon: (
      <div
        className={
          i18n.language === "ar" ? styles.closeWrapperRtl : styles.closeWrapper
        }
      >
        <img
          className={styles.close}
          src={require("../../assets/icon-close.svg")}
        />
      </div>
    ),
  },
  (n) => (notification = n)
);

export interface ICustomNotificationsOptions {
  content: React.ReactElement<any>;
  key?: string;
  closable?: boolean;
  onClose?: () => void;
  duration?: number;
  style?: any;
  maxCount?: number;
  closeIcon?: React.ReactNode;
}

export const notifyCustomContent = (options: ICustomNotificationsOptions) => {
  notification.notice({
    duration: 5,
    style: {
      position: "fixed",
      bottom: "6rem",
      left: "6rem",
      transition: "all 1s",
      opacity: 1,
      display: "flex",
      zIndex: 9999,
    },
    closable: true,
    ...options,
  });
};

const error = (content: React.ReactChildren | string, duration = 3000) => {
  notifyCustomContent({
    duration: duration / 1000,
    content: (
      <div
        className={`${styles.wrongCode} ${
          i18n.language === "ar" ? styles.rtl : ""
        }`}
      >
        <img src={require("../../assets/icon-delete.svg")} />
        <p className={styles.content}>{content}</p>
      </div>
    ),
  });
};

const success = (content: React.ReactChild | string, duration = 3000) => {
  notifyCustomContent({
    duration: duration / 1000,
    content: (
      <div
        className={`${styles.rightCode} ${
          i18n.language === "ar" ? styles.rtl : ""
        }`}
      >
        <img src={require("../../assets/icon-completed.svg")} />
        <p className={styles.content}>{content}</p>
      </div>
    ),
  });
};

const info = (content: React.ReactChild | string, duration = 3000) => {
  notifyCustomContent({
    duration: duration / 1000,
    content: (
      <div
        className={`${styles.rightCode} ${
          i18n.language === "ar" ? styles.rtl : ""
        }`}
      >
        <img src={require("../../assets/icon-info.svg")} />
        <p className={styles.content}>{content}</p>
      </div>
    ),
  });
};

export const customNotify = {
  error,
  success,
  info,
};
