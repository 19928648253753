import { call, put, select, takeLatest } from "redux-saga/effects";
import { selectToken } from "../../../../redux-store/selectors";
import { getDistrictsAction } from "../../constants/actions";
import {
  getDistricts,
  getDistrictsFailure,
  getDistrictsSuccess,
} from "../actions/get-districts-actions";
import { union } from "ts-action";
import { getDistrictsAPI } from "../../axios/getDisricts";
const actionType = union({ getAreas: getDistricts });

function* getAreasSaga(action: typeof actionType) {
  try {
    const token = yield select(selectToken);
    const res = yield call(
      getDistrictsAPI,
      token,
      action.payload.government_code
    );

    yield put(getDistrictsSuccess(res.data));
  } catch (error) {
    yield put(getDistrictsFailure(error));
  }
}

export function* watchGetAreasSaga() {
  yield takeLatest(getDistrictsAction.requested, getAreasSaga);
}
