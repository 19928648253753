import axios from "./axios";
import * as Requests from "./requests";

export const getMinOrderingValue = (token: string) => {
  return axios.get(Requests.getMinOrderingValueAPI, {
    headers: {
      token,
    },
    baseURL: process.env.PICKUP_ENDPOINT,
  });
};
