import { call, put, select, takeLatest } from "redux-saga/effects";
import { selectToken } from "../../../../redux-store/selectors";
import { union } from "ts-action";
import { orderTrackingAction } from "../../constants/actions";
import {
  orderTrackingFailure,
  orderTrackingSuccess,
  orderTracking,
} from "../actions/order-tracking-action";
import { orderTrackingAPI } from "../../axios/orderTrackingAPI";

const actionType = union({ orderTracking });
function* orderTrackingSaga(action: typeof actionType) {
  const token = yield select(selectToken);
  try {
    const res = yield call(orderTrackingAPI, token, action.payload);
    yield put(orderTrackingSuccess(res.data || []));
  } catch (e) {
    yield put(orderTrackingFailure(e));
  }
}

export function* watchOrderTrackingSaga() {
  yield takeLatest(orderTrackingAction.requested, orderTrackingSaga);
}
