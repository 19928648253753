import React, { useState, useContext } from "react";
import { hot } from "react-hot-loader";
import * as styles from "./styles.module.sass";
import { IOrder, OrderStatus } from "../../../../redux-store/actions/lib";
import formatDistance from "date-fns/distance_in_words_to_now";
import Strings from "../../../../i18n/strings/orderHistory";
import { useTranslation } from "../../../../../WizardComponents/Branches/AddBranchForm/AddZones/RenderZonesFieldArray/useTranslation";
import OrderDetails from "./OrderDetails";
import i18n from "../../../../../../i18n/i18n";
import ar from "date-fns/locale/ar";
import { useGetCurrency } from "../PastOrderTable/OrderDetails/index";
import StoreData from "../../../../../../contexts/StoreData";
import { DeliveryMethods } from "../../../../../../Components/Pickup/lib/types";

export enum OrderType {
  pickup = "pickup",
  delivery = "delivery",
  all = "all",
}
interface IProps {
  order: IOrder;
}

const PastOrderTable: React.FC<IProps> = ({ order }) => {
  const { t } = useTranslation("orderHistory");
  const textStatus = {
    [OrderStatus.accepted]: t(Strings.accepted),
    [OrderStatus.got_picked_up]: t(Strings.got_picked_up),
    [OrderStatus.ready_for_pickup]: t(Strings.ready_for_pickup),
    [OrderStatus.received]: t(Strings.received),
    [OrderStatus.rejected]: t(Strings.rejected),
    [OrderStatus.seen]: t(Strings.seen),
    [OrderStatus.sent]: t(Strings.sent),
    [OrderStatus.canceled_by_agent]: t(Strings.canceled_by_agent),
    [OrderStatus.canceled_by_customer]: t(Strings.canceled_by_customer),
    [OrderStatus.canceled_by_cashier]: t(Strings.canceled_by_cashier),
    [OrderStatus.customer_no_show]: t(Strings.customer_no_show),
    [OrderStatus.scheduled]: t(Strings.scheduled),
    [OrderStatus.delivered]: t(Strings.delivered),
    [OrderStatus.out_for_delivery]: t(Strings.out_for_delivery),
    [OrderStatus.canceled_by_courier]: t(Strings.canceled_by_courier),
  };
  const { language } = i18n;
  let date;
  if (language === "ar") {
    date = formatDistance(new Date(order.created_at), {
      addSuffix: true,
      locale: ar,
    });
  } else {
    date = formatDistance(new Date(order.created_at), { addSuffix: true });
  }
  const orderFailed = {
    [OrderStatus.canceled_by_agent]: OrderStatus.canceled_by_agent,
    [OrderStatus.canceled_by_cashier]: OrderStatus.canceled_by_cashier,
    [OrderStatus.canceled_by_customer]: OrderStatus.canceled_by_customer,
    [OrderStatus.rejected]: OrderStatus.rejected,
    [OrderStatus.canceled_by_courier]: OrderStatus.canceled_by_courier,
  };
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { storeData } = useContext(StoreData);
  const currency = useGetCurrency(storeData?.country_iso_code as any);
  return (
    <div key={order.id} className={styles.main}>
      {open && <OrderDetails id={order.id} open={open} onClose={handleClose} />}
      <div className={styles.wrapper} onClick={handleOpen}>
        <span className={styles.time}>{date}</span>
        <div className={styles.orderType}>
          <div
            className={`${
              order.order_type === OrderType.delivery
                ? styles.deliveryOrder
                : styles.pickupOrder
            }`}
          >
            <img
              className={styles.icon}
              src={
                order.order_type === OrderType.delivery
                  ? order.delivery_method ===
                    DeliveryMethods.third_party_delivery
                    ? require("../../../../assets/delivery-koinz-orange.svg")
                    : require("../../../../assets/motorcycle.svg")
                  : require("../../../../assets/pickup.svg")
              }
            />
          </div>
        </div>

        <span className={styles.name}>{order.customer_name}</span>
        <span className={styles.orderCode}>{order.order_code}</span>
        <span className={styles.brandWrapper}>
          <span className={styles.brandName}>{order.store_name}</span>
          <span className={styles.branchName}>{order.branch_name}</span>
        </span>
        <span className={styles.price}>
          {order.total_invoices.toFixed(2)} {currency}
        </span>
        <span
          className={
            orderFailed[order.status] ? styles.orderFailed : styles.orderSucceed
          }
        >
          {textStatus[order.status]}
        </span>
      </div>
    </div>
  );
};

export default hot(module)(PastOrderTable);
