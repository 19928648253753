import { call, select, takeLatest } from "redux-saga/effects";
import * as actions from "../actions/itemActions";
import { deleteOrderingMenuItem } from "../../axios/deleteOrderingMenuItem";
import { selectToken } from "../../../../redux-store/selectors";
import { deleteOrderingMenuItemAction } from "../../constants/actions";
import { handleOptimism } from "./handleOptimism";
import { customNotify } from "../../SharedComponent/Notification";
import { translateMenuError } from "./changeItemCategorySaga";
import menuErrorMessages from "../../i18n/strings/menuErrorMessages";

function* deleteMenuItemsSaga(action) {
  try {
    const token = yield select(selectToken);
    return yield call(deleteOrderingMenuItem, token, action.payload);
  } catch (e) {
    customNotify.error(translateMenuError(menuErrorMessages.deleteItem));
    throw e;
  }
}

export function* watchDeleteMenuItemSaga() {
  yield takeLatest(
    deleteOrderingMenuItemAction.requested,
    handleOptimism(deleteMenuItemsSaga, {
      success: actions.deleteOrderingItemSuccess,
      failure: actions.deleteOrderingItemFailure,
    })
  );
}
