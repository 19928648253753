import * as React from "react";
import * as styles from "./styles.module.sass";
import { hot } from "react-hot-loader";
import {
  ICustomerInfo,
  ICountryForCustomer,
  IGovernment,
  IArea,
  IAddress,
} from "../../../../../../../redux-store/actions/lib";
import CustomerAddress from "./CustomerAddress";
import { useTranslation } from "../../../../../../../../WizardComponents/Branches/AddBranchForm/AddZones/RenderZonesFieldArray/useTranslation";
import Strings from "../../../../../../../i18n/strings/orderHistory";
import { useProcessImageResource } from "../../../../../../BrandSettingsWraper/BrandSettings/ImgUploading/useProcessImageResource";
import { useRtlClass } from "../../../../../../../../../lib";
export enum OrderType {
  pickup = "pickup",
  delivery = "delivery",
}
interface ICustomerInformationProps {
  CustomerInfo: ICustomerInfo;
  country: ICountryForCustomer;
  government: IGovernment;
  address: IAddress;
  area: IArea;
  sub_district_ar_name: string;
  sub_district_en_name: string;
  city_en_name: string;
  district_ar_name: string;
  district_en_name: string;
  orderType: OrderType;
  comment: string;
  customer_country: string;
}
const CustomerInformation: React.FC<ICustomerInformationProps> = (props) => {
  const {
    CustomerInfo,
    area,
    country,
    government,
    address,
    orderType,
    district_ar_name,
    district_en_name,
    sub_district_ar_name,
    sub_district_en_name,
    customer_country,
    comment,
    city_en_name,
  } = props;

  const avatar = React.useMemo(
    () =>
      CustomerInfo.customer.name
        .split(/\s/)
        .reduce((response: any, word: any) => response + word.slice(0, 1), ""),
    [CustomerInfo]
  );
  const { t } = useTranslation("orderHistory");
  const totalOrders = CustomerInfo.total_orders;
  const customerImage = useProcessImageResource(CustomerInfo.customer.image);
  const rtl = useRtlClass(styles);
  return (
    <div className={`${styles.wrapper} ${rtl}`}>
      <div className={styles.customerInformationWrapper}>
        <span className={styles.title}>{t(Strings.userInformation)}</span>
        <div className={styles.content}>
          <div
            className={styles.contentWrapper}
            style={{ alignItems: "center" }}
          >
            <div className={styles.avatarWrapper}>
              {customerImage ? (
                <img className={styles.customerImage} src={customerImage} />
              ) : (
                avatar
              )}
            </div>
            {/* <div className={styles.contactWrapper}>
              <img
                className={styles.phoneCall}
                src={require("../../../../../../../assets/phone-call.svg")}
              />
              <img
                className={styles.email}
                src={require("../../../../../../../assets/email.svg")}
              />
              <img
                className={styles.order}
                src={require("../../../../../../../assets/order.svg")}
              />
            </div> */}
          </div>
          <div style={{ margin: " 0 1rem" }} />
          <div className={styles.contentWrapper}>
            <div className={styles.infoWrapper}>
              <span className={styles.name}>{CustomerInfo.customer.name}</span>
              <span className={styles.address}>
                {area && government && country && (
                  <>
                    {area.EnName} ,{government.EnName}, {country.EnName}
                  </>
                )}
              </span>
            </div>
            <div className={styles.userInfoWrapper}>
              {/* <span className={styles.phone}>
                {CustomerInfo.customer.country_code}{" "}
                {CustomerInfo.customer.phone_number}
              </span>
              <span className={styles.email}>
                {CustomerInfo.customer.email}
              </span>
              <div className={styles.totalWrapper}>
                <span className={styles.totalOrder}>
                  {t(Strings.totalOrders)}
                </span>
                <span className={styles.total}>{totalOrders}</span>
              </div> */}
            </div>
          </div>
        </div>
        <div className={styles.contact}>
          <div className={styles.contactAndInfoWrapper}>
            <img
              className={styles.phoneCall}
              src={require("../../../../../../../assets/phone-call.svg")}
            />
            <div style={{ margin: "0 1.4rem" }} />
            <div className={`${styles.phoneWrapper} ${useRtlClass(styles)}`}>
              <div className={styles.phone}>
                {CustomerInfo.customer.country_code}{" "}
              </div>
              <div style={{ margin: "0 .4rem" }} />
              <span className={styles.phone}>
                {CustomerInfo.customer.phone_number}
              </span>
            </div>
          </div>
          <div className={styles.contactAndInfoWrapper}>
            <img
              className={styles.phoneCall}
              src={require("../../../../../../../assets/email.svg")}
            />
            <div style={{ margin: "0 1.4rem" }} />
            <span className={styles.email}>{CustomerInfo.customer.email}</span>
          </div>
          <div className={styles.contactAndInfoWrapper}>
            <img
              className={styles.phoneCall}
              src={require("../../../../../../../assets/order.svg")}
            />
            <div style={{ margin: "0 1.4rem" }} />
            <div className={styles.totalWrapper}>
              <span className={styles.totalOrder}>
                {t(Strings.totalOrders)}
              </span>
              <span className={styles.total}>{totalOrders}</span>
            </div>
          </div>
        </div>
        <div style={{ margin: "15px 0" }} />
        {orderType === OrderType.delivery && (
          <>
            <div style={{ marginTop: "2.4rem" }} />
            <CustomerAddress
              city_en_name={city_en_name}
              customer_country={customer_country}
              address={address}
              comment={comment}
              district_ar_name={district_ar_name}
              district_en_name={district_en_name}
              sub_district_ar_name={sub_district_ar_name}
              sub_district_en_name={sub_district_en_name}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default hot(module)(CustomerInformation);
