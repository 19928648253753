import { withLoadingReducer } from "../../../../Pickup/redux-store/reducers/withLoadingState";
import { on, reducer } from "ts-action";
import {
  clearBranchDataForEdit,
  getBranchDataForEditAction,
  getBranchDataForEditSuccess,
} from "./actions/branchFormActions";
import { IGetSingleBranch } from "../../../../../types/shared";

interface IState {
  branchData?: IGetSingleBranch;
}

const initialState = { branchData: undefined };

export const getSingleBranchReducer = withLoadingReducer(
  reducer<IState>(
    [
      on(getBranchDataForEditSuccess, (state, { payload }) => ({
        branchData: payload,
      })),
      on(clearBranchDataForEdit, () => initialState),
    ],
    initialState
  ),
  getBranchDataForEditAction
);
