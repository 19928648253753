import * as Requests from "./requests";
import axios from "./axios";
import { ICreateOrderingItem } from "../lib";

export const createOrderingMenuItem = (
  token: string,
  item: ICreateOrderingItem
) => {
  let itemToPost = { ...item };
  itemToPost.custom_configurations
    ? delete itemToPost.custom_configurations
    : null;

  return axios.post<{ id: string }>(
    Requests.ORDERING_MENU_ITEMS_API,
    itemToPost,
    {
      headers: {
        token,
      },
    }
  );
};
