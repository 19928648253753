import { reducer, on } from "ts-action";
import { withLoadingReducer } from "./withLoadingState";
import { verifyZoneCSVAction } from "../../constants/actions";
import { IDataViolation } from "../actions/lib";
import {
  verifyZoneCSVFailure,
  verifyZoneCSVSuccess,
} from "../actions/get-zones-actions";

interface IState {
  data_violations: IDataViolation[];
  submitting: boolean;
}
const initialState: IState = {
  data_violations: [],
  submitting: false,
};

export const verifyZoneCSVReducer = withLoadingReducer<IState>(
  reducer<IState>(
    [
      on(verifyZoneCSVFailure, (state, { payload }) => ({
        ...state,
        data_violations: payload.data_violations,
        submitting: true,
      })),
      on(verifyZoneCSVSuccess, (state) => ({
        ...state,
        data_violations: [],
        submitting: true,
      })),
    ],
    initialState
  ),
  verifyZoneCSVAction
);
