import { reducer, on } from "ts-action";
import { withLoadingReducer } from "./withLoadingState";
import { getOrderDetailsAction } from "../../constants/actions";
import { IOrderDetails } from "../actions/lib";
import { getOrderDetailsSuccess } from "../actions/past-order-actions";
import { OrderType } from "../../SetupPickup/OrderHistory/PastOrder/PastOrderTable";

interface IState {
  orderDetails: IOrderDetails;
}

const initialState: IState = {
  orderDetails: {
    address: {
      apartment: "",
      building: "",
      floor: "",
      id: "",
      more_info: "",
      street: "",
      type: "",
    },
    items: [],
    payment_method: "",
    total_price: 0,
    promotion: {
      created_at: 0,
      customers_file_path: "",
      desc: "",
      discount_value: 0,
      end_time: 0,
      id: "",
      max_discount_value: 0,
      max_usage_times: 0,
      min_threshold: 0,
      promo_code: "",
      start_time: 0,
      type: "",
      updated_at: 0,
      user_id: "",
    },
    total_invoices_without_promo_code: 0,
    order_type: OrderType.pickup,
    district_ar_name: "",
    district_en_name: "",
    sub_district_ar_name: "",
    sub_district_en_name: "",
    comment: "",
  },
};

export const orderDetailsReducer = withLoadingReducer(
  reducer<IState>(
    [
      on(getOrderDetailsSuccess, (state, { payload }) => ({
        ...state,
        orderDetails: payload,
      })),
    ],
    initialState
  ),
  getOrderDetailsAction
);
