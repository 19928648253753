import { optAction, optReaction } from "./lib";
import { submitPickupBranchesAction } from "../../constants/actions";
import { payload } from "ts-action";
import { REVERT, COMMIT } from "redux-optimistic-ui";
import { IToggleOrderingServices } from "../../axios/toggleOrderingServices";

export const submitPickupBranches = optAction(
  submitPickupBranchesAction.requested,
  payload<IToggleOrderingServices>()
);
export const submitPickupBranchesSuccess = optReaction(
  submitPickupBranchesAction.fulfilled,
  payload<IToggleOrderingServices>(),
  COMMIT
);
export const submitPickupBranchesFailure = optReaction(
  submitPickupBranchesAction.rejected,
  payload<IToggleOrderingServices>(),
  REVERT
);
