export default {
  title: "title",
  allowReceiptIdTitle: "allowReceiptIdTitle",
  allowReceiptIdDescription: "allowReceiptIdDescription",
  allowPointsExpirationTitle: "allowPointsExpirationTitle",
  allowPointsExpirationDescription: "allowPointsExpirationDescription",
  allowHideMenuItemsTitle: "allowHideMenuItemsTitle",
  allowHideMenuItemsDescription: "allowHideMenuItemsDescription",
  blockedUsers: "blockedUsers",
  blockedUsersDescription: "blockedUsersDescription",
  customerExpirationPeriodTitle: "customerExpirationPeriodTitle",
  days: "days",
  months: "months",
  years: "years",
};
