export const EVENTS_FOR_TRACKING = {
  "Viewed reviews": "Viewed reviews",
  "Done a review filter": "Done a review filter",
  "Viewed issues": "Viewed issues",
  "Wrote an issue note": "Wrote an issue note",
  "Changed an issue status": "Changed an issue status",
  "Sent a compensation": "Sent a compensation",
  "Filters by solved issues": "Filters by solved issues",
  "Viewed a solved issue": "Viewed a solved issue",
  "Added a branch": "Added a branch",
  "Visited the branch and cashier screen":
    "Visited the branch and cashier screen",
  "Viewed a cashier or branch": "Viewed a cashier or branch",
  "Edited a cashier or a branch": "Edited a cashier or a branch",
  "Adding a branch or a cashier": "Adding a branch or a cashier",
  "Deleted a branch or a cashier": "Deleted a branch or a cashier",
  "Added a menu item": "Added a menu item",
  "Edited a menu item": "Edited a menu item",
  "Viewed the advanced settings": "Viewed the advanced settings",
  "Changed something in the advanced settings":
    "Changed something in the advanced settings",
  "Added a gift list item": "Added a gift list item",
  "Changed the conversion rate": "Changed the conversion rate",
  "Viewed the brand visuals": "Viewed the brand visuals",
  "Updated the brand visuals": "Updated the brand visuals",
  "Viewed dashboard users": "Viewed dashboard users",
  "Updated dashboard users": "Updated dashboard users",
  "Added a dashboard user": "Added a dashboard user",
  "Created a leaderboard": "Created a leaderboard",
  "Completed registration": "Completed registration",
  "Viewed my profile": "Viewed my profile",
  "Updated my profile": "Updated my profile",
  "Viewed leaderboard": "Viewed leaderboard",
  "Logged out": "Logged out",
  "Logged In": "Logged In",
  "Viewed an old leaderboard": "Viewed an old leaderboard",
};
