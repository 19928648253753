export default {
  createMenu: "Create Menu",
  cancel: "Cancel",
  vat: "Default Tax",
  menuName: "Menu Name",
  menuPlaceholder: "Ex. Main menu",
  createNewMenu: "Create New Menu",
  noMenuText1: "Create your ordering menu",
  syncOrderingMenu: "Sync Ordering Menu",
  noMenuText2:
    "In this section you can add a menu for your available-for-pickup items' info",
  mainMenu: "Main Menu",
  title: "Save modifier settings",
  yourRestaurantURL: "Your restaurant URL in English",
  yourRestaurantURLInArabic: "Your restaurant URL in Arabic",
  subTitle:
    "Do you want to save “{{data.en_name}} - {{data.ar_name}}” settings",
  alert:
    "When you save settings you can add this modifier group easily to any other item in the main menu",
  saveGroup: "Save group",
  noThanks: "No, Thanks",
  syncOrderingMenuSuccess: "Syncing...",
  syncOrderingMenuFailure: "a sync process is already running",
  uploadMenuExternally: "Upload menu externally",
  uploadMenuByExcel: "Upload menu by excel",
  uploadMenuByElMenus: "Upload menu using Elmenus",
  uploadMenuByHungerstation: "Upload menu using Hungerstation",
  firstStep: "First step:",
  secondStep: "Second step:",
  firstStepContent:
    "Download the Excel menu template below and add your menu items to it (in case of zone just change menu to zone)",
  secondStepContent: "Upload the Excel sheet that includes your menu",
  downloadMenuTemplate: "Download menu template",
  firstStepInElmenus: "First step",
  firstContentInHungerStation:
    "Find your brand on Hungerstation, and copy the URL from your browser's address bar",
  secondContentInHungerStation: "secondContentInHungerStation",
  secondStepInElmenus: "Second step",
  firstContentInElmenus:
    "Find your brand on Elmenus, and copy the URL from your browser's address bar",
  secondContentInElmenus: "Paste in your restaurant URL",
  addMenu: "Add Menu",
  enterYourURLonElmenus: "Paste in your restaurant URL",
  loadingTitle: "Your menu is being added",
  warning: "Please wait and don’t close your browser",
  grabbingYouData: "Grabbing your data...",
  menuAddedSuccessfully: "Menu added successfully",
  zoneAddedSuccessfully: "Zones added successfully",
  anErrorHappened: "An error happened please try to contact us",
  addingMenuUsingElMenus: "Adding menu using Elmenus",
  addingMenuUsingHungerstation: "Adding menu using Hungerstation",
  verifyingZones: "Verifying zones",
  addingZones: "Adding zones",
  error: "Could not connect to the server please try again in a few minutes",
  branches: "Branches:",
  searchForBranches: "Search for branches...",
  allBranches: "All Branches",
};
