import { call, select, takeLatest } from "redux-saga/effects";
import { selectToken } from "../../../../redux-store/selectors";
import { reOrderCategoryAction } from "../../constants/actions";
import * as actions from "../actions/categoryActions";
import { union } from "ts-action";
import { editOrderingMenuCategory } from "../../axios/editCategory";
import { handleOptimism } from "./handleOptimism";
import { customNotify } from "../../SharedComponent/Notification";
import { translateMenuError } from "./changeItemCategorySaga";
import menuErrorMessages from "../../i18n/strings/menuErrorMessages";

const actionType = union({ a: actions.reOrderCategory });

function* reOrderCategorySaga(action: typeof actionType) {
  try {
    const token = yield select(selectToken);
    const res = yield call(editOrderingMenuCategory, token, action.payload);
    return res.data;
  } catch (e) {
    customNotify.error(translateMenuError(menuErrorMessages.reorderCategory));
    throw e;
  }
}

export function* watchReOrderCategorySaga() {
  yield takeLatest(
    reOrderCategoryAction.requested,
    handleOptimism(reOrderCategorySaga, {
      failure: actions.reOrderCategoryFailure,
      success: actions.reOrderCategorySuccess,
    })
  );
}
