import advancedSettings from "./advancedSettings";

export default {
  branchesAndCashiers: "فروع وكاشيرات",
  brandSettings: "اعدادات المتجر",
  dashboardUsers: "مستخدمي لوحة المستخدمين",
  leaderBoard: "المسابقة",
  reviews: "التقييمات",
  reviewsResolution: "حل الشكوى",
  reviewsIssues: "الشكاوى",
  orderingPortal: "الطلبات من خلال الويب",
  brandListing: "معلومات المتجر",
  brandInfo: "الاسم ووصف المتجر",
  brandVisuals: "الصور والألوان",
  menuItems: "قائمة المنتجات",
  giftList: "قائمة الهدايا",
  pickup: "الطلب",
  pickupBranches: "فروع Ordering",
  pickupMenu: "قائمة المنتجات",
  orderHistory: "سجل الطلبات",
  socialLinks: "التواصل الاجتماعي",
  convertingRatio: "معدل التحويل",
  support: "الدعم",
  help: "المساعدة",
  logout: "تسجيل الخروج",
  main: "الرئيسية",
  myAccount: "حسابى",
  branchesAndMain: "الفروع الاساسية",
  settings: "الإعدادات",
  myProfile: "حسابي",
  advancedSettings: "اعدادات متقدمة",
  blockedUsers: advancedSettings.blockedUsers,
  menu: "القائمة",
};
