export default {
  createMenu: "إنشاء القائمة",
  cancel: "الغاء",
  vat: "الضريبة",
  menuName: "اسم القائمة",
  menuPlaceholder: "مثال: القائمة الرئيسية",
  createNewMenu: "إنشاء قائمة جديدة",
  noMenuText1: "اضافه قائمة جديده",
  noMenuText2:
    "قم بالضغط على زر اضافه قائمة جديدة لانشاء واحده والبدأ بتفعيل خاصية البيكب ",
  mainMenu: "القائمة الرئيسية",
  title: "حفظ الإعدادات التعديل",
  subTitle: " هل تريد حفظ إعدادات “{{data.en_name}} - {{data.ar_name}}”",
  alert:
    "عند حفظ الإعدادات ، يمكنك إضافة مجموعة التعديل هذه بسهولة إلى أي صنف آخر في القائمة الرئيسية",
  saveGroup: "حفظ المجموعة",
  noThanks: "لا، شكرا",
  syncOrderingMenu: "مزامنة قائمة الطعام",
  syncOrderingMenuSuccess: "جاري المزامنة...",
  syncOrderingMenuFailure: "عملية المزامنة قيد التشغيل بالفعل",
  uploadMenuExternally: "اضف قائمة الطعام بوسيلة خارجية",
  uploadMenuByExcel: "اضف قائمه الطعام عن طريق الاكسيل",
  uploadMenuByElMenus: "اضف قائمه الطعام عن طريق المنيوز",
  uploadMenuByHungerstation: "اضف قائمه الطعام عن طريق هنقرستيشن",
  firstStep: "الخطوة الاولي:",
  secondStep: "الخطوة الثانية",
  firstStepContent: "قم بتنزيل قالب قائمة الطعام، ثم قم بإضافة قائمة الطعام به",
  secondStepContent: " قم برفع ملف Execl الذي يحتوي على قائمة طعامك ",
  downloadMenuTemplate: "تنزيل قالب قائمة الطعام",
  firstStepInElmenus: "الخطوة الاولي",
  firstContentInHungerStation: "انسخ رابط متجرك من هنقرستيشن",
  secondStepInElmenus: "الخطوة الثانية",
  firstContentInElmenus: "انسخ رابط متجرك من المنيوز",
  secondContentInElmenus: "الصق رابط متجرك",
  addMenu: "اضف قائمة الطعام",
  enterYourURLonElmenus: "الصق رابط متجرك",
  yourRestaurantURL: "رابط متجرك باللغة الانجيلزية",
  yourRestaurantURLInArabic: "رابط متجرك",
  loadingTitle: "جاري اضافة قائمة الطعام",
  warning: "من فضلك لا تقوم بالإغلاق المتصفح",
  grabbingYouData: "قراءة البيانات...",
  menuAddedSuccessfully: "تم اضافه قائمه الطعام بنجاح",
  zoneAddedSuccessfully: "تم اضافة المناطق بنجاح",
  anErrorHappened: "حدث خطأ اثناء اضافة القائمة",
  addingMenuUsingElMenus: "اضافة قائمة الطعام عن طريق المنيوز",
  addingMenuUsingHungerstation: "اضافة قائمة الطعام عن طريق هنقرستيشن",
  verifyingZones: "جاري التحقق من مناطق التوصيل",
  addingZones: "جاري اضافة مناطق التوصيل",
  error: "عذرا ، تعذر علينا الوصول إلى الخادم الآن يرجى المحاولة مره أخرى",
  branches: "فروع:",
  searchForBranches: "ابحث عن فروع...",
  allBranches: "كل الفروع",
};
