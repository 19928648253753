import { reducer, on } from "ts-action";
import {
  withLoadingReducer,
  ILoadingState,
  LoadingStatus,
} from "./withLoadingState";
import { getSuggestedModifiersGroupsAction } from "../../constants/actions";
import {
  getSuggestedModifiersGroupsSuccess,
  createOrderingSuggestedModifiersGroup,
  createOrderingSuggestedModifiersGroupSuccess,
  deleteOrderingSuggestedModifiersGroup,
  editOrderingSuggestedModifiersGroup,
  editOrderingSuggestedModifiersGroupSuccess,
  deleteOrderingSuggestedModifiersGroupSuccess,
} from "../actions/modifierGroupsAction";
import { IModifiersGroup } from "../../lib";
import { optimistic, OptimisticState } from "redux-optimistic-ui";
import { Reducer } from "redux";
import { clearPrevState } from "../../../../redux-store/actions";

interface IState {
  modifierGroup: string[];
  loading: LoadingStatus;
  modifierGroupById: { [x: string]: IModifiersGroup };
}
const initialState: IState = {
  modifierGroup: [],
  modifierGroupById: {},
  loading: LoadingStatus.loading,
};

export const suggestedModifierGroupsReducer = optimistic(
  withLoadingReducer(
    reducer<IState>(
      [
        on(clearPrevState, (state, {}) => ({
          ...state,
          loading: LoadingStatus.loading,
        })),
        on(getSuggestedModifiersGroupsSuccess, (state, { payload }) => ({
          ...state,
          modifierGroup: payload.map((m) => m.id),
          modifierGroupById: payload.reduce(
            (acc, modifier) => ({
              ...acc,
              [modifier.id]: modifier,
            }),
            {}
          ),
        })),
        on(
          createOrderingSuggestedModifiersGroup,
          (
            state,
            {
              payload,
              meta: {
                optimistic: { id },
              },
            }
          ) => {
            return {
              ...state,
              modifierGroup: [...state.modifierGroup, id.toString()],
              modifierGroupById: {
                ...state.modifierGroupById,
                [id]: payload,
              },
              loading: LoadingStatus.loading,
            };
          }
        ),
        on(
          createOrderingSuggestedModifiersGroupSuccess,
          (state, { payload, meta: { optimistic: opt } }) => {
            return {
              ...state,
              modifierGroup: state.modifierGroup.map((id) =>
                id === String(opt.id) ? payload.id : id
              ),
              modifierGroupById: {
                ...state.modifierGroupById,
                [payload.id]: payload,
              },
              loading: LoadingStatus.success,
            };
          }
        ),
        on(deleteOrderingSuggestedModifiersGroupSuccess, (state, {}) => ({
          ...state,
          loading: LoadingStatus.success,
        })),
        on(deleteOrderingSuggestedModifiersGroup, (state, { payload }) => {
          return {
            ...state,
            modifierGroup: state.modifierGroup.filter((mod) => mod !== payload),
          };
        }),
        on(editOrderingSuggestedModifiersGroupSuccess, (state, {}) => ({
          ...state,
          loading: LoadingStatus.success,
        })),
        on(editOrderingSuggestedModifiersGroup, (state, { payload }) => {
          return {
            ...state,
            modifierGroupById: {
              ...state.modifierGroupById,
              [payload.id]: payload,
            },
            loading: LoadingStatus.loading,
          };
        }),
      ],
      initialState
    ),
    getSuggestedModifiersGroupsAction
  )
) as Reducer<OptimisticState<IState & ILoadingState>>;
