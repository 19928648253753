import * as React from "react";
import * as styles from "./styles.module.sass";
import {
  notifyCustomContent,
  closeAllNotices,
} from "../../../../Pickup/SharedComponent/Notification";

interface IProps {
  type: "success" | "error" | "loading";
  text: string;
}
const CustomNotify: React.FC<IProps> = (props) => {
  const { text, type } = props;
  return <div className={`${styles.wrapper} ${styles[type]}`}>{text}</div>;
};

const notifyZones = (content: string, type: IProps["type"], duration = 120) => {
  notifyCustomContent({
    duration,
    maxCount: 1,

    content: <CustomNotify text={content} type={type} />,
  });
};

export { notifyZones, closeAllNotices };
