export default {
  title: "إضافة منتح جديد",
  english_itemName: "اسم المنتح باللغة الانجليزية",
  arabic_itemName: "اسم المنتح",
  description: "وصف المنتح",
  en_description: "وصف المنتج باللغة الانجليزية",
  ar_description: "وصف المنتج",
  description_placeholder: "وصف المكونات وطرق التحضير ، إلخ.",
  branchesSearchPlaceholder: "ابحث عن فروع...",
  add_description: "اطافة وصف باللغة العربية",
  unsupportedText: "ملف غير مدعوم...",
  image_label: "صورة المنتح ",
  upload_photo: "حمل الصورة",
  image_description: "يجب أن تكون أبعاد الصورة 1200px * 312px",
  price: "السعر",
  chooseABranch: "اختر فرع ...",
  noBranchesFound: "لا يوجد فروع ...",
  all: "الكل",
  selectAll: "تحديد كل الفروع",
  unSelectAll: "الغاء تحديد كل الفروع",
  branchesSelected: "تم اختيارهم",
  vat: "الضريبة",
  addModifier: "اضافة جديدة",
  itemsAvailableIn: "هذا المنتج متوفر في:",
  selectBranch: "اختر فرع",
  modifier_description: "برجاء اضافة وصف للاضافات",
  noSavedModifier: "لا يوجد اي اضافات محفوظة",
  selectModifier: "اختيار الاضافة",
  add: "اضافة",
  addNewModifierGroup: "اضافة مجموعة اضافات",
  addNewItem: "اضافة منتح جديد",
  priceAfterVAT: "السعر بعد الضريبة:",
  editItem: "تعديل المنتح",
  optional: "(اختياري)",
  item: "المنتح",
  additionalPrice: "سعر اضافي",
  addModifierGroup: "اضافة مجموعة اضافات",
  editModifierGroup: "تعديل مجموعة الاضافات",
  modifierName: "اسم الاضافة باللغة الانجليزية",
  modifier_placeholder: "نوع السلطة",
  modifierNameInArabic: "اسم الاضافة",
  minimumItems: "بحد أدنى",
  maximumItems: "بحد أقصى",
  isThisRequired: "هل هذا مطلوب؟",
  required: "مطلوب",
  modifier_optional: "اختياري",
  saveModifier: "حفظ الاضافة في قائمة الاضافات المفضلة",
  saveModifierDescription: "يمكنك إضافة هذا المعدل إلى منتح آخر بسرعة",
  cancel: "الغاء",
  submit: "تأكيد",
  items: "منتجات",
  name: "اسم",
  arName: "اسم باللغة العربية",
  addAnotherItem: "اضافة منتح اخر",
  vatText: "السعر بعد الضريبة :",
};
