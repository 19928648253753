import * as React from "react";
interface IProps {
  states: boolean;
  orderFailed: string;
}
export const Icon: React.FC<IProps> = (props) => {
  const { states, orderFailed } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g fill="none" fill-rule="evenodd">
        <rect
          width="24"
          height="24"
          fill={orderFailed ? "#db3030" : states ? "#38C172" : "#41126C"}
          rx="12"
        />
        <path
          fill="#FFF"
          d="M15.377 8.293a.918.918 0 0 1 1.345 0c.37.39.37 1.024 0 1.414l-5.703 6a.918.918 0 0 1-1.344 0l-2.397-2.521a1.037 1.037 0 0 1 0-1.414.918.918 0 0 1 1.345 0l1.724 1.814 5.03-5.293z"
        />
      </g>
    </svg>
  );
};
