export default {
  allTime: "Overall",
  today: "Today",
  thisWeek: "This Week",
  thisMonth: "This Month",
  allBranches: "All Main",
  searchReviewsPlaceholder: "Search for review...",
  issue: "Issue",
  solved: "Solved",
  unsolved: "Not Solved",
  resolved: "Solved",
  reopened: "Reopened",
  noResponse: "No Response",
  reviewTabTitle: "Reviews",
  reviewsStatisticsTabTitle: "Statistics",
  reviewsStatisticsSubTitle: "Koinz is currently analyzing your data",
  reviewsStatisticsContent: `We are collecting and analyzing your data, we will notify you once it's ready!`,
  noIssuesCardTitle: "All is good 👌",
  noIssuesCardSubTitle: "All your customers are happy now",
  issuesCardTitle: "UNHAPPY CUSTOMERS",
  issuesCardSubTitle: "Fix issues",
  compensationQuestion: "Are you sure you want to send",
  to: "to",
  asACompensation: "as a compensation",
  points: "{{count}} Point",
  points_plural: "{{count}} Points",
  cancelCompensation: "Cancel",
  sendCompensation: "Send Points",
  emoji1: "Terrible",
  emoji2: "Bad",
  emoji3: "Good",
  emoji4: "Great",
  emoji5: "Lovely",
  overallRatingTitle: "OVERALL RATING",
  visitsCount: "{{count}} visit",
  visitsCount_plural: "{{count}} visits",
  expandReview: " Read more ",
  collapseReview: " Read less ",
  solveIssue: "Solve",
  viewIssueDetails: "View Details",
  viewProfile: "View Profile",
  nextIssue: "Next Issue",
  previousIssue: "Previous Issue",
  nextIssueArrow: "arrow_forward",
  previousIssueArrow: "arrow_back",
  issuesRemaining: "{{count}} issue remaining",
  issuesRemaining_plural: "{{count}} issues remaining",
  userCaringPart1: "Contact",
  userCaringPart2: "Customer",
  callCustomerPart1:
    "Call {{name}}, to know more about their problem, and list what happened below",
  callCustomerPart2: "",
  customerResponseQuestion: "Did the customer respond?",
  yes: "Yes",
  no: "No",
  writeNoteTitle: "Write your note",
  writeNotePlaceholder: "How did you solve the customer's issue?",
  writeNoteSubmit: "Add note",
  markResolved: "Mark as Solved",
  notesTitle: "Latest Notes",
  compensationsTitle: "Compensations",
  userCompensationPart1: "Compensate",
  userCompensationPart2: "The User",
  optional: "optional",
  userCompensationSubtitle: "🎁 Want to send to {{customerName}} free points?",
  nextGiftTitle: "User next gift to unlock",
  giftPrice: "Points amount",
  openCompensationModal: "Send Points",
  endOfReviews: "No Reviews.",
  noteDateTitle: "Noted on",
  filteredReviewsCount: "Filtered Reviews",
  filteredIssuesCount: "Filtered Issues",
  unFilteredReviewsCount: "Reviews",
  unFilteredIssuesCount: "Issues",
  allReviewsTabTitle: "All Reviews",
  categoryFilterTitle: "Main Category",
  successCompensationAlertPriceToPoints: "{{price}} ~ {{points}} points",
  successCompensationAlertWasSentTo: "was sent to",
  pointsBefore: "pts. before",
  pointsAfter: "pts. after",
  compensationWasSent: "was sent",
  markNoResponse: "Mark as No Response",
  noteArrow: "arrow_right",
  markUnResolved: "Mark as Unresolved",
  showTagsFilters: "Show tags filter",
  hideTagsFilters: "Hide tags filter",
  badTagsTitle: "Bad",
  goodTagsTitle: "Good",
  allCategoriesFilter: "All Tags",
  solveIssueFeedbackAlertCustomerName: "{{customer}}'s",
  solveIssueFeedbackAlertMessage: "Issue has been solved",
  emptyNotesTitle: "No notes added yet",
  emptyNotesAddNote: "Add note",
  emptyCompensations: "No compensations sent yet",
  noteAddedFeedbackAlertMessage: "Note added successfully",
  markUnResolvedSuccessAlert: "Issue was changed to unsolved",
  markNoResponseSuccessAlert:
    "Saved your changes, come back later to check on {{customer}} again",
  allIssues: "All Issues",
  customerCurrentPoints: "User current points",
  errorCompensationBlockedUser:
    "The customer you are trying to compensate has been blocked from the store",
  issueStatusTitle: "Issue Status:",
  compensationsCount: "{{count}} Compensation received",
  notesCount: "{{count}} Note added",
  compensationsCount_plural: "{{count}} Compensations received",
  notesCount_plural: "{{count}} notes added",
  customerReceivedAllTimePart1: "This customer received",
  customerReceivedAllTimePart2: "{{count}} gifts",
  customerReceivedAllTimePart3: "All time",
  customerReceivedAllTimePart4: "on past issues",
  userActivityNote: "noted",
  userActivityCompensate: "compensated the customer by ",
  userActivityChangeStatus: "marked issue as",
  compensationPanelTitle: "Send Compensation",
  compensationPanelSubTitlePart1: "You can choose from sending points,",
  compensationPanelSubTitlePart2: "menu item or a gift.",
  recentCompensationsTitle: "Recent Compensations",
  sendShopXPointsCompensationTitle: "Send Koinz points",
  sendShopXPointsCompensationSubTitlePart1: "Send a fixed amount of",
  sendShopXPointsCompensationSubTitlePart2: "points to your customers",
  sendCustomGiftCompensationTitle: "Send custom gift",
  sendCustomGiftCompensationSubTitlePart1: "Send a menu item, discount",
  sendCustomGiftCompensationSubTitlePart2: "or a voucher",
  sendSelectedMenuItemButtonText: "Send Selected Gift",
  itemIsSelected: "Selected!",
  pointsCompensationPlaceholder: "Enter amount of points",
  pointsCompensationValue: "This points amount = ",
  failedToLoadActivities: "Failed to load activities, please try again.",
  emptyActivityLog:
    "This issue has no activities yet. Contact the customer and add your notes.",
  activityLogTitle: "Latest Activities",
  // Gift Popup
  selectedGiftTitle: "Selected gift",
  messageInArabic: "Message in Arabic",
  messageInEnglish: "Message in English",
  messageTitle: "Write Gift Message",
  messageSubTitle:
    "*This message will be sent to the customer in Koinz app as a gift letter.",

  compensationOptionsTitle: "New Compensation",

  somethingWentWrong: "Something went wrong, please try again.",

  customGiftImageTitle: "Item image",
  customGiftNameTitle: "Item name",

  sendGiftButtonTitle: "Send Gift",

  sendVoucherTabTitle: "Voucher or Discount",
  sendMenuItemTabTitle: "Menu item",
  voucherNamePlaceholder: "Ex. 50% off on meal...",
  next: "Next",

  sendMessage: "Send Message",
  messagePlaceholder: "Your message goes here",

  expandActivities: "See more activities ({{count}})",

  messageField: "Message field",

  compensationSuccessModalTitle: "Compensation was sent successfully!",
  compensationSuccessModalSubTitle:
    "The compensation will appear to your customer in their gift inbox.",
  okay: "Okay",
};
