import * as requests from "./requests";
import axios from "./axios";

export const getStatisticsAPI = (
  token: string,
  start_time?: number,
  end_time?: number,
  branch_id?: string,
  order_type?: string
) => {
  return axios.get(requests.statisticsURL, {
    params: { start_time, end_time, branch_id, order_type },
    headers: {
      token,
    },
    baseURL: process.env.PICKUP_ENDPOINT,
  });
};
