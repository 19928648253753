import { BounceLoader, ReactSpinners } from "react-spinners";
import React from "react";
import * as styles from "./styles.module.sass";

export const LoadingPageInfo = () => {
  return (
    <div className={styles.loadingWrapper}>
      <DefaultLoader />
    </div>
  );
};

export const DefaultLoader = (props: ReactSpinners.BounceLoaderProps) => (
  <BounceLoader
    className={styles.override}
    sizeUnit={"px"}
    size={70}
    color={"#FEBE19"}
    {...props}
  />
);
