import { getBranchWorkingHoursAction } from "../../../../constants/actions";
import { IStoreWorkingHours } from "../../../../../..//types/Manager";
import { on, reducer } from "ts-action";
import { withLoadingReducer } from "../../withLoadingState";
import {
  getBranchWorkingHoursSuccess,
  clearBranchesWorkingHours,
} from "../../../actions";

interface IState {
  [branch_id: string]: IStoreWorkingHours;
}
const initialValue: IState = {};

export const branchesWorkingHoursReducer = withLoadingReducer<IState>(
  reducer<IState>(
    [
      on(getBranchWorkingHoursSuccess, (state, { payload }) => ({
        ...state,
        ...payload,
      })),
      on(clearBranchesWorkingHours, (state) => ({})),
    ],
    initialValue
  ),
  getBranchWorkingHoursAction
);
