import React, { useContext } from "react";
import "../../Reviews/Main/style.css";
import CollapseBrandListing from "../CollapseBrandListing";
import CollapseBrandSettings from "../CollapseBrandSettings";
import NavigationStrings from "../../../i18n/strings/navigation";
import { translate } from "react-i18next";
import classes from "./NavigationItems.module.sass";
import NavigationItem from "./NavigationItem/NavigationItem";
import { hot } from "react-hot-loader";
import { CriticalReviewsNumber } from "../../../lib";
// import StoreData from "../../../contexts/StoreData";
// import {
//   IUser,
//   UserTypes
// } from "../../BrandSettings/DashboardUsers/users-hooks";
import { useUserAccess } from "../../AuthenticatedRoutes";
import CollapseSetupPickup from "../CollapseSetupPickup";

// interface IProps{
//   is_shown:Boolean;
// }

const NavigationItems = ({ t, is_shown }) => {
  const {
    hasAccessToLeaderboard,
    hasFullAccess,
    hasAccessToBranches,
    hasPickUpEnabled,
  } = useUserAccess();

  // const [is_shown]

  const { criticalNumbers } = useContext(CriticalReviewsNumber);
  // const { userData } = useContext(StoreData);
  // const customerService = userData?.user_type === UserTypes.customer_service;
  return (
    <ul className={classes.NavigationItems}>
      {hasAccessToLeaderboard && (
        <NavigationItem link="/leaderboard">
          <div className={classes.bar}>
            <img
              className={classes.icon}
              src={require("../../../assets/cup.svg")}
            />
            <span
              className={is_shown ? classes.showNavItem : classes.hideNavItem}
            >
              {t(NavigationStrings.leaderBoard)}
            </span>
          </div>
        </NavigationItem>
      )}
      <NavigationItem link="/reviews">
        <div className={classes.bar}>
          <span className={`icon icon-comment ${classes.icon}`} />
          <span
            className={is_shown ? classes.showNavItem : classes.hideNavItem}
          >
            {t(NavigationStrings.reviews)}
          </span>
          {!!criticalNumbers && (
            <p
              className={`${classes.issuesCount} ${
                is_shown ? classes.showNavItem : classes.hideNavItem
              }`}
            >
              {criticalNumbers}
            </p>
          )}
        </div>
      </NavigationItem>
      {hasPickUpEnabled && <CollapseSetupPickup is_is_show={is_shown} />}

      {hasFullAccess && <CollapseBrandListing is_is_show={is_shown} />}
      {hasAccessToBranches && (
        <CollapseBrandSettings
          is_is_show={is_shown}
          canAccessBranches={hasAccessToBranches}
          hasFullAccess={hasFullAccess}
        />
      )}
    </ul>
  );
};

export default hot(module)(translate("navigation")(NavigationItems)) as any;
