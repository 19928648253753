import { reducer, on } from "ts-action";
import { withLoadingReducer } from "./withLoadingState";
import { getBranchInfoAction } from "../../constants/actions";
import { IStore } from "../actions/lib";
import { getBranchInfoSuccess } from "../actions/past-order-actions";

interface IState {
  branchInfo: IStore;
}

const initialState: IState = {
  branchInfo: {
    address: "",
    created_at: 0,
    delivery_zones: [],
    detailed_address: "",
    ar_detailed_address: "",
    en_detailed_address: "",
    id: "",
    lat: 0,
    lng: 0,
    name: "",
    ar_name: "",
    en_name: "",
    numbers: [],
    phone: "",
    pickup_prep_max: 0,
    pickup_prep_min: 0,
    store_id: "",
    type: 0,
    updated_at: 0,
    app_version: "",
  },
};

export const getBranchInfoReducer = withLoadingReducer(
  reducer<IState>(
    [
      on(getBranchInfoSuccess, (state, { payload }) => ({
        ...state,
        branchInfo: {
          ...state.branchInfo,
          ...payload,
        },
      })),
    ],
    initialState
  ),
  getBranchInfoAction
);
