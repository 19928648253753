import React, { useMemo } from "react";
import * as styles from "./styles.module.sass";
import { Modal } from "@material-ui/core";
import { hot } from "react-hot-loader";
import { IDeliveryZone } from "../../../../../../../../../redux-store/actions/lib";
import { GenericButtonWithoutIcon } from "../../../../../../../../../SharedComponent/GenericButtonWithoutIcon";
import { useTranslation } from "../../../../../../../../../../WizardComponents/Branches/AddBranchForm/AddZones/RenderZonesFieldArray/useTranslation";
import Strings from "../../../../../../../../../i18n/strings/orderHistory";
import { useRtlClass } from "../../../../../../../../../../../lib";
interface IProps {
  open: boolean;
  onClose: () => void;
  delivery_zones: IDeliveryZone[];
}
const ZonesModal: React.FC<IProps> = ({ onClose, open, delivery_zones }) => {
  const deliveryZonesCount = useMemo(() => delivery_zones.length, [
    delivery_zones,
  ]);
  const { t } = useTranslation("orderHistory");
  const rtl = useRtlClass(styles);
  return (
    <Modal open={open} onClose={onClose} className={styles.modal}>
      <div className={`${styles.wrapper} ${rtl}`}>
        <span className={styles.title}>
          {t(Strings.deliveryZones)} ({deliveryZonesCount})
        </span>
        <div className={styles.secondWrapper}>
          {(delivery_zones || []).map((zones) => (
            <div className={styles.zonesWrapper} key={zones.sub_district_id}>
              <div className={styles.zoneWrapper}>
                <span className={styles.city}>
                  {rtl ? zones.city_ar_name : zones.city_en_name}
                </span>
                <span className={styles.area}>
                  {rtl ? zones.district_ar_name : zones.district_en_name} -{" "}
                  {rtl
                    ? zones.sub_district_ar_name
                    : zones.sub_district_en_name}
                </span>
              </div>
            </div>
          ))}
        </div>

        <div className={styles.btnWrapper}>
          <GenericButtonWithoutIcon
            label={t(Strings.cancel)}
            className={styles.cancel}
            onClick={onClose}
          />
        </div>
      </div>
    </Modal>
  );
};

export default hot(module)(ZonesModal);
