import { reducer, on } from "ts-action";
import { withLoadingReducer } from "./withLoadingState";
import { getPastOrderAction } from "../../constants/actions";
import { getPastOrderSuccess } from "../actions/past-order-actions";
import { IFilter, IOrder } from "../actions/lib";

interface IState {
  orders: string[];
  total_orders: number;
  ordersById: { [x: string]: IOrder };
  filters: IFilter;
  page: number;
  hasMore: boolean;
}

const initialState: IState = {
  orders: [],
  total_orders: 0,
  ordersById: {},
  filters: {},
  page: 0,
  hasMore: true,
};
export const ORDERS_PAGE_SIZE = 100;

const processAndOrderOrders = (orderedDuplicatedOrders: string[]) => {
  let index = 0;
  const ordersOrdering: {
    [orderId: string]: number;
  } = {};
  orderedDuplicatedOrders.forEach((orderId) => {
    if (ordersOrdering[orderId] === undefined) {
      ordersOrdering[orderId] = index++;
    }
  });

  const uniqueOrderedOrders = Array.from(new Set(orderedDuplicatedOrders)).sort(
    (a, b) => ordersOrdering[a] - ordersOrdering[b]
  );
  const currentPage = Math.ceil(uniqueOrderedOrders.length / ORDERS_PAGE_SIZE);
  const exactly15PagesOrders = uniqueOrderedOrders.slice(
    0,
    currentPage * ORDERS_PAGE_SIZE
  );

  return exactly15PagesOrders;
};

export const pastOrdersReducer = withLoadingReducer(
  reducer<IState>(
    [
      on(getPastOrderSuccess, (state, { payload }) => {
        const ordersById: { [x: string]: IOrder } = {
          ...(payload.page !== 0 ? state.ordersById : {}),
          ...payload.orders.reduce(
            (acc, next) => ({
              ...acc,
              [next.id]: next,
            }),
            {}
          ),
        };
        return {
          ...state,
          orders: processAndOrderOrders([
            ...(payload.page !== 0 ? state.orders : []),
            ...payload.orders.map((a) => a.id),
          ]),
          total_orders:
            payload.page !== 0 ? state.total_orders : 0 || payload.total_orders,
          filters: payload.filters,
          hasMore: payload.hasMore,
          page: payload.page,
          ordersById,
        };
      }),
    ],
    initialState
  ),
  getPastOrderAction
);
