import instance, {
  handleSuccess,
  handleError,
  escapeAllStrings,
} from "../../../axios/axios";

import axiosLib from "axios";
const axios = axiosLib.create({
  ...instance.defaults,
  baseURL: process.env.PICKUP_ENDPOINT,
});
axios.interceptors.response.use(handleSuccess, handleError);
axios.interceptors.request.use((req) => {
  const shouldNotEscape = req.headers && req.headers.no_escaping;

  if (shouldNotEscape) {
    req.headers.no_escaping = undefined;
  }

  const isKoinzAdminRemote = sessionStorage.getItem("isKoinzAdminRemote");
  if (isKoinzAdminRemote) {
    const storeId = sessionStorage.getItem("storeId");
    if (req.method === "GET" || req.method === "get") {
      req.url = `${req.url}?store_id=${storeId}`;
    } else {
      req.data = {
        ...req.data,
        store_id: storeId,
      };
    }
  }

  return {
    ...req,
    data: shouldNotEscape ? req.data : req.data && escapeAllStrings(req.data),
  };
});

export default axios;
