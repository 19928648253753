import { call, select, takeLatest } from "redux-saga/effects";
import { selectToken } from "../../../../redux-store/selectors";
import { reOrderItemAction } from "../../constants/actions";
import * as actions from "../actions/itemActions";
import { reOrderItem } from "../../axios/reOrderItem";
import { union } from "ts-action";
import { handleOptimism } from "./handleOptimism";
import { customNotify } from "../../SharedComponent/Notification";
import { translateMenuError } from "./changeItemCategorySaga";
import menuErrorMessages from "../../i18n/strings/menuErrorMessages";

const actionType = union({ a: actions.reOrderItem });

function* reOrderItemSaga(action: typeof actionType) {
  try {
    const token = yield select(selectToken);
    const { newOrder, id } = action.payload;
    yield call(reOrderItem, token, id, newOrder);
    return action.payload;
  } catch (e) {
    customNotify.error(translateMenuError(menuErrorMessages.reorderItem));
    throw e;
  }
}

export function* watchReOrderItemSaga() {
  yield takeLatest(
    reOrderItemAction.requested,
    handleOptimism(reOrderItemSaga, {
      success: actions.reOrderItemSuccess,
      failure: actions.reOrderItemFailure,
    })
  );
}
