import React, { useContext, useMemo } from "react";
import { Collapse, Button, CardBody, Card } from "reactstrap";
import { NavLink, RouteComponentProps } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { InjectedTranslateProps, translate } from "react-i18next";
import NavigationStrings from "../../../i18n/strings/navigation";
import { useToggleState } from "../../../../Utils/custom-hooks/useToggleState";
import { useRtlClass } from "../../../lib";
import * as styles from "../CollapseBrandListing/styles.module.sass";
import { useSelect } from "../../../hooks/useSelect";
import StoreData from "../../../contexts/StoreData";
import { useOrderingPortalToggle } from "../../OrderingPortal/OrderingPortalHeader/useOrderPortalToggle";

interface IProps {
  is_is_show: boolean;
}

const CollapseSetupPickup: React.FC<RouteComponentProps &
  InjectedTranslateProps &
  IProps> = ({ t, location, is_is_show }) => {
  const isInPickup = useMemo(() => location.pathname.includes("/pickup"), [
    location.pathname,
  ]);
  const duration = 10;

  const defaultStyle = {
    transition: `all ${duration}ms ease`,
    opacity: 0,
  };

  const transitionStyles = {
    false: { opacity: 0 },
    true: { opacity: 1 },
  };
  const [collapse, toggle] = useToggleState();
  const isOpen = collapse && is_is_show;
  const { token } = useContext(StoreData);
  const { General } = useSelect((state) => state.storeSettingsReduces);
  const { orderingPortalStatus } = useOrderingPortalToggle(token);
  return (
    <div className={`${styles.wrapper} ${useRtlClass(styles)}`}>
      <NavLink
        to={"/pickup/setup/pickup-branches/select-branches"}
        className={isInPickup ? styles.btn : styles.btn}
      >
        <Button
          onClick={toggle}
          className={isInPickup ? styles.btnActive : styles.btn}
        >
          <div className={styles.bar}>
            <img
              className={styles.icon}
              src={require("../../../assets/shopping-bag.svg")}
            />
            <span
              className={is_is_show ? styles.showNavItem : styles.hideNavItem}
            >
              {t(NavigationStrings.pickup)}
            </span>
          </div>
          <span
            className={`${
              is_is_show ? styles.showNavItem : styles.hideNavItem
            } ${isOpen ? styles.arrow : styles.arrowOpen}`}
          />
        </Button>
      </NavLink>

      <Collapse
        style={{
          ...defaultStyle,
          ...transitionStyles[isOpen ? "true" : "false"],
        }}
        in={isOpen}
        timeout={duration}
        className={styles.body}
        isOpen={isOpen}
      >
        <Card className={styles.card}>
          <CardBody style={{ padding: "1.2rem 0" }}>
            <li className={styles.NavigationItem}>
              <NavLink
                to="/pickup/setup/pickup-branches/select-branches"
                exact={true}
                activeClassName={styles.active}
              >
                <span>{t(NavigationStrings.pickupBranches)}</span>
              </NavLink>
            </li>
            <li className={styles.NavigationItem}>
              <NavLink
                to="/pickup/setup/menu"
                exact={true}
                activeClassName={styles.active}
              >
                <span>{t(NavigationStrings.menu)}</span>
              </NavLink>
            </li>
            <li className={styles.NavigationItem}>
              <NavLink
                to="/pickup/setup/settings"
                exact={true}
                activeClassName={styles.active}
              >
                <span>{t(NavigationStrings.brandSettings)}</span>
              </NavLink>
            </li>
            {orderingPortalStatus !== "service_disabled" && (
              <li className={styles.NavigationItem}>
                <NavLink
                  to="/pickup/setup/ordering-portal"
                  exact={true}
                  activeClassName={styles.active}
                >
                  <span>{t(NavigationStrings.orderingPortal)}</span>
                </NavLink>
              </li>
            )}
            {(General?.delivery_settings_completed ||
              General?.pickup_settings_completed) && (
              <li className={styles.NavigationItem}>
                <NavLink
                  to="/pickup/setup/order-history"
                  exact={true}
                  activeClassName={styles.active}
                >
                  <span>{t(NavigationStrings.orderHistory)}</span>
                </NavLink>
              </li>
            )}
          </CardBody>
        </Card>
      </Collapse>
    </div>
  );
};

export default translate("navigation")(withRouter(CollapseSetupPickup)) as any;
