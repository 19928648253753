import {
  changeItemCategory,
  changeItemCategorySuccess,
  changeItemCategoryFailure,
} from "../actions/itemActions";
import { union } from "ts-action";
import { takeLatest, call, select } from "redux-saga/effects";
import { handleOptimism } from "./handleOptimism";
import { changeItemCategoryAPI } from "../../axios/changeItemCategory";
import { selectToken } from "../../../../redux-store/selectors";
import i18n from "../../../../i18n/i18n";
import menuErrorMessages from "../../i18n/strings/menuErrorMessages";
import { customNotify } from "../../SharedComponent/Notification";
const actionType = union({ changeItemCategory });
export const translateMenuError = (key) =>
  i18n.t(key, { ns: "menuErrorMessages" });

function* changeItemCategorySaga(action: typeof actionType) {
  try {
    const token = yield select(selectToken);
    yield call(
      changeItemCategoryAPI,
      token,
      action.payload.itemId,
      action.payload.categoryId
    );
    return action.payload;
  } catch (e) {
    customNotify.error(
      translateMenuError(menuErrorMessages.changeItemCategory)
    );
    throw e;
  }
}

export function* watchChangeItemCategorySaga() {
  yield takeLatest(
    changeItemCategory.type,
    handleOptimism(changeItemCategorySaga, {
      success: changeItemCategorySuccess,
      failure: changeItemCategoryFailure,
    })
  );
}
