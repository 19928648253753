import { call, put, select, takeLatest } from "redux-saga/effects";
import { selectToken } from "../../../../../../redux-store/selectors";
import { getBranchWorkingHoursAction } from "../../../../constants/actions";
import {
  getBranchWorkingHoursSuccess,
  getBranchWorkingHours,
  getBranchWorkingHoursFailure,
} from "../../../actions/Manager";
import { getBranchWorkingHoursApi } from "../../../../axios/Manager";
import { union } from "ts-action";

const actionTypes = union({ getBranchWorkingHours });

function* getBranchWorkingHoursSaga({ payload }: typeof actionTypes) {
  try {
    const token = yield select(selectToken);
    const res = yield call(getBranchWorkingHoursApi, token, payload);

    yield put(getBranchWorkingHoursSuccess({ [payload]: res.data }));
  } catch (error) {
    yield put(getBranchWorkingHoursFailure(error));
  }
}

export function* watchGetBranchWorkingHours() {
  yield takeLatest(
    getBranchWorkingHoursAction.requested,
    getBranchWorkingHoursSaga
  );
}
