import {
  IGeneral,
  IBrandCategories,
  IBrandTags,
  RoutingMethod,
  DeliveryMethods,
} from "../../lib/types";
import { reducer, on } from "ts-action";
import { withLoadingReducer } from "./withLoadingState";
import { getStoreSettingsAction } from "../../constants/actions";
import {
  getStoreSettingsSuccess,
  editStoreSettingsSuccess,
  getBrandCategorySuccess,
  getAllBrandCategoriesSuccess,
  getAllBrandTagsSuccess,
  getBrandTagsSuccess,
  submitOrderingSettingsSuccess,
  submitOrderingSettingsFailure,
  submitOrderingSettings,
} from "../actions/StoreSettings";
import { OrderingPortalStatus } from "../../../../types";

interface IState {
  General: IGeneral;
  brandCategories: IBrandCategories[];
  currentBrandCategory: IBrandCategories | null;
  currentBrandTags: IBrandTags[];
  brandTags: IBrandTags[];
  isSubmitting: boolean;
}

const initialState: IState = {
  General: {
    pickup_prep_min: 0,
    web_portal_status: OrderingPortalStatus.service_disabled,
    pickup_prep_max: 0,
    pickup_cover_image: "",
    payment_methods: [],
    updated_at: 0,
    is_call_center: false,
    routing_method: RoutingMethod.standalone,
    timezone: "",
    country_iso_code: "",
    created_at: 0,
    id: "0",
    pickup_status: "",
    delivery_fees: 0,
    delivery_time: 0,
    delivery_settings_completed: false,
    pickup_settings_completed: false,
    delivery_method: DeliveryMethods.in_house_delivery,
  },
  brandCategories: [],
  brandTags: [],
  currentBrandCategory: null,
  currentBrandTags: [],
  isSubmitting: false,
};

export const storeSettingsReduces = withLoadingReducer(
  reducer<IState>(
    [
      on(getStoreSettingsSuccess, (state, { payload }) => {
        return {
          ...state,
          General: payload,
        };
      }),
      on(editStoreSettingsSuccess, (state, { payload }) => ({
        ...state,
        General: {
          ...state.General,
          ...payload,
        },
      })),
      on(submitOrderingSettings, (state) => ({
        ...state,
        isSubmitting: true,
      })),
      on(
        [submitOrderingSettingsSuccess, submitOrderingSettingsFailure],
        (state) => ({
          ...state,
          isSubmitting: false,
        })
      ),
      on(getAllBrandCategoriesSuccess, (state, { payload }) => ({
        ...state,
        brandCategories: payload,
      })),
      on(getAllBrandTagsSuccess, (state, { payload }) => ({
        ...state,
        brandTags: payload,
      })),
      on(getBrandCategorySuccess, (state, { payload }) => ({
        ...state,
        currentBrandCategory: payload,
      })),
      on(getBrandTagsSuccess, (state, { payload }) => ({
        ...state,
        currentBrandTags: payload,
      })),
    ],
    initialState
  ),
  getStoreSettingsAction
);
