import axios from "./axios";
import * as Requests from "./requests";
import { handleSimpleCaching } from "./getZones";

export interface IArea {
  created_at: number;
  ArName: string;
  id: string;
  updated_at: number;
  EnName: string;
}

export const getDistrictsAPI = handleSimpleCaching(
  (token: string, city_id: string) => {
    return axios.get(Requests.getDistrictAPI, {
      baseURL: process.env.PICKUP_ENDPOINT,

      headers: {
        token,
      },
      params: {
        city_id,
      },
    });
  },
  Requests.getDistrictAPI
);
