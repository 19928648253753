import { useMemo, useState } from "react";
import { ICreateUser } from "../../../axios/createUser";
import { IEditUserData } from "../../../axios/editUser";
import Strings from "../../../i18n/strings/users";
import { IBranch } from "../../../types/shared";
export interface IGenericCUDForm<FormValues = any> {
  titleText: string;
  buttonText?: string;
  onSubmit?: (values: ICreateUser | IEditUserData) => any;
  readOnly?: boolean;
  initialValues?: FormValues;
  userType: number;
}

export interface IUserCUDForm {
  [x: string]: IGenericCUDForm<any>;
}

const VIEW = "VIEW";
type VIEW = typeof VIEW;
const EDIT = "EDIT";
type EDIT = typeof EDIT;
const ADD = "ADD";
type ADD = typeof ADD;

export type FormTypes = VIEW | EDIT | ADD;

export enum UserTypes {
  deeOriginalAdmin = 0,
  owner_CEO = 1,
  branch_area_manager = 2,
  senior_manager = 3,
  customer_service = 4,
}

export interface IUser {
  name: string;
  reports_frequencies: {
    overview_report: number;
    critical_review_report: number;
  };
  email: string;
  branch_ids: string[];
  branches: IBranch[];
  user_type: number;
  role: string;
  image: string;
  updated_at: number;
  created_at: number;
  id: string;
  last_seen: number;
  is_pending: number;
  title: string;
}

type PromisingFn = (...args: any[]) => Promise<any>;

export const useUserCRUD = (
  createUser: PromisingFn,
  editUser: PromisingFn,
  t
) => {
  const [formType, setFormType] = useState<FormTypes>(ADD);

  const [userType, setUserType] = useState<UserTypes>(UserTypes.owner_CEO);

  const [selectedUser, selectUser] = useState<IUser | undefined>();

  const userFormsMap: IUserCUDForm = useMemo(
    () => ({
      [ADD]: {
        titleText: t(Strings.addUser),
        onSubmit: (values) => createUser(values as ICreateUser),
        buttonText: t(Strings.addUser),
        userType,
      },
      [EDIT]: {
        titleText: t(Strings.editUser),
        buttonText: t(Strings.editUser),
        onSubmit: (values) => editUser(values as IEditUserData),
        initialValues: selectedUser,
        userType,
      },
      [VIEW]: {
        readOnly: true,
        titleText: t(Strings.viewUser),
        initialValues: selectedUser,
        userType,
      },
    }),
    [selectedUser, userType]
  );

  const setFormToEditUser = (user: IUser, userT: UserTypes) => {
    setFormType(EDIT);
    setUserType(userT);
    selectUser(user);
  };
  const setFormToViewUser = (user: IUser, userT: UserTypes) => {
    setFormType(VIEW);
    setUserType(userT);
    selectUser(user);
  };
  const setFormToAddUser = (userT: UserTypes) => {
    setFormType(ADD);
    setUserType(userT);
  };
  return {
    formType,
    currentForm: userFormsMap[formType],
    setFormToAddUser,
    setFormToEditUser,
    setFormToViewUser,
  };
};
