export default {
  openingHour: "ساعات العمل",
  preparationTime: "وقت التحضير",
  delivery: "وقت وتكلفة الدليفرى",
  brandCat: "تصنيف المتجر",
  brandTags: "أوسمة المتجر",
  coverPhoto: "صورة الواجهة",
  friday: "الجمعة",
  saturday: "السبت",

  sunday: "الاحد",
  monday: "الاثنين",
  wednesday: "الاربعاء",
  tuesday: "الثلاثاء",
  posTitle: "يتم إرسال الطلبات للفروع عن طريق نظام الـ POS مباشرة",
  thursday: "الخميس",
  AM: "ص",
  PM: "م",
  selectTimeZoneLabel: "اختر منطقتك الزمنية",
  setUpPrepTimeTitle: "تنصيب وقت التحضير",
  setUpPrepTimeSubTitle: "ما هو متوسط وقت تحضير الطلبات في متجرك؟",
  from: "من",
  to: "إلى",
  minutes: "دقيقة",
  minimumOrderValue: "أقل قيمة للطلب",
  setUpDeliveryTitle: "تنصيب وقت وتكليف خدمة التطلبات",
  setUpDeliverySubTitle: "ما هو متوسط وقت الطلب",
  time: "الوقت",
  cost: "التكلفة",
  description:
    "يجب ان تكون يكون حجم الصور لا يتعدى 5MB تكون من صيغة ال JPG , PNG ",
  uploadPhoto: "رفع الصورة",
  done: "الأنتهاء",
  unsupported: "نوع صورة غير مدعم",
  routingMethod: "طريقة التوجية",
  orderTracking: "تتبع الطلب",
  deliveryMethod: "طريقة التوصيل",
  paymentMethodTitle: "طريقة الدفع",
  branchesWorkingHours: "مواعيد عمل الفروع",
};
