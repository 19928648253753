import amplitude from "amplitude-js";

export const amplitudeInit = () =>
  amplitude.getInstance().init(process.env.AMPLITUDE_API_KEY as string);

export const amplitudeLogEvent = (eventName: string, eventProps?: {}) => {
  const isKoinzAdminRemote = sessionStorage.getItem("isKoinzAdminRemote");
  if (!isKoinzAdminRemote) {
    return amplitude.getInstance().logEvent(eventName, eventProps);
  } else {
    return null;
  }
};

export enum AmplitudeEvents {
  reviews_page_viewed = "reviews_page_viewed",
  issue_resolved = "issue_resolved",
  compensation = "compensation",
}
