import { reducer, on } from "ts-action";
import { withLoadingReducer } from "./withLoadingState";
import {
  IOrderingPortalLink,
  getOrderingPortalLinkSuccess,
} from "../actions/ordering-portal-link-action";
import { getOrderingPortalLinkAction } from "../../constants/actions";

export const orderingPortalLinkReducer = withLoadingReducer<
  IOrderingPortalLink
>(
  reducer<IOrderingPortalLink>(
    [
      on(getOrderingPortalLinkSuccess, (state, { payload }) => ({
        ...state,
        web_portal_link: payload.web_portal_link,
      })),
    ],
    {
      web_portal_link: "",
    }
  ),
  getOrderingPortalLinkAction
);
