import { IStoreWorkingHours } from "src/types";
import axios from "../../axios";
import * as Requests from "../../requests";

// get store working hours
export const getStoreWorkingHours = (token: string) => {
  return axios.get(Requests.getStoreWorkingHours, {
    headers: {
      token,
    },
    baseURL: process.env.PICKUP_ENDPOINTS,
  });
};

// update store working hours
export const updateStoreWorkingHours = (
  token: string,
  payload: IStoreWorkingHours
) => {
  return axios.put(Requests.updateStoreWorkingHours, payload, {
    headers: {
      token,
    },
    baseURL: process.env.PICKUP_ENDPOINTS,
  });
};
