import { withLoadingReducer } from "./withLoadingState";
import { reducer, on } from "ts-action";
import { getWebPortalStatisticsAction } from "../../constants/actions";
import {
  IWebPortalStatistics,
  getWebPortalStatisticsSuccess,
} from "../actions/ordering-portal-link-action";

interface IState {
  webPortalStatistics: IWebPortalStatistics;
}
const initialState: IState = {
  webPortalStatistics: {
    completed_orders_count: 0,
    created_basket_orders_count: 0,
    created_orders_count: 0,
    portal_clicks_count: 0,
  },
};
export const orderingPortalStatisticsReducer = withLoadingReducer<IState>(
  reducer<IState>(
    [
      on(getWebPortalStatisticsSuccess, (state, { payload }) => {
        return {
          ...state,
          webPortalStatistics: payload,
        };
      }),
    ],
    initialState
  ),
  getWebPortalStatisticsAction
);
