import * as React from "react";
import { Redirect, Route, Switch } from "react-router";
import NotFound from "../NotFound";
import { IRootReducerState } from "../../redux-store/rootReducer";
import { connect } from "react-redux";
import { hot } from "react-hot-loader";
import { HashRouter } from "react-router-dom";
import ScrollToTop from "../Leaderboard/Scroller";
import StoreData from "../../contexts/StoreData";
import { IUser, UserTypes } from "../BrandSettings/DashboardUsers/users-hooks";
import { loadableWithBounce } from "../LoadableRoute";
import { PickupStatus } from "../../types/shared";

const Login = loadableWithBounce(() => import("../Login"));
const LeaderBoardAuth = loadableWithBounce(() => import("./LeaderBoard"));
const WizardAuth = loadableWithBounce(() => import("./Wizard"));
const ReviewsAuth = loadableWithBounce(() => import("./Reviews"));
const BrandSettingsAuth = loadableWithBounce(() => import("./BrandSettings"));
const MyAccountForm = loadableWithBounce(() =>
  import("../BrandSettings/MyAccount/Main")
);
const PickupWrapper = loadableWithBounce(() =>
  import("../Pickup/PickupWrapper")
);
interface IProps {
  hasNextLeaderboardLoaded: boolean;
}

export const useUserAccess = () => {
  const { userData, storeData } = React.useContext(StoreData);
  const { user_type } = userData as IUser;
  const hasFullAccess = React.useMemo(
    () =>
      user_type === UserTypes.deeOriginalAdmin ||
      user_type === UserTypes.owner_CEO ||
      user_type === UserTypes.senior_manager,
    [user_type]
  );
  const hasAccessToLeaderboard = React.useMemo(
    () => Boolean(storeData ? storeData.allow_leaderboard : 0) && hasFullAccess,
    [storeData && hasFullAccess]
  );
  const hasAccessToBranches = React.useMemo(
    () => hasFullAccess || user_type === UserTypes.branch_area_manager,
    [hasFullAccess, user_type]
  );

  const hasPickUpEnabled = React.useMemo(() => {
    return Boolean(
      hasFullAccess &&
        storeData &&
        storeData.pickup_status &&
        storeData.pickup_status !== PickupStatus.service_disabled
    );
  }, [storeData, hasFullAccess]);

  return {
    hasAccessToLeaderboard,
    hasAccessToBranches,
    hasFullAccess,
    hasPickUpEnabled,
  };
};

const AuthenticatedRoutesRoot: React.FunctionComponent<IProps> = (props) => {
  const { hasNextLeaderboardLoaded } = props;
  const {
    hasAccessToLeaderboard,
    hasFullAccess,
    hasPickUpEnabled,
  } = useUserAccess();
  const defaultRoute = React.useMemo(
    () => (hasAccessToLeaderboard ? "/leaderboard" : "/reviews"),
    [hasAccessToLeaderboard]
  );

  // dashboard live preview
  const isKoinzAdminRemote = sessionStorage.getItem("isKoinzAdminRemote");

  return (
    <HashRouter>
      <ScrollToTop>
        <Switch>
          <Route path="/reviews" component={ReviewsAuth} />
          {/* if dashboard live preview , don't define this route 
          >>> my account is removed from dashboard live preview scope 
          */}
          {!isKoinzAdminRemote && (
            <Route exact={true} path="/my-account" component={MyAccountForm} />
          )}
          {/* End dashboard preview guard */}
          {hasFullAccess && <Route path="/brand" component={WizardAuth} />}
          <Route path="/settings" component={BrandSettingsAuth} />
          {hasPickUpEnabled && (
            <Route path="/pickup" component={PickupWrapper} />
          )}
          {hasAccessToLeaderboard && (
            <Route path="/leaderboard" component={LeaderBoardAuth} />
          )}
          <Route exact={true} path="/login" component={Login} />
          <Redirect to={defaultRoute} />
          {(hasNextLeaderboardLoaded || !hasFullAccess) && (
            <Route component={NotFound} />
          )}
        </Switch>
      </ScrollToTop>
    </HashRouter>
  );
};

const mapStateToProps = (state: IRootReducerState) => ({
  hasNextLeaderboardLoaded:
    state.competitionsReducer.HAS_NEXT_LEADERBOARD_LOADED,
});

export default hot(module)(connect(mapStateToProps)(AuthenticatedRoutesRoot));
