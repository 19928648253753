import React from "react";
import * as styles from "./styles.module.sass";
import { IReview } from "../../../axios/getUserProfile";
import { InjectedTranslateProps, translate } from "react-i18next";
import { useLocalizedMoment } from "../../../../Utils/localizedMoment";
import { useToggleState } from "../../../../Utils/custom-hooks/useToggleState";
import Strings from "../../../i18n/strings/leaderboard";
import { useRtlClass } from "../../../lib";

interface IProps extends InjectedTranslateProps {
  review: IReview;
}

export interface ISelectItemRating {
  label: string;
  value: any;
  img: string;
}

export const ratingSelectItems: ISelectItemRating[] = [
  {
    value: 1,
    label: "emoji1",
    img: require("../../../assets/terrible.svg"),
  },
  {
    value: 2,
    label: "emoji2",
    img: require("../../../assets/bad.svg"),
  },
  {
    value: 3,
    label: "emoji3",
    img: require("../../../assets/ok.svg"),
  },
  {
    value: 4,
    label: "emoji4",
    img: require("../../../assets/great.svg"),
  },
  {
    value: 5,
    label: "emoji5",
    img: require("../../../assets/wow.svg"),
  },
];

const RatingsCard: React.FC<IProps> = ({ review, t }) => {
  const [seeMore, onAddMore] = useToggleState();
  const reviewStatus = ratingSelectItems.find(
    (rate) => rate.value === review.rate
  ) as ISelectItemRating;
  return (
    <div className={`${useRtlClass(styles)} ${styles.cardWrapper}`}>
      <div className={styles.date}>
        <img
          className={styles.logo}
          src={require("../../../assets/icon-time.svg")}
        />
        <p className={styles.text}>
          {useLocalizedMoment(review.created_at, "x").format(
            "DD MMM, YYYY, hh:mm a"
          )}
        </p>
      </div>
      <div className={styles.reviewStatus}>
        <img className={styles.logo} src={reviewStatus.img} />
        <p className={styles.rateText}>{t(reviewStatus.label)}</p>
      </div>
      <div className={styles.reviewText}>
        {seeMore && review.text}
        {!seeMore && review.text.slice(0, 100)}
        {review.text.length > 100 && !seeMore && (
          <span className={styles.readMore} onClick={onAddMore}>
            {t(Strings.readMore)}
          </span>
        )}
      </div>
    </div>
  );
};

export default translate("leaderboard")(RatingsCard);
