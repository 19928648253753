import axios from "./axios";
import { AxiosReturn, ICompetition } from "./types";
import * as Requests from "./requests";

export const getCompetitions = (
  token,
  filters
): AxiosReturn<ICompetition[]> => {
  return axios.get(Requests.leaderBoards, {
    baseURL: process.env.LUMEN_ENDPOINT,
    headers: {
      token,
    },
    params: filters,
  });
};
