import { call, put, select, takeLatest } from "redux-saga/effects";
import {
  getUserRanking,
  getUserRankingFailure,
  getUserRankingSuccess,
} from "../actions/user-ranking-actions";
import { union } from "ts-action";
import { getUsers, IUsersResponse } from "../../axios/getUsers";
import { selectToken } from "../selectors";
import { getUserRankingAction } from "../../constants";
import { AxiosResponse } from "axios";
const getUserRankingType = union({ getUserRanking });
function* getUserRankingSaga(action: typeof getUserRankingType) {
  try {
    const params = action.payload;
    const token = yield select(selectToken);
    const { data }: AxiosResponse<IUsersResponse> = yield call(
      getUsers,
      token,
      params
    );
    const pagesCount = Math.ceil(data.totalCustomers / params.size);
    yield put(
      getUserRankingSuccess({
        data,
        page: params.page,
        pagesCount,
      })
    );
  } catch (e) {
    yield put(getUserRankingFailure(e));
  }
}

export function* watchUserRankingSaga() {
  yield takeLatest(getUserRankingAction.requested, getUserRankingSaga);
}
