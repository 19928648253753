import axios from "./axios";
import * as Requests from "./requests";

export const orderTrackingAPI = (token: string, id: string) => {
  return axios.get(Requests.orderTracking(id), {
    headers: {
      token,
    },
    baseURL: process.env.PICKUP_ENDPOINT,
  });
};
