import { IMenu } from "../../lib/types";
import { reducer, on } from "ts-action";
import { withLoadingReducer } from "./withLoadingState";
import { getOrderingMenusAction } from "../../constants/actions";
import { getMenusSuccess, createMenuSuccess } from "../actions/menuActions";

interface IState {
  menus: string[];
  menusById: { [x: string]: IMenu };
}

const initialState: IState = {
  menus: [],
  menusById: {},
};

export const orderingMenusReducer = withLoadingReducer(
  reducer<IState>(
    [
      on(getMenusSuccess, (state, { payload }) => ({
        ...state,

        menus: payload
          .sort((a, b) => a.created_at - b.created_at)
          .map((a) => a.id),
        menusById: payload.reduce(
          (acc, next) => ({ ...acc, [next.id]: next }),
          state.menusById
        ),
      })),
      on(createMenuSuccess, ({ menus, menusById }, { payload }) => ({
        menus: [...menus, payload.id],
        menusById: {
          ...menusById,
          [payload.id]: payload,
        },
      })),
    ],
    initialState
  ),
  getOrderingMenusAction
);
