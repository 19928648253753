export default {
  deliveryInformation: "deliveryInformation",
  deliveryTime: "deliveryTime",
  deliveryZones: "deliveryZones",
  endMessage: "endMessage",
  deliveryCost: "deliveryCost",
  cancel: "cancel",
  customerAddress: "customerAddress",
  country: "country",
  today: "today",
  orders: "orders",
  _30days: "_30days",
  city: "city",
  district: "district",
  zoneAndStreet: "zoneAndStreet",
  redemption: "redemption",
  buildingNo: "buildingNo",
  floorNo: "floorNo",
  apartmentNo: "apartmentNo",
  comment: "comment",
  netProfit: "netProfit",
  totalOrders: "totalOrders",
  sales: "sales",
  totalOrdersForStatistics: "totalOrdersForStatistics",
  userInformation: "userInformation",
  orderHistory: "orderHistory",
  min: "min",
  seeMoreTrans: "seeMoreTrans",
  orderHistoryTitle: "orderHistoryTitle",
  orderTracking: "orderTracking",
  order: "order",
  rejectionReason: "rejectionReason",
  placeholder: "placeholder",
  accepted: "accepted",
  brandInformation: "brandInformation",
  got_picked_up: "got_picked_up",
  branchAddress: "branchAddress",
  branchAppVersion: "branchAppVersion",
  branchLocationOnMap: "branchLocationOnMap",
  ready_for_pickup: "ready_for_pickup",
  unknown: "unknown",
  received: "received",
  order_received: "order_received",
  rejected: "rejected",
  seen: "seen",
  sent: "sent",
  canceled_by_agent: "canceled_by_agent",
  empty: "empty",
  canceled_by_customer: "canceled_by_customer",
  canceled_by_cashier: "canceled_by_cashier",
  customer_no_show: "customer_no_show",
  scheduled: "scheduled",
  delivered: "delivered",
  out_for_delivery: "out_for_delivery",
  time: "time",
  orderId: "orderId",
  orderType: "orderType",
  brand: "brand",
  branch: "branch",
  clientName: "clientName",
  price: "price",
  orderState: "orderState",
  pastOrders: "pastOrders",
  name: "name",
  all: "all",
  branches: "branches",
  orderTypeSelected: "orderTypeSelected",
  timeSelected: "timeSelected",
  yesterday: "yesterday",
  _3Days: "_3Days",
  _7Days: "_7Days",
  _20Days: "_20Days",
  pickup: "pickup",
  delivery: "delivery",
  orderDetails: "orderDetails",
  paymentMethod: "paymentMethod",
  noCodeApplied: "noCodeApplied",
  cash: "cash",
  visa: "visa",
  promoCode: "promoCode",
  totalInvoice: "totalInvoice",
  deliverFees: "deliverFees",
  custom: "custom",
  pickDate: "pickDate",
  canceled_by_courier: "canceled_by_courier",
};
