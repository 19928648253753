import * as React from "react";

import * as styles from "./App.module.sass";
import Notifications from "react-notify-toast";

import { HashRouter, Switch } from "react-router-dom";
import { hot } from "react-hot-loader";
import { UpdateNotifier } from "shopx-shared-components/src";

import LoadingBar from "react-redux-loading-bar";
import NavBar from "./Components/NavBar";
import Header from "./Components/Header";
import { Route } from "react-router";
import AuthenticatedRoutes from "./Components/AuthenticatedRoutes";
import StoreData from "./contexts/StoreData";
import { useContext, useEffect, useState } from "react";
import { IStore } from "./types";
import { PrivateRoute } from "./Components/PrivateRoute";
import BreadCrumbs, { ICrumbData } from "./contexts/BreadCrumbs";

import isEqual from "lodash/isEqual";
import { getCashiers } from "./redux-store/actions";
import { connect } from "react-redux";

import translate from "react-i18next/src/translate";
import { IUser } from "./Components/BrandSettings/DashboardUsers/users-hooks";
import { useGetCriticalReviewsNumber } from "./hooks/get-critical-reviews-number";
import { loadableWithBounce } from "./Components/LoadableRoute";
import { CriticalReviewsNumber, useRtlClass } from "./lib";
import { MuiThemeProvider } from "@material-ui/core";
import { MaterialUITheme } from "./theme.material";

const SignUp = loadableWithBounce(() => import("./Components/SignUp"));
const Login = loadableWithBounce(() => import("./Components/Login"));

interface IState {
  storeData: IStore | null;
  isLoggedIn: boolean;
  token: string;
  signUpComplete: boolean;
}

interface IProps {
  getCashiers: () => void;
}

const AuthenticatedWrapper = (props: IProps) => {
  useEffect(() => {
    props.getCashiers();
  }, []);
  const { criticalNumbers, update } = useGetCriticalReviewsNumber(false);
  const [crumbs, setCrumbs] = useState<ICrumbData[]>([]);
  const updateCrumbs = React.useCallback(
    (data) => {
      if (!isEqual(data, crumbs)) {
        setCrumbs(data);
      }
    },
    [setCrumbs, crumbs]
  );
  const { signUpComplete } = useContext(StoreData);
  return signUpComplete ? (
    <div className={styles.mainSection}>
      <CriticalReviewsNumber.Provider value={{ criticalNumbers, update }}>
        <NavBar />
        <div className={styles.navBar} />
        <div className={styles.secondarySection}>
          <BreadCrumbs.Provider value={{ crumbs, setCrumbs: updateCrumbs }}>
            <Header />
            <AuthenticatedRoutes />
            <UpdateNotifier appName={"Koinz Dashboard"} />
          </BreadCrumbs.Provider>
        </div>
      </CriticalReviewsNumber.Provider>
    </div>
  ) : (
    <Route component={SignUp} />
  );
};

const mapDispatchToProps = (dispatch) => ({
  getCashiers: () => dispatch(getCashiers()),
});

const ConnectedAuthRoutes = connect(
  null,
  mapDispatchToProps
)(AuthenticatedWrapper);

const App: React.FC = () => {
  const [state, setState] = useState<IState>({
    storeData: null,
    token: "",
    isLoggedIn: false,
    signUpComplete: false,
  });
  const rtlClass = useRtlClass(styles);
  const setStoreData = (
    store: IStore,
    token: string,
    signUpComplete: boolean
  ) => {
    setState({
      storeData: store,
      isLoggedIn: true,
      token,
      signUpComplete,
    });
  };
  const [userData, setUserData] = useState<IUser | null>(null);
  return (
    <MuiThemeProvider theme={MaterialUITheme}>
      <HashRouter>
        <div className={`${styles.App} ${rtlClass}`}>
          <LoadingBar style={{ backgroundColor: "yellow" }} />
          <div style={{ fontSize: "2rem" }}>
            <Notifications
              options={{ zIndex: 2000, wrapperId: "notificationsWrapper" }}
            />
          </div>
          <StoreData.Provider
            value={{ ...state, setStoreData, userData, setUserData }}
          >
            <Switch>
              <Route exact={true} path="/(login|signup)" component={Login} />
              <PrivateRoute component={ConnectedAuthRoutes} />
            </Switch>
          </StoreData.Provider>
        </div>
      </HashRouter>
    </MuiThemeProvider>
  );
};

export default hot(module)(translate("")(App));
