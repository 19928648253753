import { call, select, takeLatest } from "redux-saga/effects";
import { selectToken } from "../../../../redux-store/selectors";
import { getAllBrandTags } from "../../axios/getAllBrandTags";
import * as actions from "../actions/StoreSettings";
import { handleOptimism } from "./handleOptimism";

function* getAllBrandTagsSaga() {
  const token = yield select(selectToken);
  const res = yield call(getAllBrandTags, token);
  return res.data.store_tags;
}

export function* watchGetAllBrandTagsSaga() {
  yield takeLatest(
    actions.getAllBrandTags.type,
    handleOptimism(getAllBrandTagsSaga, {
      success: actions.getAllBrandTagsSuccess,
      failure: actions.getAllBrandTagsFailure,
    })
  );
}
