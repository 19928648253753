import { watchCreateMenuSaga } from "./createMenuSaga";
import { watchGetMenusSaga } from "./getMenusSaga";
import { watchCreateCategorySaga } from "./createCategorySaga";
import { watchGetItemSaga } from "./getItemSaga";
import { watchGetCategoriesSaga } from "./getCategoriesSaga";
import { watchReOrderItemSaga } from "./reOrderItemSaga";
import { watchGetModifierGroupsSaga } from "./getModifierGroups";
import { watchCreateItemSaga } from "./createItemSaga";
import { watchReOrderCategorySaga } from "./reOrderCategory";
import { watchGetSuggestedModifiersGroupsSaga } from "./getSuggestedModifiersGroupsSaga";
import { watchEditMenuCategorySaga } from "./editCategorySaga";
import { watchDeleteMenuCategorySaga } from "./deleteCategorySaga";
import { watchDeleteMenuItemSaga } from "./deleteItemSaga";
import { watchCreateModifierGroupSaga } from "./createModifierGroupSaga";
import { watchEditMenuItemSaga } from "./editItemSaga";
import { watchCreateSuggestedModifierGroupSaga } from "./createSuggestedModifierGroupSaga";
import { watchChangeItemCategorySaga } from "./changeItemCategorySaga";
import { watchGetStoreSettingsSaga } from "./getStoreSettingsSaga";
import { watchedStoreSettingsSaga } from "./editStoreSettings";
import { watchGetBrandTagsSaga } from "./getBrandTags";
import { watchGetAllBrandCategoriesSaga } from "./getAllBrandCategories";
import { watchGetAllBrandTagsSaga } from "./getAllBrandTags";
import { watchGetBrandCategorySaga } from "./getBrandCategory";
import { watchSubmitPickupBranchesSaga } from "./submitPickupBranchesSaga";
import { watchDeleteSuggestedModifiersGroupSaga } from "./deleteSuggestedModifiersGroup";
import { watchEditSuggestedModifiersGroupSaga } from "./editSuggestedModifiersGroup";
import { watchGetZonesSaga } from "./getZonesSaga";
import { watchGetAreasSaga } from "./getAreasSaga";
import { watchPickupSettingsSuccessSaga } from "./pickupSettingsSuccessSaga";
import { editBranchZonesFormSaga } from "./editBranchZonesFormSaga";
import { watchGetMinOrderValueSaga } from "./getMinOrderValue";
import { watchSetMinOrderValueSaga } from "./setMinOrderValue";
import { watchSetRoutingMethodSaga } from "./setRoutingMethodSaga";
import { watchSetPaymentMethodSaga } from "./setPaymentMethodSaga";
import { watchGetPastOrdersSaga } from "./getPastOrdersSaga";
import { watchOrderTrackingSaga } from "./orderTrackingSaga";
import { watchGetCustomerInfoSaga } from "./getCustomerInfoSaga";
import { watchGetOrderDetailsSaga } from "./orderDetailsSaga";
import { watchGetOrderSaga } from "./orderSaga";
import { watchGetBranchInfoSaga } from "./getBranchInfoSaga";
import { watchGetStoreInfoSaga } from "./getStoreInfoSaga";
import { watchGetStatisticsSaga } from "./statisticsSaga";
import { watchExtraInfoSaga } from "./extraInfoSaga";
import { watchImportMenuSaga } from "./importMenuSaga";
import { watchMenuScrapingStateSaga } from "./getMenuScrapingStateSaga";
import { watchDownloadExcelSheet } from "./downloadExcelSheetSaga";
import { watchGetOrderingPortalLinkSaga } from "./orderingPortalLinkSaga";
import { watchGetWebPortalHistorySaga } from "./getWebPortalHistorySaga";
import { watchGetWebPortalStatisticsSaga } from "./getWebPortalStatistics";
import {
  watchGetBranchWorkingHours,
  watchGetStoreWorkingHours,
  watchUpdateStoreWorkingHours,
} from "./Manager/index";
export default [
  watchCreateMenuSaga(),
  watchGetMenusSaga(),
  watchGetOrderDetailsSaga(),
  watchGetItemSaga(),
  watchOrderTrackingSaga(),
  watchCreateCategorySaga(),
  watchExtraInfoSaga(),
  watchGetBranchInfoSaga(),
  watchGetStatisticsSaga(),
  watchGetCategoriesSaga(),
  watchGetStoreInfoSaga(),
  watchGetOrderSaga(),
  watchReOrderItemSaga(),
  watchGetCustomerInfoSaga(),
  watchGetModifierGroupsSaga(),
  watchGetOrderingPortalLinkSaga(),
  watchGetWebPortalHistorySaga(),
  watchGetWebPortalStatisticsSaga(),
  watchCreateItemSaga(),
  watchGetSuggestedModifiersGroupsSaga(),
  watchReOrderCategorySaga(),
  watchEditMenuCategorySaga(),
  watchDeleteMenuCategorySaga(),
  watchDeleteMenuItemSaga(),
  watchCreateModifierGroupSaga(),
  watchEditMenuItemSaga(),
  watchCreateSuggestedModifierGroupSaga(),
  watchSetPaymentMethodSaga(),
  watchChangeItemCategorySaga(),
  watchGetStoreSettingsSaga(),
  watchedStoreSettingsSaga(),
  watchGetBrandTagsSaga(),
  watchGetBrandCategorySaga(),
  watchGetAllBrandCategoriesSaga(),
  watchDownloadExcelSheet(),
  watchGetAllBrandTagsSaga(),
  watchGetPastOrdersSaga(),
  watchSubmitPickupBranchesSaga(),
  watchDeleteSuggestedModifiersGroupSaga(),
  watchEditSuggestedModifiersGroupSaga(),
  watchPickupSettingsSuccessSaga(),
  watchGetZonesSaga(),
  watchGetAreasSaga(),
  editBranchZonesFormSaga(),
  watchSetRoutingMethodSaga(),
  watchGetMinOrderValueSaga(),
  watchSetMinOrderValueSaga(),
  watchImportMenuSaga(),
  watchMenuScrapingStateSaga(),
  watchGetBranchWorkingHours(),
  watchGetStoreWorkingHours(),
  watchUpdateStoreWorkingHours(),
];
