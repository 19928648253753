export default {
  searchPlaceholder: "ابحث عن اسم الفرع",
  notExistItem: "فرع غير موجود",
  branchSelected: "الفروع التى قمت بأختيارها",
  branches: "الفروع",
  newBranch: "فرع جديد",
  next: "التالى",
  description: "قم بأختيار الفرع الذى تريد تنصيب خدمة التوصيل لة",
  branchName: "أسم الفرع",
};
