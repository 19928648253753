import * as React from "react";
import * as styles from "./styles.module.sass";
// @ts-ignore
// import classes from './NavigationItems/NavigationItems.module.css';
import NavigationItems from "./NavigationItems/NavigationItems";
import { hot } from "react-hot-loader";
// import NavigationItem from "./NavigationItems/NavigationItem/NavigationItem";
//
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { useContext } from "react";
import StoreData from "../../contexts/StoreData";
import { IStore } from "../../types";
import { imagesURL } from "../Leaderboard/Card";
import { logout } from "../../axios/logout";
import { InjectedTranslateProps, translate } from "react-i18next";
import Strings from "../../i18n/strings/navigation";
import { useRtlClass } from "../../lib";
import { RouteComponentProps, withRouter } from "react-router";
import { trackEvent } from "../../tracking/trackEvent";
import { EVENTS_FOR_TRACKING } from "../../constants/events-for-tracking";

const NavBar: React.FC<InjectedTranslateProps & RouteComponentProps> = ({
  t,
  history: { push },
}) => {
  const state = useContext(StoreData);
  const store = state.storeData as IStore;
  const isKoinzAdminRemote = sessionStorage.getItem("isKoinzAdminRemote");
  const storeId = sessionStorage.getItem("storeId");

  const onClickLogout = () => {
    if (isKoinzAdminRemote) {
      location.assign(
        `${process.env.ADMIN_WEBAPP_PROFILE_PAGE_URL}/${storeId}/statistics`
      );
      sessionStorage.clear();
    } else {
      logout(state.token).then(async () => {
        await trackEvent(EVENTS_FOR_TRACKING["Logged out"]);
        localStorage.clear();

        location.assign(
          `${process.env.BRAND_DASHBOARD_URL}/#/login?logout=true`
        );
      });
    }
  };
  const goToMyAccount = () => push("/my-account");
  const [show, setShow] = React.useState(false);

  const handleMouseOver = () => {
    setShow(true);
  };
  const handleMouseLeave = () => {
    setShow(false);
  };

  const navRef = React.useRef<HTMLElement>(null);

  const [width, setWidth] = React.useState<number>(1000);

  React.useEffect(() => {
    const w = navRef.current?.clientWidth;
    if (w && w < width) {
      setWidth(w);
    }
  });

  return (
    <>
      <div style={{ minWidth: width, padding: "0 1.5rem" }} />
      <nav
        ref={navRef}
        className={`${styles.navBar} ${useRtlClass(styles)}`}
        onMouseOver={handleMouseOver}
        onMouseLeave={handleMouseLeave}
      >
        {/* <img className={styles.logo} src={require('../../assets/navbar-icon.png')}/> */}
        <div className={styles.items}>
          {/* <span className={styles.title}>{t(Strings.main)}</span> */}
          <NavigationItems is_shown={show} />
        </div>
        <div
          style={{ marginTop: "auto", width: "100%" }}
          className={show ? styles.showS : styles.hideS}
        >
          <div style={{ margin: 0 }} className={styles.separator} />
          <UncontrolledDropdown direction={"up"}>
            <DropdownToggle className={styles.userDD}>
              <div style={{ margin: "0 auto" }} className={styles.userDD}>
                <img
                  className={styles.brandImg}
                  src={imagesURL + (store.store_image as any)}
                />
                <div className={styles.userData}>
                  <p className={styles.brandName}>{store.name}</p>
                </div>
              </div>
              <img
                className={styles.dd}
                src={require("../../assets/icon-dropdownD.svg")}
              />
            </DropdownToggle>
            <DropdownMenu className={styles.menu}>
              {!isKoinzAdminRemote && (
                <DropdownItem onClick={goToMyAccount} className={styles.item}>
                  {t(Strings.myAccount)}
                </DropdownItem>
              )}
              <DropdownItem onClick={onClickLogout} className={styles.item}>
                {t(Strings.logout)}
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
          <div style={{ margin: 0 }} className={styles.separator} />
          <p className={styles.footer}>© Koinz LLC. 2019</p>
        </div>
      </nav>
    </>
  );
};

export default hot(module)(translate("navigation")(withRouter(NavBar)));
