import { FormSubmissionStateTypes } from "../../lib/types";
import { reducer, on } from "ts-action";

import {
  editBranchZonesClear,
  editBranchZonesFailure,
  editBranchZonesSubmitted,
  editBranchZones,
} from "../actions/edit-branch-zones-action";
import { clearPrevState } from "../../../../redux-store/actions";

interface IState {
  formSubmissionState: FormSubmissionStateTypes | undefined;
}

const initialState: IState = {
  formSubmissionState: undefined,
};

const stateHandler = (
  formSubmissionState?: FormSubmissionStateTypes
) => () => ({
  formSubmissionState: formSubmissionState || undefined,
});

export const editBranchZonesReducer = reducer<IState>(
  [
    on(clearPrevState, stateHandler(undefined)),
    on(editBranchZonesClear, stateHandler(FormSubmissionStateTypes.initial)),
    on(editBranchZonesFailure, stateHandler(FormSubmissionStateTypes.failed)),
    on(
      editBranchZonesSubmitted,
      stateHandler(FormSubmissionStateTypes.submitted)
    ),
    on(editBranchZones, stateHandler(FormSubmissionStateTypes.submitting)),
  ],
  initialState
);
