import * as constants from "../../constants";
import { Map } from "immutable";
import { mutateState } from "../../helpers/mutate-state";
import {
  IAddCategoryAction,
  IAddCategoryFailureAction,
  IAddCategorySuccessAction,
  ICataloguedItems,
  ICUDItemsAction,
  IDeleteCategoryAction,
  IDeleteCategoryFailureAction,
  IDeleteCategorySuccessAction,
  IEditCategoryAction,
  IEditCategoryFailureAction,
  IEditCategorySuccessAction,
  IGetCategoriesAction,
  IGetCategoriesFailureAction,
  IGetCategoriesSuccessAction,
  IGetStoreItemsAction,
  IGetStoreItemsFailureAction,
  IGetStoreItemsSuccessAction,
  IOrderedObject,
} from "../../types/wizard-types";
import update from "immutability-helper";
import {
  constructLoadingReducer,
  ILoadingReducerState,
} from "./constructLoadingReducer";
import { itemsReducer } from "./itemsReducer";

const CATEGORIES = "categories";

export interface IMenuReducerState extends ILoadingReducerState {
  [CATEGORIES]: ICataloguedItems[];
}

const initialState: IMenuReducerState = Map({
  [CATEGORIES]: [],
}).toJS();

type menuActions =
  | IGetCategoriesAction
  | IGetCategoriesSuccessAction
  | IGetCategoriesFailureAction
  | IAddCategoryAction
  | IAddCategorySuccessAction
  | IAddCategoryFailureAction
  | IDeleteCategoryAction
  | IDeleteCategoryFailureAction
  | IDeleteCategorySuccessAction
  | IEditCategoryAction
  | IEditCategorySuccessAction
  | IEditCategoryFailureAction
  | IGetStoreItemsAction
  | IGetStoreItemsSuccessAction
  | IGetStoreItemsFailureAction
  | ICUDItemsAction;

export const orderByIndex = <T extends IOrderedObject = IOrderedObject>(
  a: T,
  i: number
): T => ({ ...a, order: i + 1 });

export const sortByOrder = (a: IOrderedObject, b: IOrderedObject) =>
  a.order - b.order;

export const updateItemOrdering = <T extends IOrderedObject = IOrderedObject>(
  items: T[],
  reOrderedItem: T,
  oldItem: T
) => {
  const reOrderedItems = update(items, {
    $splice: [
      [oldItem.order - 1, 1],
      [reOrderedItem.order - 1, 0, reOrderedItem],
    ],
  });
  return reOrderedItems.map(orderByIndex);
};

const menuReducer = (
  state: IMenuReducerState,
  action: menuActions
): IMenuReducerState => {
  switch (action.type) {
    case constants.getStoreItemsAction.requested: {
      return mutateState(state, (map) => {
        map.set(CATEGORIES, []);
      });
    }
    case constants.getStoreItemsAction.fulfilled: {
      return mutateState(state, (map) => {
        const { payload } = action as IGetStoreItemsSuccessAction;
        map.set(
          CATEGORIES,
          payload
            .sort(sortByOrder)
            .map((a) => ({ ...a, items: a.items.sort(sortByOrder) }))
        );
      });
    }
    case constants.addCategoryAction.requested: {
      const { payload } = action as IAddCategoryAction;
      return mutateState(state, (map) => {
        const oldCats = map.get(CATEGORIES) as ICataloguedItems[];
        const newCats = [
          ...oldCats,
          { ...payload, items: [], order: oldCats.length },
        ];
        map.set(CATEGORIES, newCats);
      });
    }
    case constants.addCategoryAction.rejected: {
      const { payload } = action as IAddCategoryFailureAction;
      return mutateState(state, (map) => {
        const oldCats = map.get(CATEGORIES) as ICataloguedItems[];
        const newCats = oldCats.filter(
          (c) => c.optimisticId !== payload.optimisticId
        );
        map.set(CATEGORIES, newCats);
      });
    }
    case constants.addCategoryAction.fulfilled: {
      const { payload } = action as IAddCategorySuccessAction;
      return mutateState(state, (map) => {
        const oldCats = map.get(CATEGORIES) as ICataloguedItems[];
        const newCats = oldCats.map((c) =>
          c.optimisticId === payload.optimisticId ? payload : c
        );
        map.set(CATEGORIES, newCats);
      });
    }
    case constants.deleteCategoryAction.requested: {
      const { payload } = action as IDeleteCategoryAction;
      return mutateState(state, (map) => {
        const oldCats = map.get(CATEGORIES) as ICataloguedItems[];
        const newCats = oldCats.filter((c) => c.id !== payload.id);
        map.set(CATEGORIES, newCats);
      });
    }
    case constants.deleteCategoryAction.rejected: {
      const { payload } = action as IDeleteCategoryFailureAction;
      return mutateState(state, (map) => {
        const oldCats = map.get(CATEGORIES) as ICataloguedItems[];
        map.set(CATEGORIES, [...oldCats, payload].sort(sortByOrder));
      });
    }
    case constants.deleteCategoryAction.fulfilled: {
      return mutateState(state, (map) => {
        const oldCats = map.get(CATEGORIES) as ICataloguedItems[];
        map.set(CATEGORIES, oldCats.map(orderByIndex));
      });
    }
    case constants.editCategoryAction.requested: {
      const { payload } = action as IEditCategoryAction;
      return mutateState(state, (map) => {
        const oldCats = map.get(CATEGORIES) as ICataloguedItems[];
        const categoryOldData = oldCats.find(
          (c) => c.id === payload.id
        ) as ICataloguedItems;
        const editedItemData = {
          ...categoryOldData,
          ...payload,
          fallBack: state,
        };
        if (categoryOldData.name !== payload.name) {
          const newCats = oldCats.map((c) =>
            c.id === payload.id ? editedItemData : c
          );
          map.set(CATEGORIES, newCats);
        } else if (
          payload.order !== undefined &&
          categoryOldData.order !== payload.order
        ) {
          map.set(
            CATEGORIES,
            updateItemOrdering(oldCats, editedItemData, categoryOldData)
          );
        }
      });
    }
    case constants.editCategoryAction.rejected: {
      const { payload } = action as IEditCategoryFailureAction;
      const categoryOldData = state[CATEGORIES].find(
        (c) => c.id === payload.id
      ) as ICataloguedItems;
      return categoryOldData.fallBack;
    }
    case constants.editCategoryAction.fulfilled: {
      const { payload } = action as IEditCategorySuccessAction;
      return mutateState(state, (map) => {
        const oldCats = map.get(CATEGORIES) as ICataloguedItems[];
        const newCats = oldCats.map((c) =>
          c.id === payload.id ? { ...c, ...payload } : c
        );
        map.set(CATEGORIES, newCats);
      });
    }

    case constants.addStoreItemAction.rejected:
    case constants.addStoreItemAction.requested:
    case constants.addStoreItemAction.fulfilled:
    case constants.editStoreItemAction.rejected:
    case constants.editStoreItemAction.requested:
    case constants.editStoreItemAction.fulfilled:
    case constants.deleteStoreItemAction.rejected:
    case constants.deleteStoreItemAction.requested:
    case constants.deleteStoreItemAction.fulfilled: {
      return mutateState(state, (map) => {
        const oldCats = map.get(CATEGORIES) as ICataloguedItems[];
        const {
          payload: { category_id },
        } = action as ICUDItemsAction;
        const newCats = oldCats.map((c) => {
          if (c.id === category_id) {
            return {
              ...c,
              items: itemsReducer(c.items || [], action as ICUDItemsAction),
            };
          }
          return c;
        });
        map.set(CATEGORIES, newCats);
      });
    }
    default:
      return state;
  }
};

export default constructLoadingReducer(
  constants.getStoreItemsAction,
  menuReducer,
  initialState
);
