import { takeLatest, select, call } from "redux-saga/effects";
import {
  editOrderingSuggestedModifiersGroup,
  editOrderingSuggestedModifiersGroupFailure,
  editOrderingSuggestedModifiersGroupSuccess,
} from "../actions/modifierGroupsAction";
import { handleOptimism } from "./handleOptimism";
import { selectToken } from "../../../../redux-store/selectors";
import { editSuggestedModifiersGroupAPI } from "../../axios/editSuggestedModifiersGroup";
import { union } from "ts-action";
import { customNotify } from "../../SharedComponent/Notification";
import { translateMenuError } from "./changeItemCategorySaga";
import menuErrorMessages from "../../i18n/strings/menuErrorMessages";

const actionType = union({ editOrderingSuggestedModifiersGroup });
function* editSuggestedModifiersGroupSaga(action: typeof actionType) {
  try {
    const token = yield select(selectToken);
    yield call(editSuggestedModifiersGroupAPI, token, action.payload);
  } catch (e) {
    customNotify.error(translateMenuError(menuErrorMessages.editModifierGroup));
    throw e;
  }
}

export function* watchEditSuggestedModifiersGroupSaga() {
  yield takeLatest(
    editOrderingSuggestedModifiersGroup.type,
    handleOptimism(editSuggestedModifiersGroupSaga, {
      failure: editOrderingSuggestedModifiersGroupFailure,
      success: editOrderingSuggestedModifiersGroupSuccess,
    })
  );
}
