import axios from "./axios";
import * as Requests from "./requests";

export const getBrandTags = (token: string) => {
  return axios.get(Requests.GET_BRAND_TAGS_API, {
    headers: {
      token,
    },
    baseURL: process.env.DASHBOARD_ENDPOINT,
  });
};
