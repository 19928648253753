import React from "react";
import * as styles from "./styles.module.sass";
import { IPointsOperation } from "../../../axios/getUserProfile";
import { useGetCurrency } from "../../../../Utils/getLocalizedCurrency";
import { useLocalizedMoment } from "../../../../Utils/localizedMoment";
import { translate, InjectedTranslateProps } from "react-i18next";
import Strings from "../../../i18n/strings/leaderboard";

interface IProps {
  pointsOperation: IPointsOperation;
}

const PointsOperationCard: React.FC<IProps & InjectedTranslateProps> = (
  props
) => {
  const { pointsOperation, t } = props;
  const currency = useGetCurrency();
  return (
    <div className={styles.cardWrapper}>
      <div className={styles.date}>
        <img
          className={styles.logo}
          src={require("../../../assets/icon-time.svg")}
        />
        <p className={styles.text}>
          {useLocalizedMoment(pointsOperation.created_at, "x").format(
            "DD MMM, YYYY, hh:mm a"
          )}
        </p>
      </div>
      {!!pointsOperation.is_compensation && (
        <div className={styles.compensation}>Compensation</div>
      )}
      <div className={styles.statsWrapper}>
        <div className={styles.status}>
          <h5 className={styles.statusLabel}>
            {t(Strings.customerReceiptPoints)}
          </h5>
          <p className={styles.statusValue}>{pointsOperation.points}</p>
        </div>
        <div className={styles.status}>
          <h5 className={styles.statusLabel}>
            {t(Strings.customerReceiptRemainingPoints)}
          </h5>
          <p className={styles.statusValue}>{pointsOperation.remaining}</p>
        </div>
        <div
          style={{ width: "100%", margin: ".5rem 0 0" }}
          className={styles.status}
        >
          <h5 className={styles.statusLabel}>{t(Strings.invoice)}</h5>
          <p className={styles.statusValue}>
            {pointsOperation.price} {currency}
          </p>
        </div>
        {!pointsOperation.is_compensation && (
          <>
            {pointsOperation.branch_name && (
              <div className={styles.branchStatus}>
                <h5 className={styles.statusLabel}>{t(Strings.branch)}</h5>
                <p className={styles.statusValue}>
                  {pointsOperation.branch_name}
                </p>
              </div>
            )}
            {pointsOperation.cashier && (
              <div className={styles.cashierStatus}>
                <h5 className={styles.statusLabel}>{t(Strings.cashierName)}</h5>
                <p className={styles.statusValue}>
                  {pointsOperation.cashier.name}
                </p>
              </div>
            )}

            {pointsOperation.receipt_code && (
              <div className={styles.cashierStatus}>
                <h5 className={styles.statusLabel}>{t(Strings.receiptCode)}</h5>
                <p className={styles.statusValue}>
                  {pointsOperation.receipt_code}
                </p>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default translate("leaderboard")(PointsOperationCard);
