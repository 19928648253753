import { call, select, takeLatest } from "redux-saga/effects";
import * as actions from "../actions/itemActions";
import { editOrderingMenuItem } from "../../axios/editOrderingMenuItem";
import { selectToken } from "../../../../redux-store/selectors";
import { editOrderingItemAction } from "../../constants/actions";
import { union } from "ts-action";
import { handleOptimism } from "./handleOptimism";
import { customNotify } from "../../SharedComponent/Notification";
import { translateMenuError } from "./changeItemCategorySaga";
import menuErrorMessages from "../../i18n/strings/menuErrorMessages";
const actionType = union({ a: actions.editOrderingItem });

function* editMenuItemSaga(action: typeof actionType) {
  try {
    const { id } = action.payload;
    const token = yield select(selectToken);
    const item = yield call(editOrderingMenuItem, token, action.payload, id);
    if (item.status) {
      return action.payload;
    }
  } catch (e) {
    customNotify.error(translateMenuError(menuErrorMessages.editItem));
    throw e;
  }
}

export function* watchEditMenuItemSaga() {
  yield takeLatest(
    editOrderingItemAction.requested,
    handleOptimism(editMenuItemSaga, {
      success: actions.editOrderingItemSuccess,
      failure: actions.editOrderingItemFailure,
    })
  );
}
