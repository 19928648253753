import advancedSettings from "./advancedSettings";

export default {
  branchesAndCashiers: "Branches & Cashiers",
  brandSettings: "Brand Settings",
  dashboardUsers: "Dashboard Users",
  leaderBoard: "Leaderboard",
  reviews: "Reviews",
  reviewsResolution: "Issue Resolution",
  reviewsIssues: "Issues",
  orderingPortal: "Web Portal Ordering",
  brandListing: "Brand listing",
  pickup: "Ordering",
  pickupBranches: "Ordering Branches",
  orderHistory: "Order History",
  pickupMenu: "Menu",
  myProfile: "My Profile",
  settings: "Settings",
  brandInfo: "Brand Info",
  brandVisuals: "Brand Visuals",
  menuItems: "Menu Items",
  giftList: "Gift List",
  socialLinks: "Social Links",
  convertingRatio: "Converting Ratio",
  support: "Support",
  help: "Help",
  logout: "Logout",
  main: "Main",
  myAccount: "My Account",
  branchesAndMain: "Branches & Main",
  advancedSettings: "Advanced settings",
  blockedUsers: advancedSettings.blockedUsers,
  menu: "Menu",
};
