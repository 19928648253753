import * as React from "react";
import * as styles from "./styles.module.sass";
import { hot } from "react-hot-loader";
import { useMemo } from "react";

import { CustomDropDownSelect } from "../CustomDropDownSelect";
import i18n from "../../i18n/i18n";
import { InjectedTranslateProps, translate } from "react-i18next";
import ArrowUp from "@material-ui/icons/ArrowDropUp";
import ArrowDown from "@material-ui/icons/ArrowDropDown";
import BreadCrumbs, { ICrumbData } from "../../contexts/BreadCrumbs";
import { Link, RouteComponentProps } from "react-router-dom";
import ReactGA from "react-ga";
import { withRouter } from "react-router";
import { useRtlClass } from "../../lib";
import { Chip } from "@material-ui/core";

// import { trackEvent } from "../../tracking/trackEvent";

const Crumb: React.FC<ICrumbData & InjectedTranslateProps> = ({
  name,
  url,
  disabled,
  t,
}) => {
  return (
    <>
      {disabled ? (
        <p className={styles.disabledCrumb}>{t(name)}</p>
      ) : (
        <Link to={url} className={styles.crumb}>
          {t(name)}
        </Link>
      )}
      <div className={styles.separator}>
        <img src={require("../../assets/chevron.svg")} />
      </div>
    </>
  );
};
const TranslatedCrumb = translate("navigation")(Crumb);

const Crumbs: React.FC = () => {
  const { crumbs } = React.useContext(BreadCrumbs);
  React.useEffect(() => {
    // const currentLink = crumbs.reduce((acc, next) => acc + '/' + i18n.t(next.name, {ns: 'navigation', lng: 'en'}),'');
    const currentLink = crumbs.length ? crumbs[crumbs.length - 1].url : "";
    if (currentLink) {
      ReactGA.ga("set", "page", currentLink);
      ReactGA.ga("send", "pageview");
    }
  }, [crumbs]);
  const rtl = useRtlClass(styles);

  return (
    <div className={`${styles.crumbsWrapper} ${rtl}`}>
      {crumbs.map((c) => (
        <TranslatedCrumb key={c.url} {...c} />
      ))}
    </div>
  );
};
const languages = ["ar", "en"];

const images = {
  en: require("../../assets/en-flag.svg"),
  ar: require("../../assets/ar-flag.svg"),
};

const onChangeLanguage = (lng) => {
  i18n.changeLanguage(lng);
  setTimeout(() => location.reload());
};

const LanguageToggle: React.FC<InjectedTranslateProps & {
  language: string;
}> = ({ language, t }) => (
  <div className={styles.switchLanguageToggleWrapper}>
    <div className={styles.languageWrapper}>
      <img className={styles.languageImage} src={images[language]} />
      <span className={styles.languageLabel}>{t(language)}</span>
    </div>
    <div className={styles.arrowsWrapper}>
      <ArrowUp />
      <ArrowDown />
    </div>
  </div>
);

const LanguageSelectItem: React.FC<InjectedTranslateProps & {
  language: string;
}> = ({ t, language }) => (
  <div className={styles.languageSelectIem}>
    <div className={styles.languageWrapper}>
      <img className={styles.languageImage} src={images[language]} />
      <span className={styles.languageLabel}>{t(language)}</span>
    </div>
  </div>
);

const TranslatedToggleLanguage = translate("switchLanguage")(LanguageToggle);
const TranslatedLanguageItem = translate("switchLanguage")(LanguageSelectItem);

const renderLanguageToggle = (lng) => (
  <TranslatedToggleLanguage language={lng} />
);
const renderLanguageItem = (lng) => <TranslatedLanguageItem language={lng} />;
const SwitchLanguage = () => {
  const initialLang = useMemo(() => i18n.language.split("-")[0], [
    i18n.language,
  ]);
  return (
    <CustomDropDownSelect
      initialValue={initialLang as any}
      options={languages as any}
      onChange={onChangeLanguage}
      renderSelectItem={renderLanguageItem}
      renderSelectToggle={renderLanguageToggle}
    />
  );
};

const Header: React.FC<RouteComponentProps> = ({ location }) => {
  const isKoinzAdminRemote = sessionStorage.getItem("isKoinzAdminRemote");

  return (
    <header className={styles.header}>
      <div className={styles.search}>
        <img className={styles.logo} src={require("../../group-24.svg")} />
        {isKoinzAdminRemote && (
          <Chip
            className={styles.koinzChip}
            label={
              <span className={styles.dashboardLivePreviewChip}>
                Dashboard Live Preview
              </span>
            }
            color="primary"
          />
        )}
      </div>
      {location.pathname === "/pickup/setup/web-portal" && <Crumbs />}
      <SwitchLanguage />
    </header>
  );
};

export default hot(module)(withRouter(Header));
