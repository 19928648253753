import { useProgressState } from "../../../../Utils/custom-hooks/useProgressState";
import { useContext, useEffect, useState, useMemo } from "react";
import { IGetUserProfileRes } from "../../../axios/getUserProfile";
import StoreData from "../../../contexts/StoreData";
import * as profileAPI from "../../../axios/getUserProfile";
import { useToggleState } from "../../../../Utils/custom-hooks/useToggleState";
import { ICashier } from "../../../axios/getCashiers";

export const tempProfile = {
  redemptions: [],
  points_operations: [],
  reviews: [],
  visits: 0,
  updated_at: 0,
  total_points: 0,
  total_invoices: 0,
  sign_up_time: 0,
  shopxId: "n/a",
  points: 0,
  phone_number: "n/a",
  page_points_operations: 0,
  name: "n/a",
  last_active_time: 0,
  image: "n/a",
  id: "n/a",
  gender: 1,
  email: "n/a",
  district_code: "n/a",
  created_at: 0,
  country_code: "n/a",
  birth_date: 0,
  average_rating: 0,
  app_version: "n/a",
  age: 0,
};

const extendArray = (...args) =>
  args.reduce((acc, next) => {
    if (next) {
      return [...acc, ...next];
    }
    return acc;
  }, []);

const usePaginationState = (initialPage = 0) => {
  const [page, setPage] = useState(initialPage);
  const [noMorePages, endPagination] = useToggleState(false);
  const nextPage = () => !noMorePages && setPage(page + 1);
  return {
    page,
    nextPage,
    noMorePages,
    endPagination,
  };
};

export const useGetCustomerProfile = (customerId: string) => {
  const { success, failure, setFailure, setSuccess } = useProgressState();
  const [profile, setProfile] = useState<IGetUserProfileRes>(tempProfile);
  const { token } = useContext(StoreData);
  useEffect(() => {
    profileAPI
      .getUserProfile(token, customerId)
      .then(({ data }) => {
        setSuccess();
        setProfile(data);
      })
      .catch(setFailure);
  }, [customerId, token]);
  return {
    success,
    failure,
    profile,
  };
};

const usePaginatedStateService = (
  initialState,
  service: (page: number) => Promise<any[]>
) => {
  const { nextPage, page, endPagination, noMorePages } = usePaginationState();
  const {
    setFailure,
    setSuccess,
    success,
    failure,
    loading,
  } = useProgressState();
  const [items, setItems] = useState(initialState);
  useEffect(() => {
    if (!noMorePages) {
      service(page)
        .then((receivedItems) => {
          setSuccess();
          setItems(extendArray(items, receivedItems));
          if (!receivedItems || receivedItems.length < 8) {
            endPagination();
          }
        })
        .catch(setFailure);
    }
  }, [page]);
  return {
    items,
    nextPage,
    success,
    failure,
    loading,
  };
};

export const useGetCustomerReviews = (
  customer_id: string,
  initialReviews: IGetUserProfileRes["reviews"]
) => {
  const { token } = useContext(StoreData);
  const {
    nextPage,
    items: reviews,
    success,
    loading,
    failure,
  } = usePaginatedStateService(initialReviews, (page) => {
    return profileAPI
      .getNextReviewsPage(token, { page_reviews: page, customer_id })
      .then(({ data }) => data.reviews);
  });
  return {
    reviews,
    nextReviewsPage: nextPage,
    success,
    failure,
    loading,
  };
};

export const useGetCustomerOperations = (
  customer_id: string,
  initialOperations: IGetUserProfileRes["points_operations"],
  cashiers: ICashier[]
) => {
  const { token } = useContext(StoreData);
  const {
    items: operations,
    nextPage,
    success,
    loading,
    failure,
  } = usePaginatedStateService(initialOperations, (page) => {
    return profileAPI
      .getNextPointsOperationsPage(token, {
        customer_id,
        page_points_operations: page,
      })
      .then(({ data }) => data.points_operations);
  });
  const mappedCashiersOperations = useMemo(
    () =>
      (operations as IGetUserProfileRes["points_operations"]).map((op) => {
        const cashier = (cashiers || []).filter(
          (ca) => ca.id === op.cashier_id
        )[0];
        return { ...op, cashier };
      }),
    [cashiers, operations]
  );
  return {
    operations: mappedCashiersOperations,
    nextOperationsPage: nextPage,
    success,
    failure,
    loading,
  };
};

export const useGetCustomerRedemptions = (
  customer_id: string,
  initialRedemptions: IGetUserProfileRes["redemptions"]
) => {
  const { token } = useContext(StoreData);
  const {
    nextPage,
    items: redemptions,
    success,
    loading,
    failure,
  } = usePaginatedStateService(initialRedemptions, (page) => {
    return profileAPI
      .getNextRedemptionsPage(token, { customer_id, page_redemptions: page })
      .then(({ data }) => data.redemptions);
  });
  return {
    redemptions,
    nextRedemptionsPage: nextPage,
    success,
    failure,
    loading,
  };
};
