import axios from "./axios";
import { AxiosReturn } from "./types";
import * as Requests from "./requests";

export const deleteCompetition = (
  id: string,
  token
): AxiosReturn<{ id: string }> => {
  return axios.delete(Requests.deleteLeaderboard(id), {
    baseURL: process.env.LUMEN_ENDPOINT,
    headers: {
      token,
    },
  });
};
