import * as Mixpanel from "mixpanel-browser";
import ReactGA from "react-ga";

export const trackEvent = (eventName: string, ...args: string[]) => {
  const isKoinzAdminRemote = sessionStorage.getItem("isKoinzAdminRemote");
  if (!isKoinzAdminRemote && process.env.IS_TRACKING) {
    return new Promise<void>((res) => {
      Mixpanel.track(
        eventName,
        undefined,
        () => res()
        // args.length ? {args} : undefined
      );
      hj("tagRecording", [
        eventName,
        // ...args
      ]);

      ReactGA.ga("send", "event", {
        eventCategory: "Custom event",
        eventAction: eventName,
      });
    });
  }
};
