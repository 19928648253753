import { reducer, on } from "ts-action";
import { withLoadingReducer } from "./withLoadingState";
import {
  IWebPortalOrder,
  getWebPortalHistorySuccess,
} from "../actions/ordering-portal-link-action";
import { IWebPortalFilters } from "../../../../axios/getWebPortalHistory";
import { getWebPortalHistoryAction } from "../../constants/actions";

interface IState {
  orders: string[];
  total_orders: number;
  ordersById: { [x: string]: IWebPortalOrder };
  filters: IWebPortalFilters;
  page: number;
  hasMore: boolean;
}

const initialState: IState = {
  orders: [],
  total_orders: 0,
  ordersById: {},
  filters: {
    branch_id: "",
    ordering_source: "ordering_portal",
    end_time: 0,
    order_code_or_customer_phone_number: "",
    order_type: "",
    start_time: 0,
  },
  page: 0,
  hasMore: true,
};
export const ORDERS_PAGE_SIZE = 100;

const processAndOrderOrders = (orderedDuplicatedOrders: string[]) => {
  let index = 0;
  const ordersOrdering: {
    [orderId: string]: number;
  } = {};
  orderedDuplicatedOrders.forEach((orderId) => {
    if (ordersOrdering[orderId] === undefined) {
      ordersOrdering[orderId] = index++;
    }
  });

  const uniqueOrderedOrders = Array.from(new Set(orderedDuplicatedOrders)).sort(
    (a, b) => ordersOrdering[a] - ordersOrdering[b]
  );
  const currentPage = Math.ceil(uniqueOrderedOrders.length / ORDERS_PAGE_SIZE);
  const exactly15PagesOrders = uniqueOrderedOrders.slice(
    0,
    currentPage * ORDERS_PAGE_SIZE
  );

  return exactly15PagesOrders;
};

export const webPortalHistoryReducer = withLoadingReducer(
  reducer<IState>(
    [
      on(getWebPortalHistorySuccess, (state, { payload }) => {
        const ordersById: { [x: string]: IWebPortalOrder } = {
          ...(payload.page !== 0 ? state.ordersById : {}),
          ...payload.orders.reduce(
            (acc, next) => ({
              ...acc,
              [next.id]: next,
            }),
            {}
          ),
        };
        return {
          ...state,
          orders: processAndOrderOrders([
            ...(payload.page !== 0 ? state.orders : []),
            ...payload.orders.map((a) => a.id),
          ]),
          total_orders:
            payload.page !== 0 ? state.total_orders : 0 || payload.total_orders,
          filters: payload.filters,
          hasMore: payload.hasMore,
          page: payload.page,
          ordersById,
        };
      }),
    ],
    initialState
  ),
  getWebPortalHistoryAction
);
