import React, { useMemo } from "react";
import * as styles from "./styles.module.sass";
import { Collapse, Button, CardBody, Card } from "reactstrap";
import { NavLink, RouteComponentProps } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { InjectedTranslateProps, translate } from "react-i18next";
import NavigationStrings from "../../../i18n/strings/navigation";
import { useToggleState } from "../../../../Utils/custom-hooks/useToggleState";
import { useRtlClass } from "../../../lib";

interface IProps {
  is_is_show: boolean;
}
const CollapseBrandListing: React.FC<RouteComponentProps &
  InjectedTranslateProps &
  IProps> = ({ t, location, is_is_show }) => {
  const isInBrand = useMemo(() => location.pathname.includes("/brand"), [
    location.pathname,
  ]);
  const duration = 10;

  const defaultStyle = {
    transition: `all ${duration}ms ease`,
    opacity: 0,
  };

  const transitionStyles = {
    false: { opacity: 0 },
    true: { opacity: 1 },
  };
  const [collapse, toggle] = useToggleState();
  const isOpen = collapse && is_is_show;

  return (
    <div className={`${styles.wrapper} ${useRtlClass(styles)}`}>
      <NavLink
        to={"/brand/info"}
        className={isInBrand ? styles.btn : styles.btn}
      >
        <Button
          onClick={toggle}
          className={isInBrand ? styles.btnActive : styles.btn}
        >
          <div className={styles.bar}>
            <img
              className={styles.icon}
              src={require("../../../assets/brand.svg")}
            />
            <span
              className={is_is_show ? styles.showNavItem : styles.hideNavItem}
            >
              {t(NavigationStrings.brandListing)}
            </span>
          </div>
          <span
            className={`${
              is_is_show ? styles.showNavItem : styles.hideNavItem
            } ${isOpen ? styles.arrow : styles.arrowOpen}`}
          />
        </Button>
      </NavLink>
      <Collapse
        style={{
          ...defaultStyle,
          ...transitionStyles[isOpen ? "true" : "false"],
        }}
        in={isOpen}
        timeout={duration}
        className={styles.body}
        isOpen={isOpen}
      >
        <Card className={styles.card}>
          <CardBody style={{ padding: "1.2rem 0" }}>
            <li className={styles.NavigationItem}>
              <NavLink
                to="/brand/info"
                exact={true}
                activeClassName={styles.active}
              >
                <span>{t(NavigationStrings.brandInfo)}</span>
              </NavLink>
            </li>
            <li className={styles.NavigationItem}>
              <NavLink
                to="/brand/visuals"
                exact={true}
                activeClassName={styles.active}
              >
                <span>{t(NavigationStrings.brandVisuals)}</span>
              </NavLink>
            </li>
            <li className={styles.NavigationItem}>
              <NavLink
                to="/brand/menu"
                exact={true}
                activeClassName={styles.active}
              >
                <span>{t(NavigationStrings.menuItems)}</span>
              </NavLink>
            </li>
            <li className={styles.NavigationItem}>
              <NavLink
                to="/brand/gift_list"
                exact={true}
                activeClassName={styles.active}
              >
                <span>{t(NavigationStrings.giftList)}</span>
              </NavLink>
            </li>
            <li className={styles.NavigationItem}>
              <NavLink
                to="/brand/converting_ratio"
                exact={true}
                activeClassName={styles.active}
              >
                <span>{t(NavigationStrings.convertingRatio)}</span>
              </NavLink>
            </li>
            <li className={styles.NavigationItem}>
              <NavLink
                to="/brand/social"
                exact={true}
                activeClassName={styles.active}
              >
                <span>{t(NavigationStrings.socialLinks)}</span>
              </NavLink>
            </li>
          </CardBody>
        </Card>
      </Collapse>
    </div>
  );
};

export default translate("navigation")(withRouter(CollapseBrandListing)) as any;
