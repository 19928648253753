import { call, select, takeLatest } from "redux-saga/effects";
import { selectToken } from "../../../../redux-store/selectors";
import { getBrandCategoryAction } from "../../constants/actions";
import { getBrandCategory } from "../../axios/getBrandCategory";
import * as actions from "../actions/StoreSettings";
import { handleOptimism } from "./handleOptimism";

function* getBrandCategorySaga() {
  const token = yield select(selectToken);
  const res = yield call(getBrandCategory, token);
  return (res.data || { store_category: [] }).store_category[0];
}

export function* watchGetBrandCategorySaga() {
  yield takeLatest(
    getBrandCategoryAction.requested,
    handleOptimism(getBrandCategorySaga, {
      success: actions.getBrandCategorySuccess,
      failure: actions.getBrandCategoryFailure,
    })
  );
}
