import { all } from "redux-saga/effects";

import * as SAGAS from "./sagas";
import pickupSagas from "../Components/Pickup/redux-store/sagas/pickupSagas";
import { watchGetSingleBranchSaga } from "../Components/WizardComponents/Branches/AddBranchForm/redux/getSingleBranchSaga";
import * as ManagerSagas from "../Components/Pickup/redux-store/sagas/Manager";

export default function* rootSaga() {
  yield all([
    SAGAS.watchGetCountriesSaga(),
    SAGAS.watchCreateCompetitionSaga(),
    SAGAS.watchEditCompetitionSaga(),
    SAGAS.watchGetCompetitionsSaga(),
    SAGAS.watchGetCompetitionSaga(),
    SAGAS.watchDeleteCompetitionSaga(),
    SAGAS.watchHasNextCompetitionSaga(),
    SAGAS.watchUserRankingSaga(),
    SAGAS.watchGetStoreSaga(),
    SAGAS.watchEditStoreSaga(),
    SAGAS.watchAddCategorySaga(),
    SAGAS.watchAddStoreItemSaga(),
    SAGAS.watchDeleteCategorySaga(),
    SAGAS.watchDeleteStoreItemSaga(),
    SAGAS.watchEditCategorySaga(),
    SAGAS.watchEditStoreItemSaga(),
    SAGAS.watchGetCategoriesSaga(),
    SAGAS.watchGetStoreItemsSaga(),
    SAGAS.watchPassOnFormValuesSaga(),
    SAGAS.watchAddSpecialItemSaga(),
    SAGAS.watchDeleteSpecialItemSaga(),
    SAGAS.watchEditLoyaltyItemSaga(),
    SAGAS.watchEditSpecialItemSaga(),
    SAGAS.watchGetSpecialItemsSaga(),
    SAGAS.watchGetBranchesSaga(),
    SAGAS.watchAddBranchSaga(),
    SAGAS.watchEditBranchSaga(),
    SAGAS.watchDeleteBranchSaga(),
    SAGAS.watchGetCashiersSaga(),
    SAGAS.watchSignUpSaga(),
    watchGetSingleBranchSaga(),
    ManagerSagas.watchGetStoreWorkingHours(),
    ManagerSagas.watchUpdateStoreWorkingHours(),
    ManagerSagas.watchUpdateBranchesWorkingHours(),
    ManagerSagas.watchCheckOnStoreWHUpdatingStatus(),
    ...pickupSagas,
  ]);
}
