import { select, call, takeLatest } from "redux-saga/effects";
import { selectToken } from "../../../../redux-store/selectors";
import {
  submitPickupBranches,
  submitPickupBranchesFailure,
  submitPickupBranchesSuccess,
} from "../actions/pickupBranchesActions";
import { union } from "ts-action";
import { toggleOrderingServicesAPI } from "../../axios/toggleOrderingServices";
import { handleOptimism } from "./handleOptimism";

const actionType = union({ submitPickupBranches });

function* submitPickupBranchesSaga(action: typeof actionType) {
  const token = yield select(selectToken);
  yield call(toggleOrderingServicesAPI, token, action.payload);
  return action.payload;
}

export function* watchSubmitPickupBranchesSaga() {
  yield takeLatest(
    submitPickupBranches.type,
    handleOptimism(submitPickupBranchesSaga, {
      failure: submitPickupBranchesFailure,
      success: submitPickupBranchesSuccess,
    })
  );
}
