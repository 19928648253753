import sl from "../ar/socialLinks";
import convertingRatio from "../ar/convertingRatio";
export default {
  title: "لوحة التحكم الرئيسية",
  subTitle: "إضافة فريقك الي لوحة التحكم الرئيسية و تعديل معلوماتهم.",
  allUsers: "كل المستخدمين ({{count}})",
  fullName: "الاسم كامل",
  addBranchTitle: "اضافة فرع جديد",
  email: "البريد الالكتروني",
  assignToBranches: "تعيين للفروع",
  otherJobTitle: "وظائف اخري",
  jobTitle: "وظائف",
  searchForUser: "البحث",
  addUser: "إضافة مستخدم",
  addUserTT: "إضافة مستخدم",
  editUser: "تعديل مستخدم",
  viewUser: "عرض مستخدم",
  name: "الاسم",
  role: "  منصب",
  missingRole: "وظيفة مفقودة",
  pendingInvitations: "في انتظار دعوة",
  resendInvitation: "اعادة ارسال الدعوة",
  lastLogin: "اخر تسجيل دخول",
  pendingUsers: "المستخدمين المنتظرين",
  description1: "الوصول الكامل إلى جميع معلومات وإعدادات لوحة التحكم",
  description2: "وصول مخصص لجميع معلومات وإعدادات لوحة التحكم",
  description3: "الوصول إلى البيانات الفروع المعينة",
  description4: "يمكنه رؤية المراجعات وحل المشكلات وتعويض المستخدم فقط",
  description5: "أي شخص مسؤول عن حل العملية",
  description6: "حدد الموقف الذي لم يكن لديك",
  all: "الكل",
  managePositions: "ادارة المراكز",
  title1: "الرئيس التنفيذي للشركة / المالك",
  title2: "الإدارة العليا",
  title3: "مدير المنطقة / الفرع",
  title4: "خدمة العملاء",
  title5: "مدير العمليات",
  title6: "مدير التسويق",
  title7: "مدير تكنولوجيا المعلومات",
  addaBranchMangerUser: " مدير فرع",
  addaCustomerServicesUser: " خدمة عملاء",
  addanOwneracc: " حساب المالك",
  addaSeniorManagmentUser: " إدارة عليا",
  accessToAssignedTitle: "الوصول إلى البيانات فرع المعينة",
  unknown: "غير معروف",
  saveBtn: "حفظ",
  cancelBtn: "إلغاء",
  somethingWentWrong: convertingRatio.somethingWentWrong,
  submit: sl.submit,
  deleteUser: "حذف المستخدم",
  deleteUserDescription: "أنت على وشك حذف هذا المستخدم، قم بالتأكيد للاستمرار",
  confirmDelete: "تأكيد",
  cancel: "إلغاء",
};
