export default {
  accepted: "جارى التحضير",
  got_picked_up: "تم الاستلام",
  ready_for_pickup: "جاهز للإستلام",
  received: "الواردة",
  endMessage: "لقد رأيت كل شيء",
  order_received: "تم الاستلام",
  rejected: "تم رفض الطلب",
  seen: "شوهد من قبل الفرع",
  today: "اليوم",
  _30days: "ثلاثون يوما",
  sent: "تم الارسال للفرع",
  orders: "طلبات",
  placeholder: "ابحث ب كود الطلب، رقم الهاتف...",
  canceled_by_agent: "تم الغاء الطلب",
  redemption: "رمز استرداد الهدية:",
  canceled_by_customer: "تم الغاء الطلب من طرف العميل",
  netProfit: "مجموع المبيعات",
  canceled_by_cashier: "تم الغاء الطلب",
  customer_no_show: " لا يظهر",
  scheduled: "مجدولة",
  custom: "تحديد مدة",
  pickDate: "أختر ميعاد ...",
  sales: "إحصائيات",
  totalOrdersForStatistics: "إجمالى عدد الطلبات",
  delivered: "تم التوصيل",
  out_for_delivery: "خارج للتوصيل",
  time: "الوقت",
  orderId: "كود الطلب",
  clientName: "اسم العميل",
  orderType: "نوع الطلب",
  brand: "المتجر",
  branch: "الفرع",
  price: "السعر",
  orderState: "حالة الطلب",
  pastOrders: "الطلبات السابقة",
  name: "الاسم",
  all: "الكل",
  branches: "الفروع:",
  orderTypeSelected: "نوع الطلب:",
  timeSelected: "بداية من:",
  yesterday: "امس",
  _3Days: "ثلاتة ايام",
  _7Days: "سبعة ايام",
  _20Days: "عشرون يوما",
  pickup: "إستلام من الفرع",
  delivery: "خدمة التوصيل",
  orderDetails: "تفاصيل الطلب",
  rejectionReason: "سبب الرفض",
  empty: "لا يوجد طلبات",
  paymentMethod: "طريقة الدفع",
  noCodeApplied: "لا يوجد كود مطبّق",
  promoCode: "رقم العرض",
  totalInvoice: "إجمالي الفاتورة",
  orderHistory: "سجل الطلبات",
  cash: "كاش",
  visa: "بطاقة ائتمانية",
  orderHistoryTitle: "انظر نتائج خدمة الطلب الخاص بك",
  brandInformation: "معلومات المتجر",
  branchAddress: "عنوان الفرع",
  branchAppVersion: "إصدار تطبيق الفرع",
  branchLocationOnMap: "موقع الفرع على الخريطة",
  deliveryInformation: "معلومات التوصيل",
  deliveryCost: "مصاريف التوصيل",
  deliveryTime: "وقت التوصيل",
  deliveryZones: "مناطق توصيل",
  min: "دقيقة",
  seeMoreTrans: "شاهد {{seeMore}} المزيد من المناطق",
  cancel: "الغاء",
  userInformation: "معلومات العميل",
  totalOrders: "إجمالي الطلبات: {{totalOrders}}",
  customerAddress: "عنوان العميل",
  country: "الدولة",
  city: "مدينة",
  district: "محافظة",
  zoneAndStreet: "منطقة/شارع",
  buildingNo: "رقم المبني",
  floorNo: "رقم الطابق",
  apartmentNo: "رقم الشقة",
  orderTracking: "تتبع الطلب",
  comment: "تعليق",
  order: " {{order}} طلب",
  deliverFees: "مصاريف التوصيل",
  canceled_by_courier: "تم الغاء الطلب"
};
