import { call, select, takeLatest } from "redux-saga/effects";
import * as actions from "../actions/itemActions";
import { createOrderingMenuItem } from "../../axios/addItem";
import { selectToken } from "../../../../redux-store/selectors";
import { createOrderingItemAction } from "../../constants/actions";
import { handleOptimism } from "./handleOptimism";
import { customNotify } from "../../SharedComponent/Notification";
import { translateMenuError } from "./changeItemCategorySaga";
import menuErrorMessages from "../../i18n/strings/menuErrorMessages";

function* createItemSaga(action) {
  try {
    const token = yield select(selectToken);
    const item = yield call(createOrderingMenuItem, token, action.payload);
    if (item.status === 200) {
      return { ...action.payload, id: item.data.item_id };
    }
  } catch (e) {
    customNotify.error(translateMenuError(menuErrorMessages.createItem));
    throw e;
  }
}

export function* watchCreateItemSaga() {
  yield takeLatest(
    createOrderingItemAction.requested,
    handleOptimism(createItemSaga, {
      success: actions.createOrderingItemSuccess,
      failure: actions.createOrderingItemFailure,
    })
  );
}
