export default {
  allTime: "allTime",
  today: "today",
  thisWeek: "thisWeek",
  thisMonth: "thisMonth",
  allBranches: "allBranches",
  searchReviewsPlaceholder: "searchReviewsPlaceholder",
  issue: "issue",
  solved: "solved",
  resolved: "resolved",
  unsolved: "unsolved",
  reopened: "reopened",
  noResponse: "noResponse",
  reviewTabTitle: "reviewTabTitle",
  reviewsStatisticsTabTitle: "reviewsStatisticsTabTitle",
  reviewsStatisticsSubTitle: "reviewsStatisticsSubTitle",
  reviewsStatisticsContent: "reviewsStatisticsContent",
  noIssuesCardTitle: "noIssuesCardTitle",
  noIssuesCardSubTitle: "noIssuesCardSubTitle",
  issuesCardTitle: "issuesCardTitle",
  issuesCardSubTitle: "issuesCardSubTitle",
  compensationQuestion: "compensationQuestion",
  to: "to",
  asACompensation: "asACompensation",
  points: "points",
  cancelCompensation: "cancelCompensation",
  sendCompensation: "sendCompensation",
  emoji1: "emoji1",
  emoji2: "emoji2",
  emoji3: "emoji3",
  emoji4: "emoji4",
  emoji5: "emoji5",
  overallRatingTitle: "overallRatingTitle",
  visitsCount: "visitsCount",
  expandReview: "expandReview",
  collapseReview: "collapseReview",
  solveIssue: "solveIssue",
  viewIssueDetails: "viewIssueDetails",
  viewProfile: "viewProfile",
  nextIssue: "nextIssue",
  nextIssueArrow: "nextIssueArrow",
  previousIssueArrow: "previousIssueArrow",
  previousIssue: "previousIssue",
  issuesRemaining: "issuesRemaining",
  userCaringPart1: "userCaringPart1",
  userCaringPart2: "userCaringPart2",
  callCustomerPart1: "callCustomerPart1",
  callCustomerPart2: "callCustomerPart2",
  customerResponseQuestion: "customerResponseQuestion",
  yes: "yes",
  no: "no",
  writeNoteTitle: "writeNoteTitle",
  writeNotePlaceholder: "writeNotePlaceholder",
  writeNoteSubmit: "writeNoteSubmit",
  markResolved: "markResolved",
  notesTitle: "notesTitle",
  compensationsTitle: "compensationsTitle",
  userCompensationPart1: "userCompensationPart1",
  userCompensationPart2: "userCompensationPart2",
  optional: "optional",
  userCompensationSubtitle: "userCompensationSubtitle",
  nextGiftTitle: "nextGiftTitle",
  giftPrice: "giftPrice",
  openCompensationModal: "openCompensationModal",
  endOfReviews: "endOfReviews",
  noteDateTitle: "noteDateTitle",
  filteredReviewsCount: "filteredReviewsCount",
  filteredIssuesCount: "filteredIssuesCount",
  unFilteredReviewsCount: "unFilteredReviewsCount",
  unFilteredIssuesCount: "unFilteredIssuesCount",
  allReviewsTabTitle: "allReviewsTabTitle",
  categoryFilterTitle: "categoryFilterTitle",
  successCompensationAlertPriceToPoints:
    "successCompensationAlertPriceToPoints",
  successCompensationAlertWasSentTo: "successCompensationAlertWasSentTo",
  pointsBefore: "pointsBefore",
  pointsAfter: "pointsAfter",
  compensationWasSent: "compensationWasSent",
  markNoResponse: "markNoResponse",
  noteArrow: "noteArrow",
  markUnResolved: "markUnResolved",
  showTagsFilters: "showTagsFilters",
  hideTagsFilters: "hideTagsFilters",
  badTagsTitle: "badTagsTitle",
  goodTagsTitle: "goodTagsTitle",
  allCategoriesFilter: "allCategoriesFilter",
  solveIssueFeedbackAlertCustomerName: "solveIssueFeedbackAlertCustomerName",
  solveIssueFeedbackAlertMessage: "solveIssueFeedbackAlertMessage",
  emptyNotesTitle: "emptyNotesTitle",
  emptyNotesAddNote: "emptyNotesAddNote",
  emptyCompensations: "emptyCompensations",
  noteAddedFeedbackAlertMessage: "noteAddedFeedbackAlertMessage",
  markUnResolvedSuccessAlert: "markUnResolvedSuccessAlert",
  markNoResponseSuccessAlert: "markNoResponseSuccessAlert",
  allIssues: "allIssues",
  customerCurrentPoints: "customerCurrentPoints",
  errorCompensationBlockedUser: "errorCompensationBlockedUser",
  issueStatusTitle: "issueStatusTitle",
  compensationsCount: "compensationsCount",
  notesCount: "notesCount",
  customerReceivedAllTimePart1: "customerReceivedAllTimePart1",
  customerReceivedAllTimePart2: "customerReceivedAllTimePart2",
  customerReceivedAllTimePart3: "customerReceivedAllTimePart3",
  customerReceivedAllTimePart4: "customerReceivedAllTimePart4",
  userActivityNote: "userActivityNote",
  userActivityCompensate: "userActivityCompensate",
  userActivityChangeStatus: "userActivityChangeStatus",
  compensationPanelTitle: "compensationPanelTitle",
  compensationPanelSubTitlePart1: "compensationPanelSubTitlePart1",
  compensationPanelSubTitlePart2: "compensationPanelSubTitlePart2",
  recentCompensationsTitle: "recentCompensationsTitle",
  sendShopXPointsCompensationTitle: "sendShopXPointsCompensationTitle",
  sendShopXPointsCompensationSubTitlePart1:
    "sendShopXPointsCompensationSubTitlePart1",
  sendShopXPointsCompensationSubTitlePart2:
    "sendShopXPointsCompensationSubTitlePart2",
  sendCustomGiftCompensationTitle: "sendCustomGiftCompensationTitle",
  sendCustomGiftCompensationSubTitlePart1:
    "sendCustomGiftCompensationSubTitlePart1",
  sendCustomGiftCompensationSubTitlePart2:
    "sendCustomGiftCompensationSubTitlePart2",
  sendSelectedMenuItemButtonText: "sendSelectedMenuItemButtonText",
  itemIsSelected: "itemIsSelected",
  pointsCompensationPlaceholder: "pointsCompensationPlaceholder",
  pointsCompensationValue: "pointsCompensationValue",

  failedToLoadActivities: "failedToLoadActivities",
  emptyActivityLog: "emptyActivityLog",
  activityLogTitle: "activityLogTitle",
  // Gift Popup
  selectedGiftTitle: "selectedGiftTitle",
  messageInArabic: "messageInArabic",
  messageInEnglish: "messageInEnglish",
  messageTitle: "messageTitle",
  messageSubTitle: "messageSubTitle",

  compensationOptionsTitle: "compensationOptionsTitle",

  somethingWentWrong: "somethingWentWrong",

  customGiftImageTitle: "customGiftImageTitle",
  customGiftNameTitle: "customGiftNameTitle",

  sendGiftButtonTitle: "sendGiftButtonTitle",

  sendVoucherTabTitle: "sendVoucherTabTitle",
  sendMenuItemTabTitle: "sendMenuItemTabTitle",
  voucherNamePlaceholder: "voucherNamePlaceholder",
  next: "next",
  sendMessage: "sendMessage",
  messagePlaceholder: "messagePlaceholder",

  expandActivities: "expandActivities",
  messageField: "messageField",

  compensationSuccessModalTitle: "compensationSuccessModalTitle",
  compensationSuccessModalSubTitle: "compensationSuccessModalSubTitle",
  okay: "okay",
};
