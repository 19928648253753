import { call, put, select, takeLatest } from "redux-saga/effects";
import { selectToken } from "../../../../redux-store/selectors";
import { union } from "ts-action";
import { getOrderDetailsAction } from "../../constants/actions";
import {
  getOrderDetails,
  getOrderDetailsFailure,
  getOrderDetailsSuccess,
} from "../actions/past-order-actions";
import { getOrderDetailsAPI } from "../../axios/getOrderDetailsAPI";

const actionType = union({ getOrderDetails });
function* getOrderDetailsSaga(action: typeof actionType) {
  const token = yield select(selectToken);
  try {
    const res = yield call(getOrderDetailsAPI, token, action.payload);
    yield put(getOrderDetailsSuccess(res.data));
  } catch (e) {
    yield put(getOrderDetailsFailure(e));
  }
}

export function* watchGetOrderDetailsSaga() {
  yield takeLatest(getOrderDetailsAction.requested, getOrderDetailsSaga);
}
