import React from "react";
import * as styles from "./styles.module.sass";
import { hot } from "react-hot-loader";
import { IAddress } from "../../../../../../../../redux-store/actions/lib";
import { useTranslation } from "../../../../../../../../../WizardComponents/Branches/AddBranchForm/AddZones/RenderZonesFieldArray/useTranslation";
import Strings from "../../../../../../../../i18n/strings/orderHistory";
import { useRtlClass } from "../../../../../../../../../../lib";
interface IProps {
  // country: ICountryForCustomer;
  customer_country: string;
  sub_district_ar_name: string;
  sub_district_en_name: string;
  district_ar_name: string;
  comment: string;
  city_en_name: string;
  district_en_name: string;
  address: IAddress;
}
const CustomerAddress: React.FC<IProps> = ({
  // country,
  customer_country,
  district_en_name,
  city_en_name,
  sub_district_en_name,
  address,
  comment,
}) => {
  const { t } = useTranslation("orderHistory");
  const rtl = useRtlClass(styles);
  return (
    <div className={`${styles.wrapper} ${rtl}`}>
      <span className={styles.title}>{t(Strings.customerAddress)}</span>
      <div className={styles.addressWrapper}>
        <div className={styles.address}>
          {/* <div className={styles.country}>
            <span className={styles.countryTitle}>{t(Strings.country)}</span>
            <span className={styles.countrySubTitle}>{customer_country}</span>
          </div> */}

          <div className={styles.country}>
            <span className={styles.countryTitle}>{t(Strings.city)}</span>
            <span className={styles.countrySubTitle}>{city_en_name}</span>
          </div>
          <div className={styles.country}>
            <span className={styles.countryTitle}>{t(Strings.district)}</span>
            <span className={styles.countrySubTitle}>{district_en_name}</span>
          </div>
        </div>
        <div className={styles.address}>
          {address.delivery_address.street && (
            <div className={styles.zone}>
              <span className={styles.zoneTitle}>
                {t(Strings.zoneAndStreet)}
              </span>
              <span className={styles.zoneSubTitle}>
                {sub_district_en_name}/{address.delivery_address.street}
              </span>
            </div>
          )}
        </div>
        {address.delivery_address.building && (
          <div className={styles.address}>
            <div className={styles.country}>
              <span className={styles.countryTitle}>
                {t(Strings.buildingNo)}
              </span>
              <span className={styles.countrySubTitle}>
                {address.delivery_address.building}
              </span>
            </div>
            <div className={styles.country}>
              <span className={styles.countryTitle}>{t(Strings.floorNo)}</span>
              <span className={styles.countrySubTitle}>
                {address.delivery_address.floor}
              </span>
            </div>
            <div className={styles.country}>
              <span className={styles.countryTitle}>
                {t(Strings.apartmentNo)}
              </span>
              <span className={styles.countrySubTitle}>
                {address.delivery_address.apartment}
              </span>
            </div>
          </div>
        )}

        {comment && (
          <div className={styles.address}>
            <div className={styles.zone}>
              <span className={styles.zoneTitle}>{t(Strings.comment)}</span>
              <span className={styles.zoneSubTitle}>{comment}</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default hot(module)(CustomerAddress);
