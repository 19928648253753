import axios from "./axios";
import * as Requests from "./requests";

export interface IToggleOrderingServices {
  delivery_branches_ids: string[];
  pickup_branches_ids: string[];
}

export const toggleOrderingServicesAPI = (
  token: string,
  data: IToggleOrderingServices
) => {
  return axios.put(Requests.toggleOrderingServices, data, {
    headers: {
      token,
    },
    baseURL: process.env.PICKUP_ENDPOINT,
  });
};
