import { call, select, takeLatest } from "redux-saga/effects";
import * as actions from "../actions/categoryActions";
import { selectToken } from "../../../../redux-store/selectors";
import { editOrderingCategories } from "../../constants/actions";
import { union } from "ts-action";
import { handleOptimism } from "./handleOptimism";
import { editOrderingMenuCategory } from "../../axios/editCategory";
import { customNotify } from "../../SharedComponent/Notification";
import { translateMenuError } from "./changeItemCategorySaga";
import menuErrorMessages from "../../i18n/strings/menuErrorMessages";
const actionType = union({ a: actions.reOrderCategory });

function* editMenuCategorySaga(action: typeof actionType) {
  try {
    const token = yield select(selectToken);
    const res = yield call(editOrderingMenuCategory, token, action.payload);
    return res.data;
  } catch (e) {
    customNotify.error(translateMenuError(menuErrorMessages.editCategory));
    throw e;
  }
}

export function* watchEditMenuCategorySaga() {
  yield takeLatest(
    editOrderingCategories.requested,
    handleOptimism(editMenuCategorySaga, {
      failure: actions.editOrderingCategoryFailure,
      success: actions.editOrderingCategorySuccess,
    })
  );
}
