import axios from "./axios";
import * as Requests from "./requests";

export const setPaymentMethodApi = (
  token: string,
  payment_methods: string[]
) => {
  return axios.put(
    Requests.setPaymentMethodURL,
    { payment_methods },
    {
      headers: {
        token,
      },
      baseURL: process.env.PICKUP_ENDPOINT,
    }
  );
};
